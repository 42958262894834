import { styled } from '@mui/material/styles';
import { DidactoColor } from '../../types/App';

type ModalProps = {
    backgroundColor: DidactoColor,
    fontColor: DidactoColor,
    shadowColor: DidactoColor,
};

export const ModalBackground = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.6)',
    zIndex: 9,  // Ensure it covers other items but below modal box
});

export const ModalBox = styled('div')<ModalProps>(({ backgroundColor, fontColor, shadowColor }) => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: backgroundColor,
    fontSize: '1.5rem',
    color: fontColor,
    boxShadow: `0 0 3px 0px ${shadowColor}`,
    padding: '2rem',
    width: '330px',
    zIndex: 10,  // Ensure it's above the modal background
}));

export const ModalTitle = styled('div')({
    fontFamily: 'TisaSansPro-Bold',
    fontSize: '1.8rem',
});

export const ModalText = styled('div')({
    fontFamily: 'TisaSansPro-Light',
    lineHeight: 1.3,
    marginBottom: '1rem',
});

export const ModalButtonBox = styled('div')({
    position: 'absolute',
    bottom: '1rem',
    right: '1rem',
});

export const ModalCloseBox = styled('div')({
    position: 'absolute',
    top: '1rem',
    right: '1rem',
});
