import * as S from "./Styles";

type SuccessDisplayProps = {
    message: string,
    padding?: string,
    width?: string,
    margin?: string,
}

export const SuccessDisplay = ({message, padding, width, margin}: SuccessDisplayProps) => {
    return <S.SuccessDisplay padding={padding} width={width} margin={margin}>{message}</S.SuccessDisplay>
};