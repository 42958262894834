import { createUserWithEmailAndPassword, signInWithEmailAndPassword, User, signOut as _signOut } from 'firebase/auth';
import { createSlice, createAsyncThunk, SerializedError, PayloadAction } from '@reduxjs/toolkit';
import { EmailAndPassword } from '../../types/App';
import { auth } from '../../firebase';

export interface IUser {
    uid: string
    emailVerified: boolean,
    email: string | null,
    displayName: string | null,
}

const userToIUser = (user: User) => {
    return ({
            uid: user.uid,
            emailVerified: user.emailVerified,
            email: user.email,
            displayName: user.displayName,
    })
}

export const signIn = createAsyncThunk<(IUser|null), EmailAndPassword>(
    'app/signIn',
    async ({ email, password }, thunkApi) => {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userToIUser(userCredential.user);
    }
);

export const signUp = createAsyncThunk<IUser, EmailAndPassword>(
    'app/signUp',
    async ({ email, password }, thunkApi) => {
        const user = (await createUserWithEmailAndPassword(auth, email, password)).user;
        return userToIUser(user);
    }
)

export const signOut = createAsyncThunk<void, void>(
    'app/signOut',
    async () => {
        return await _signOut(auth);
    }
)

// App State
export type AppState = {
    user: IUser | null;
    error: SerializedError | null;
}

const initialState: AppState = {
    user: null,
    error: null
}


// Redux slice
export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        authChanged: (state, action: PayloadAction<IUser | null>) => {
            state.user = action.payload;
        },
        authError: (state, action: PayloadAction<SerializedError>) => {
            state.error = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(signIn.fulfilled, (state, action) => {
                state.user = action.payload;
                state.error = null;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.user = null;
                state.error = action.error;
            })
            .addCase(signOut.fulfilled, (state, action) => {
                state.user = null;
                state.error = null;
            })
            .addCase(signOut.rejected, (state, action) => {
                state.error = action.error;
            })
    }
});


//export Redux action creators
export const { authChanged, authError } = appSlice.actions;
//export Redux selectors
export const selectUser = (state: { user: User; }) => state.user;
export const selectError = (state: { error: SerializedError; }) => state.error
