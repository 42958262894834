import { FirestoreDataConverter } from 'firebase/firestore';
import { Grade, isGrade } from "../../types/App";

export const PDF_PACKAGES = {
    "3":  {
        name: "Mathe-Package 3. Klasse",
        path: "/products/3/Mathe-Package_3_Klasse.zip",
        filename: "Mathe-Package_3_Klasse.zip",
    },
     "4":  {
        name: "Mathe-Package 4. Klasse",
        path: "/products/4/Mathe-Package_4_Klasse.zip",
        filename: "Mathe-Package_4_Klasse.zip",
    },
     "5":  {
        name: "Mathe-Package 5. Klasse",
        path: "/products/5/Mathe-Package_5_Klasse.zip",
        filename: "Mathe-Package_5_Klasse.zip",
    },
     "6":  {
        name: "Mathe-Package 6. Klasse",
        path: "/products/6/Mathe-Package_6_Klasse.zip",
        filename: "Mathe-Package_6_Klasse.zip",
    },
    "5mini": {
        name: "Mini-Mathe-Package 5. Klasse",
        path: "/products/5/Mini-Mathe-Package_5_Klasse.zip",
        filename: "Mini-Mathe-Package_5_Klasse.zip",
    },
    "6mini": {
        name: "Mini-Mathe-Package 6. Klasse",
        path: "/products/6/Mini-Mathe-Package_6_Klasse.zip",
        filename: "Mini-Mathe-Package_6_Klasse.zip",
    }
 } as const;

export type PDFPackage = keyof typeof PDF_PACKAGES;

export type IProduct = {
    id: string,
    productId: number,
    name: string,
    price: number,
    facts: string[],
    pdfAccess: PDFPackage[],
    wpAccess: Grade[],
    tag: string
    sortOrder: number,
    instruction: string,
    active: boolean,
}

export const isIProduct = (product: any): product is IProduct => {
    let cond = "id" in product &&
        typeof (product.id) === 'string' &&
        "productId" in product &&
        typeof (product.productId) === 'number' &&
        "name" in product &&
        typeof (product.name) === 'string' &&
        "price" in product &&
        typeof (product.price) === 'number' &&
        "tag" in product &&
        typeof (product.tag) === 'string' &&
        "sortOrder" in product &&
        typeof (product.sortOrder) === 'number' &&
        "facts" in product;
    cond = cond && Array.isArray(product.facts) &&
        product.facts.every((fact: any) => typeof (fact) === 'string');

    cond = cond && "pdfAccess" in product &&
        Array.isArray(product.pdfAccess) &&
        cond && product.pdfAccess.every((accessValue: any) => Object.keys(PDF_PACKAGES).includes(accessValue));

    cond = cond && "wpAccess" in product &&
        Array.isArray(product.wpAccess) &&
        product.wpAccess.every((accessValue: any) => isGrade(accessValue));

    cond = cond && "instruction" in product &&
        typeof (product.instruction) === 'string';

    cond = cond && "active" in product &&
        typeof (product.active) === 'boolean';
    return cond;

};

/**
 * A Product that is available for sale in the Didacto webshop.
 * 
 * Params:
 *     id:          Firestore id in Products collection
 *     productId:   Numeric product id, used for sort order on shop page
 *     name:        Name of the product displayed in shop
 *     price:       price in CHF
 *     facts:       key facts about the product to be displayed in shop
 *     pdfAccess:   determine for what grades the PDFs are available when the product is bought
 *     wpAccess:    determine what grades are available in the app (i.e. in work plan generator)
 *     tag:         type of product, PDF or App or both,
 *     instruction: user instructions regarding availability of product
 * 
 */

export class Product implements IProduct {
    private _id: string;
    private _productId: number;
    private _name: string;
    private _price: number;
    private _facts: string[];
    private _tag: string;
    private _pdfAccess: PDFPackage[];
    private _wpAccess: Grade[];
    private _sortOrder: number;
    private _instruction: string;
    private _active: boolean;


    constructor({
        id,
        productId,
        name,
        price,
        facts = [],
        tag,
        pdfAccess = [],
        wpAccess = [],
        sortOrder,
        instruction,
        active,
    }: IProduct) {
        this._id = id;
        this._productId = productId;
        this._name = name;
        this._price = price;
        this._facts = facts;
        this._pdfAccess = pdfAccess;
        this._wpAccess = wpAccess;
        this._sortOrder = sortOrder;
        this._instruction = instruction;
        this._tag = tag;
        this._active = active;
    }

    get id(): string {
        return this._id;
    }

    get productId(): number {
        return this._productId;
    }

    get name(): string {
        return this._name;
    }

    get price(): number {
        return this._price;
    }

    get formattedPrice(): string {
        return `${this._price}.-`;
    }

    get facts(): string[] {
        return this._facts;
    }

    get tag(): string {
        return this._tag;
    }

    get pdfAccess(): PDFPackage[] {
        return this._pdfAccess;
    }

    get wpAccess(): Grade[] {
        return this._wpAccess;
    }

    get sortOrder(): number {
        return this._sortOrder;
    }

    get instruction(): string {
        return this._instruction;
    }

    get active(): boolean {
        return this._active;
    }
}

export const ProductConverter: FirestoreDataConverter<IProduct> = {
    toFirestore: (product: IProduct) => {
        return {
            id: product.id,
            productId: product.productId,
            name: product.name,
            price: product.price,
            facts: product.facts,
            pdfAccess: product.pdfAccess,
            wpAccess: product.wpAccess,
            tag: product.tag,
            sortOrder: product.sortOrder,
            instruction: product.instruction,
            active: product.active
        }
    },
    fromFirestore: (snapshot): IProduct => {
        const data = snapshot.data();
        if (isIProduct(data)) {
            return data;
        } else {
            const entries = Object.entries(data);
            const stringRepr = entries.reduce<string>((acc, [key, val]) => `${key}: ${val};\n${acc}`, "");
            throw new Error(`Malformed product record:\n${stringRepr}`);
        }
    }
}