/**
 * App Component
 *
 * - subscribe to Firebase authentication change listener (App.tsx)
 * - handle global Redux app state containing user account details or authentication errors (appSlice.js)
 * - handle routing between different pagees using React Router v5 (App.tsx)
 * - register Contexts (to share props such as scrollTo, refCallbacks)
 */

import { 
    App,
} from './App';

import { 
    appSlice,
    authChanged, 
    authError, 
    signUp, 
    signIn, 
    signOut, 
    selectError, 
    selectUser,
    AppState,
    IUser,
} from './appSlice';


export { 
    appSlice,
    authChanged, 
    authError, 
    signUp, 
    signIn, 
    signOut, 
    selectUser, 
    selectError,
};

export type {AppState, IUser};
export default App;