import { useState, useEffect } from 'react';
import { storage } from '../firebase';
import { getDownloadURL, ref } from 'firebase/storage';

export const useDownloadURL = (
    {path}: 
    {path: string}
): string => {
    const [downloadUrl, setDownloadURL] = useState<string>("");

    useEffect(() => {
        const setSampleExamDownloadUrl = async () => {
            try {
                const storageRef = ref(storage, path);
                const url = await getDownloadURL(storageRef);
                setDownloadURL(url);
            } catch (error){
                console.error(error)
            }
        }
        if (path){
            setSampleExamDownloadUrl();
        }
    }, [setDownloadURL, path]);

    return downloadUrl;
}
