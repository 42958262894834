import jsPDF from "jspdf";
import {
  tisaSansProNormal,
  tisaSansProBold,
  tisaSansProBlack,
  tisaSansProMedium,
  tisaSansProThin,
  tisaSansProLight,
  tisaSansProLightItalic,
  tisaProLight,
  tisaSansProVersalMedium
} from "./CustomFonts.js";

// Create Document Component
export default function printWorkplanPdf(workPlan, topics, {title, subtitle, showLevels, customExercisesTitle, customExercisesColumnTitle, defaultColor, student}) {
  // Setup
  var doc = new jsPDF('p', 'mm', 'a4');

  // Fonts
  doc.addFileToVFS('TisaSansPro.ttf', tisaSansProNormal);
  doc.addFont('TisaSansPro.ttf', 'TisaSansPro', 'normal');
  doc.addFileToVFS('TisaSansPro-Bold.ttf', tisaSansProBold);
  doc.addFont('TisaSansPro-Bold.ttf', 'TisaSansPro', 'Bold');
  doc.addFileToVFS('TisaSansPro-Black.ttf', tisaSansProBlack);
  doc.addFont('TisaSansPro-Black.ttf', 'TisaSansPro', 'Black');
  doc.addFileToVFS('TisaSansPro-Medium.ttf', tisaSansProMedium);
  doc.addFont('TisaSansPro-Medium.ttf', 'TisaSansPro', 'Medium');
  doc.addFileToVFS('TisaSansProVersal-Medium.ttf', tisaSansProVersalMedium);
  doc.addFont('TisaSansProVersal-Medium.ttf', 'TisaSansProVersal', 'Medium');
  doc.addFileToVFS('TisaSansPro-Thin.ttf', tisaSansProThin);
  doc.addFont('TisaSansPro-Thin.ttf', 'TisaSansPro', 'Thin');
  doc.addFileToVFS('TisaSansPro-LightItalic.ttf', tisaSansProLightItalic);
  doc.addFont('TisaSansPro-LightItalic.ttf', 'TisaSansPro', 'LightItalic');
  doc.addFileToVFS('TisaSansPro-Light.ttf', tisaSansProLight);
  doc.addFont('TisaSansPro-Light.ttf', 'TisaSansPro', 'Light');
  doc.addFileToVFS('TisaPro-Light.ttf', tisaProLight);
  doc.addFont('TisaPro-Light.ttf', 'TisaPro', 'Light');
  doc.setFont(`TisaSansPro`, `Black`);

  // Page Settings
  const pageDimensions = {
    height: 296,
    width: 210
  };
  const pageMargin = 8;
  const liveArea = {
    width: pageDimensions.width - 2*pageMargin,
    height: pageDimensions.height - 2*pageMargin
  };
  const padding = 2;
  const headerRowHeight = 6;
  const contentRowHeight = 5.2;
  const sideBarWidth = 8;
  const topicTitleHeight = 6.5;
  const pageHeaderHeight = pageMargin + 17;
  const exerciseColWidth = showLevels? (liveArea.width - sideBarWidth)/4.14 : (liveArea.width - liveArea.width*0.63) - sideBarWidth;
  const checkBoxSize = contentRowHeight - 1.8;
  const exerciseTextWidth = exerciseColWidth - checkBoxSize - 3*padding;

  // Helpers
  const groupBy = (key, array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  const bookTypes = [
    {pattern: /^AH gelb/, name: "AH gelb "},
    {pattern: /^AH rot/, name: "AH rot "},
    {pattern: /^AH blau/, name: "AH blau "},
    {pattern: /^AH grün/, name: "AH grün "},
    {pattern: /^AH hellgrün/, name: "AH hellgrün "},
    {pattern: /^AH dunkelgrün/, name: "AH dunkelgrün "},
    {pattern: /^AH orange/, name: "AH orange "},
    {pattern: /^TB /, name: "TB "},
    {pattern: /^A\d+\./, name: "A"},
    {pattern: /^Anton/, name: "Anton "}
  ];
  const colorMap = {
    text: {
      light: '#ffffff',
      dark: '#000000'
    },
    table: {
      '1': {
        light: '#f3cea0',
        dark: '#e49d3c'
      },
      '2': {
        light: '#c5ddeb',
        dark: '#8bbad3'
      },
      '3': {
        light: '#c6dbb4',
        dark: '#88b767'
      },
      'yellow': {
        light: "#f5daa2",
        dark: "#e9b538"
      },
      "orange": {
        light: "#f4c19d",
        dark: "#e87d3d"
      },
      "blue": {
        light: "#b8e6e6",
        dark: "#7acccc"
      },
      "green": {
        light: "#c9dcac",
        dark: "#8ebc53"
      },
      "red": {
        light: "#f0a89d",
        dark: "#e14249"
      },
      "lightgreen": {
        light: "#c9dcac",
        dark: "#8ebc53"
      },
      "darkgreen": {
        light: "#a9d0ab",
        dark: "#31a659"
      },
      "grey": {
        light: '#cdd5da',
        dark: '#9da9b1'
      }
    }
  };
  
  const matchBookType = (exercise) => {
    let resultType = null;
    bookTypes.every(bookType => {
      let keepSearching = true;
      if (exercise.match(bookType.pattern)) {
        keepSearching = false;
        resultType = bookType.name;
      }
      return keepSearching;
    });
    return resultType;
  };
  const splitExercise = (exercise) => {
    let bookType = matchBookType(exercise);
    let exerciseArray = [];
    doc.setFontSize(11);
    if (bookType) {
      let splittedExercise = exercise.substring(bookType.length);
      doc.setFont(`TisaSansPro`, 'normal');
      let bookTypeWidth = doc.getStringUnitWidth(bookType) * 11 / (72/25.6);
      doc.setFont(`TisaSansPro`, 'Light');
      let exerciseWidth = doc.getStringUnitWidth(splittedExercise) * 11 / (72/25.6);
      if (bookTypeWidth + exerciseWidth > exerciseTextWidth) {
        exerciseArray.push(bookType);
        exercise = splittedExercise;
      }
    }
    doc.setFont(`TisaSansPro`, 'Light');
    return [...exerciseArray, ...doc.splitTextToSize(exercise, exerciseTextWidth)];
  };
  const groupByTopic = (data) => {
    let groupedByTopic = [];
    let currentTopicId = null;
    let currentTopic = null;
    let currentGroupNumber = 1;
    Object.entries(data).forEach(([key, group], groupIndex) => {
      let topicId = '';
      let match = key.match(/^\d+(\.\d+){3}\s*/);
      if (match) {
        topicId = match[0].match(/^\d+(\.\d+){2}/)[0];
      }
      if (topicId === currentTopicId) {
        currentTopic.groups.push(
          {
            number: match? match[1].trim().substring(1) : currentGroupNumber++,
            description: match? key.substring(match[0].length) : key,
            exercises: group.map(ex => ({...ex, exercise: splitExercise(ex.exercise)}))
          }
        );
      }
      else {
        currentTopicId = topicId;
        if (currentTopic) groupedByTopic.push(currentTopic);
        currentGroupNumber = 1;
        currentTopic = {
          number: topicId === ''? '' : topicId + ' ',
          title: topics[topicId]? topics[topicId].title.toUpperCase() : customExercisesTitle.toUpperCase(),
          firstRow: topics[topicId]? "Kompetenz" : customExercisesColumnTitle,
          groups: [
            {
              number: match? match[1].trim().substring(1) : currentGroupNumber++,
              description: match? key.substring(match[0].length) : key,
              exercises: group.map(ex => ({...ex, exercise: splitExercise(ex.exercise)}))
            }
          ],
          color: topics[topicId]?.color
        }
      }
    });
    if (currentTopic) groupedByTopic.push(currentTopic);
    return groupedByTopic;
  };
  const drawPageHeader = () => {
    // Title
    let workPlanHeight = 12.0 / 3.5;
    doc.setFontSize(12);
    doc.setFont(`TisaSansProVersal`, 'Medium');
    doc.text(title, pageMargin, pageMargin + workPlanHeight);

    // Subtitle
    let textHeight = 12.0 / 3.5;
    doc.text(subtitle, pageMargin, pageMargin + workPlanHeight + padding + textHeight);

    // Name
    doc.setFontSize(13);
    doc.setFont(`TisaSansPro`, 'LightItalic');
    let nameWidth = doc.getStringUnitWidth("Name:    ") * 13 / (72/25.6);
    let lineLength = 48;
    doc.text("Name:    " + (student === null? "" : student), pageMargin + liveArea.width - lineLength - nameWidth, pageMargin + workPlanHeight);
    doc.setLineWidth(0.1);
    doc.line(pageMargin + liveArea.width - lineLength, pageMargin + workPlanHeight + 1, pageMargin + liveArea.width, pageMargin + workPlanHeight + 1);

  };
  const drawPageFooter = () => {
    doc.setFontSize(11);
    doc.setFont(`TisaSansPro`, 'normal');
    doc.text("A", pageMargin + 70, pageMargin + liveArea.height);
    doc.text("AH", pageMargin + 35, pageMargin + liveArea.height);
    doc.text("TB", pageMargin, pageMargin + liveArea.height);
    doc.setFont(`TisaSansPro`, 'Light');
    doc.text("Arbeitsblatt", pageMargin + 77, pageMargin + liveArea.height);
    doc.text("Arbeitsheft", pageMargin + 42, pageMargin + liveArea.height);
    doc.text("Themenbuch", pageMargin + 7, pageMargin + liveArea.height);
    doc.setFont(`TisaSansPro`, 'normal');
    doc.setFontSize(8);
    let didactoWidth = doc.getStringUnitWidth("©didacto") * 8 / (72/25.6);
    doc.text("©didacto", pageMargin + liveArea.width - didactoWidth, pageMargin + liveArea.height);
    doc.setFont(`TisaPro`, 'Light');
    let labelWidth = doc.getStringUnitWidth("ARBEITSPLAN    ") * 8 / (72/25.6);
    doc.text("ARBEITSPLAN", pageMargin + liveArea.width - didactoWidth - labelWidth, pageMargin + liveArea.height);
  };

  // Data
  const data = groupBy('objective', workPlan.finalTableRows);
  const groupedByTopic = groupByTopic(data);
  const headers = [{key: 'objective', display: "Kompetenz"}];
  if (showLevels) [1,2,3].forEach(i => headers.push({key: `l${i}`, display: `Niveau ${i}`}));
  else headers.push({key: "exercise", display: "Aufgaben"});

  // Prepare content
  const xPositions = [pageMargin + sideBarWidth + padding];
  if (showLevels) {
    xPositions.push(xPositions[0] + exerciseColWidth*1.14);
    [2,3].forEach(i => xPositions.push(xPositions[i - 1] + exerciseColWidth));
  } else xPositions.push(xPositions[0] + liveArea.width*0.63);
  let pageOffsetY = 0;

  // Page Header & Footer
  drawPageHeader();
  drawPageFooter();

  // Table content
  groupedByTopic.forEach(topic => {
    doc.setFontSize(11);
    let lastYPos = pageMargin + pageHeaderHeight + topicTitleHeight + 3 + headerRowHeight + padding;
    let currentYPositions = {'1': lastYPos, '2': lastYPos, '3': lastYPos};

    // ROWS
    let contentToDraw = [];
    let groupLineYs = [];
    topic.groups.forEach((group, groupIndex) => {
      let borderOffset = 0;
      // Objective dividing horizontal line
      if (groupIndex > 0) {
        lastYPos += 1.5;
        currentYPositions = {'1': lastYPos, '2': lastYPos, '3': lastYPos};
        groupLineYs.push(lastYPos-0.8*contentRowHeight);
      }

      // Objective number & description
      let objective = doc.splitTextToSize(group.description, xPositions[1] - xPositions[0] - 3*padding);
      let maxExerciseRows = showLevels? Math.max(...Object.values(group.exercises.reduce((acc, ex) => ({...acc, [ex.level]: acc[ex.level] + ex.exercise.length}), {'1': 0, '2': 0, '3': 0}))) : group.exercises.length;
      if (objective.length > maxExerciseRows) {
        borderOffset = (objective.length - maxExerciseRows) * (contentRowHeight - 1.0);
      }
      contentToDraw.push({text: String(group.number), x: pageMargin + 1.75, y: lastYPos, fontStyle: `Medium`, type: 'objectiveNumber'});
      contentToDraw.push({text: objective, x: xPositions[0], y: lastYPos, fontStyle: `normal`, type: 'objectiveName'});

      // Exercise list
      group.exercises.forEach((row, rIndex) => {
        let level = showLevels? row.level : 1;
        let text = row.exercise;
        let bookTypeWidth = 0;
        let bookType = matchBookType(text[0]);
        if (bookType){
          contentToDraw.push({text: bookType, x: xPositions[level], y: currentYPositions[level], fontStyle: `normal`, type: 'bookType'});
          text[0] = text[0].substring(bookType.length);
          if (text.length === 1) {
            doc.setFont(`TisaSansPro`, 'normal');
            bookTypeWidth = doc.getStringUnitWidth(bookType) * 11 / (72/25.6);
          }
        }
        contentToDraw.push({text: text, x: xPositions[level] + bookTypeWidth, y: currentYPositions[level], fontStyle: `Light`, type: 'exercise', level});
        currentYPositions[level] = currentYPositions[level] + contentRowHeight + (text.length - 1)*(contentRowHeight - 1.0);
        lastYPos = Math.max(lastYPos, currentYPositions[level]);
      });
      lastYPos += borderOffset;
    });

    // Check if topic fits on current page or add new page otherwise
    let maxTableY = pageOffsetY + lastYPos - contentRowHeight/2;
    if (maxTableY > pageMargin + liveArea.height - 5) {
      maxTableY -= pageOffsetY;
      pageOffsetY = 0;
      doc.addPage('a4', 'p');
      drawPageHeader();
      drawPageFooter();
    }

    // Topic title
    doc.setTextColor(colorMap.text.dark);
    doc.setFontSize(16);
    doc.setFont(`TisaSansPro`, 'normal');
    doc.text(topic.number, pageMargin, pageOffsetY + pageHeaderHeight + pageMargin + 3.5);
    let xOffset = doc.getStringUnitWidth(topic.number) * 16 / (72/25.6);
    doc.setFont(`TisaSansPro`, 'Black');
    doc.text(topic.title, pageMargin + xOffset, pageOffsetY + pageHeaderHeight + pageMargin + 3.5);

    // Exercises background
    doc.setLineWidth(0.3);
    let colorIndex = topic.number.split('.')[1];
    let tableColors = {};
    if (topic.color) tableColors = colorMap.table[topic.color];
    else tableColors = (colorIndex && colorMap.table[colorIndex])? colorMap.table[colorIndex] : colorMap.table[defaultColor];
    doc.setFillColor(tableColors.light);
    doc.rect(pageMargin, pageOffsetY + pageMargin + pageHeaderHeight + topicTitleHeight + headerRowHeight, sideBarWidth, lastYPos - contentRowHeight/2 - pageMargin - pageHeaderHeight - topicTitleHeight - headerRowHeight, 'F');
    xPositions.forEach((pos, index) => {
      if (index % 2 === 1) {
        doc.rect(pos - padding, pageOffsetY + pageMargin + pageHeaderHeight + topicTitleHeight + headerRowHeight, exerciseColWidth, lastYPos - contentRowHeight/2 - pageMargin - pageHeaderHeight - topicTitleHeight - headerRowHeight, 'F');
      }
    });

    // Table border
    doc.setDrawColor(tableColors.dark);
    groupLineYs.forEach(y => doc.line(pageMargin, pageOffsetY + y, pageMargin + liveArea.width, pageOffsetY + y));
    doc.line(pageMargin, maxTableY, pageMargin + liveArea.width, maxTableY);

    // Header background & divier
    doc.setFillColor(tableColors.dark);
    doc.setTextColor(colorMap.text.dark);
    doc.rect(pageMargin, pageOffsetY + pageMargin + pageHeaderHeight + topicTitleHeight, liveArea.width, headerRowHeight, 'F');
    doc.setDrawColor(colorMap.text.light);
    doc.line(pageMargin + sideBarWidth, pageOffsetY + pageMargin + pageHeaderHeight + topicTitleHeight, pageMargin + sideBarWidth, pageOffsetY + pageMargin + pageHeaderHeight + topicTitleHeight + headerRowHeight);
    xPositions.forEach((pos, index) => {
      doc.line(pos - padding, pageOffsetY + pageMargin + pageHeaderHeight + topicTitleHeight, pos - padding, pageOffsetY + pageMargin + pageHeaderHeight + topicTitleHeight + headerRowHeight);
    });

    // Write header text
    doc.setFont(`TisaSansPro`, 'Medium');
    doc.setFontSize(11);
    doc.setTextColor(colorMap.text.light);
    headers[0].display = topic.firstRow;
    headers.forEach((heading, index) => doc.text(heading.display, xPositions[index], pageOffsetY + pageMargin + topicTitleHeight + pageHeaderHeight + headerRowHeight - 1.75));

    // Write content text
    doc.setTextColor(colorMap.text.dark);
    doc.setFont(`TisaSansPro`, 'normal');
    contentToDraw.forEach(({text, x, y, fontStyle, type, level}) => {
      doc.setFont(`TisaSansPro`, fontStyle);
      doc.text(text, x, pageOffsetY + y);
      if (type === 'exercise') {
        doc.setFillColor(colorMap.text.light);
        doc.setDrawColor(colorMap.text.dark);
        doc.setLineWidth(0.22);
        let rowOffset = showLevels? (3 - level) * exerciseColWidth : 0;
        let xRect = pageMargin + liveArea.width - checkBoxSize - padding - rowOffset;
        doc.roundedRect(xRect, pageOffsetY + y - contentRowHeight/2 - 0.4, checkBoxSize, checkBoxSize, 0.6, 0.6, 'FD');
      }
    });

    // Set statring point for next table
    pageOffsetY += lastYPos - pageMargin - pageHeaderHeight + pageMargin;
  });

  // Save
  doc.save(title.replace(/[^\w\s]/gi, '').replace(/\s+/gi,'_') + "_Arbeitsplan");
}
