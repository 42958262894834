import { useMemo, useCallback } from 'react';
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { TableRow, TableCell, IconButton, Tooltip, Checkbox } from '@mui/material';

export default function GroupedMUIDataTable({ title, data, columns, options, groupingKey }) {
  // Memoize grouped data to avoid recomputation on each render
  const { groupedData, contentByGroup } = useMemo(() => {
    const groupBy = (key, array) =>
      array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

    let contentByGroup = groupBy(groupingKey, data);
    let groupedData = Object.entries(contentByGroup).flatMap(([k, v]) => [{ groupTitle: k, isTitle: true }, ...v]);
    let indexCounter = 0;
    groupedData.forEach((item, i) => {
      if (!item.isTitle) groupedData[i].dataIndex = indexCounter++;
    });

    return { groupedData, contentByGroup };
  }, [data, groupingKey]);

  // Memoize event handlers to avoid unnecessary re-renders
  const handleMove = useCallback((index, isUp) => {
    let upIndices = [];
    let downIndices = [];
    if (groupedData[index].isTitle) {
      let groupNames = Object.keys(contentByGroup);
      let groupIndex = groupNames.indexOf(groupedData[index].groupTitle);
      if (isUp) {
        upIndices = contentByGroup[groupNames[groupIndex]].map(x => x.dataIndex);
        downIndices = contentByGroup[groupNames[(groupIndex - 1)]].map(x => x.dataIndex);
      } else {
        upIndices = contentByGroup[groupNames[(groupIndex + 1)]].map(x => x.dataIndex);
        downIndices = contentByGroup[groupNames[groupIndex]].map(x => x.dataIndex);
      }
    } else {
      let dataIndex = groupedData[index].dataIndex;
      if (isUp) {
        upIndices = [dataIndex];
        downIndices = [dataIndex - 1];
      } else {
        upIndices = [dataIndex + 1];
        downIndices = [dataIndex];
      }
    }
    options.rowActions.onRowsMove(upIndices, downIndices);
  }, [groupedData, contentByGroup, options.rowActions]);

  const handleCopy = useCallback((index) => {
    if (groupedData[index].isTitle) {
      options.rowActions.onRowsCopy(contentByGroup[groupedData[index].groupTitle].map(x => x.dataIndex));
    } else {
      options.rowActions.onRowsCopy([groupedData[index].dataIndex]);
    }
  }, [groupedData, contentByGroup, options.rowActions]);

  const handleDelete = useCallback((index) => {
    if (groupedData[index].isTitle) {
      options.rowActions.onRowsDelete(contentByGroup[groupedData[index].groupTitle].map(x => x.dataIndex));
    } else {
      options.rowActions.onRowsDelete([groupedData[index].dataIndex]);
    }
  }, [groupedData, contentByGroup, options.rowActions]);

  const rowClicked = useCallback(dataIndex => {
    let index = options.rowsSelected.indexOf(dataIndex);
    let tempSelectedRows = options.rowsSelected;
    if (index !== -1) tempSelectedRows = options.rowsSelected.filter((_, i) => index !== i);
    else tempSelectedRows = [dataIndex, ...options.rowsSelected];
    options.onRowSelectionChange([{ index: dataIndex, dataIndex: dataIndex }], tempSelectedRows.map(row => ({ index: row, dataIndex: row })), tempSelectedRows);
    options.rowsSelected = tempSelectedRows;
  }, [options]);

  const onRowSelectionChange = useCallback((_curr, _all, rows) => {
    let tempSelectedRows = [];
    let tempParam = [];
    if (rows.length !== 0) { // All clicked
      tempSelectedRows = groupedData.filter(x => !x.isTitle).map(x => x.dataIndex);
      tempParam = tempSelectedRows.map(row => ({ index: row, dataIndex: row }));
    }
    options.onRowSelectionChange(tempParam, tempParam, tempSelectedRows);
    options.rowsSelected = tempSelectedRows;
  }, [groupedData, options]);

  const isSameExercise = useCallback((ex) => {
    return (element) => ex.objective === element.objective && ex.exercise === element.exercise && ex.level === element.level;
  }, []);

  const hasActionsCol = useCallback((isTitle) => {
    let { rowActions } = options;
    if (rowActions) {
      let key = isTitle ? 'titleRow' : 'contentRows';
      return !['move', 'copy', 'delete'].every(name => !(rowActions[name] && rowActions[name][key]));
    }
    return false;
  }, [options]);

  const moveArrowEnabled = useCallback((index, isUp) => {
    let groupIndex = -1;
    let group = [];
    if (groupedData[index].isTitle) {
      group = Object.keys(contentByGroup);
      groupIndex = group.indexOf(groupedData[index].groupTitle);
    } else {
      let groupKey = groupedData[index].objective;
      group = contentByGroup[groupKey];
      groupIndex = group.findIndex(isSameExercise(groupedData[index]));
    }
    return isUp ? (groupIndex > 0) : (groupIndex < (group.length - 1));
  }, [groupedData, contentByGroup, isSameExercise]);

  const groupedColumns = useMemo(() => {
    let newColumns = [...columns];
    let colNames = newColumns.map(x => x.name);
    let filterColNames = columns.filter(x => x.options === undefined || x.options.filter !== false).map(x => x.name);
    filterColNames.forEach(colName => {
      let uniqueValues = groupedData.filter(x => !x.isTitle).map(x => x[colName]).filter((value, index, self) => self.indexOf(value) === index);
      let index = colNames.indexOf(colName);
      if (!(newColumns[index].options && newColumns[index].options.filterOptions)) {
        newColumns[index].options = {
          ...(newColumns[index].options),
          filterOptions: {
            names: uniqueValues,
            logic: (value, filters) => filters.length && value !== undefined && !filters.includes(value)
          }
        };
      }
    });
    if (groupedData && groupedData.length && (hasActionsCol(true) || hasActionsCol(false)) && !newColumns.find(x => x.name === "")) {
      newColumns.push({ // TODO egl: don't push actionCols repeatedly!
        name: "",
        label: "",
        empty: true,
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            let key = groupedData[dataIndex].isTitle ? 'titleRow' : 'contentRows';
            let { rowActions } = options;
            return (
              <>
                {(rowActions.move && rowActions.move[key]) ?
                  (<><Tooltip title={"Nach oben"}>
                    <span>
                      <IconButton onClick={() => handleMove(dataIndex, true)} disabled={!moveArrowEnabled(dataIndex, true)}>
                        <KeyboardArrowUpIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                    <Tooltip title={"Nach unten"}>
                      <span>
                        <IconButton onClick={() => handleMove(dataIndex, false)} disabled={!moveArrowEnabled(dataIndex, false)}>
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </span>
                    </Tooltip></>)
                  : ''}
                {(rowActions.copy && rowActions.copy[key]) ?
                  (<><Tooltip title={"Aufgabe kopieren"}>
                    <span>
                      <IconButton onClick={() => handleCopy(dataIndex)}>
                        <FileCopyIcon />
                      </IconButton>
                    </span>
                  </Tooltip></>)
                  : ''}
                {(rowActions.delete && rowActions.delete[key]) ?
                  (<><Tooltip title={"Aufgabe löschen"}>
                    <span>
                      <IconButton onClick={() => handleDelete(dataIndex)}>
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip></>)
                  : ''}
              </>
            );
          }
        }
      });
    }
    return newColumns;
  }, [columns, groupedData, handleMove, handleCopy, handleDelete, moveArrowEnabled, hasActionsCol, options]);

  const groupedOptions = useMemo(() => ({
    ...options,
    rowsSelected: options.rowsSelected && options.rowsSelected.length === data.length ? groupedData.map((_x, i) => i) : options.rowsSelected,
    onRowSelectionChange: onRowSelectionChange,
    customRowRender: (data, dataIndex, rowIndex) => {
      let dataRow = groupedData[dataIndex];
      if (dataRow.isTitle) {
        let hasActions = hasActionsCol(true);
        let nCols = (options.selectableRowsOnClick === true ? 1 : 0) + 3 - (hasActions ? 1 : 0); // TODO egl: Morge generic Object.keys(columns).length instead of '3'
        return (
          <TableRow>
            <TableCell key={0} colSpan={nCols} style={{ fontWeight: 'bold', paddingTop: '30px', paddingBottom: '5px' }}>
              {dataRow.groupTitle}
            </TableCell>
            {hasActions ? (<TableCell key={1} style={{ textAlign: 'right' }}>{data[data.length - 1](dataIndex, dataIndex)}</TableCell>) : ''}
          </TableRow>
        );
      } else {
        let lastItem = data[data.length - 1];
        let lastIndexParam = hasActionsCol(false) ? dataIndex : dataRow.dataIndex;
        return (
          <TableRow onClick={() => { if (options.rowsSelected) rowClicked(dataRow.dataIndex); }} hover={options.rowsSelected ? true : false} selected={options.rowsSelected && (options.rowsSelected.indexOf(dataRow.dataIndex) !== -1)}>
            {options.rowsSelected ? (
              <TableCell align="left" padding='none' style={{ paddingLeft: '4px' }}>
                <Checkbox
                  checked={options.rowsSelected.indexOf(dataRow.dataIndex) !== -1}
                  color="primary"
                />
              </TableCell>
            ) : ''}
            {data.filter((_x, i) => i < (data.length - 1)).map((item, index) => <TableCell key={index}>{typeof (item) === 'function' ? item(dataRow.dataIndex, dataRow.dataIndex) : item}</TableCell>)}
            <TableCell key={data.length - 1}>{typeof (lastItem) === 'function' ? lastItem(lastIndexParam, lastIndexParam) : lastItem}</TableCell>
          </TableRow>
        );
      }
    },
    customSearch: (searchQuery, currentRow, columns) => {
      if (!currentRow.some(x => x !== undefined)) return true;
      let matchFound = false;
      currentRow.every(val => {
        matchFound = typeof (val) === 'string' && val.toLowerCase().includes(searchQuery.toLowerCase());
        return !matchFound;
      });
      return matchFound;
    },
    customSort: (data, colIndex, order, meta) => {
      let groupsToSort = [];
      let currentGroup = [];
      let groupSizes = [];
      data.forEach((item, i) => {
        if (item.data[colIndex]) currentGroup.push(item);
        else {
          if (i > 0) groupsToSort.push(currentGroup);
          groupSizes.push(currentGroup.length);
          currentGroup = [];
        }
      });
      groupsToSort.push(currentGroup);
      let resultData = [];
      let lastTitleIndex = -1;
      groupsToSort.forEach((group, i) => {
        let sortedGroup = options.customSort(group, colIndex, order, meta);
        lastTitleIndex += groupSizes[i] + 1;
        resultData = [...resultData, { index: lastTitleIndex, data: [undefined, undefined] }, ...sortedGroup];
      });
      return resultData;
    }
  }), [groupedData, columns, options, rowClicked, onRowSelectionChange, hasActionsCol]);

  // Return table
  return (
    <MUIDataTable
      title={title}
      data={groupedData}
      columns={groupedColumns}
      options={groupedOptions}
    />
  );
}