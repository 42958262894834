import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants';
import { DidactoColor } from '../../types/App';

type InlineRouteLinkProps = {
    hovercolor?: DidactoColor,
    activecolor?: DidactoColor,
    color?: DidactoColor,
    visitedcolor?: DidactoColor,
    margin?: string;
    fontSize?: string;
};

export const InlineRouteLink = styled(Link)<InlineRouteLinkProps>(({ 
    hovercolor, activecolor, color, visitedcolor, margin, fontSize 
}) => ({
    display: 'inline-block',
    fontFamily: 'TisaSansPro !important',
    margin: margin ?? "initial",
    fontSize: fontSize ?? "inherit",
    color: (color ?? "white") + " !important",
    '&:hover': {
        color: hovercolor ?? COLORS.DIDACTO_LIGHT_RED + ' !important',
    },
    '&:active': {
        color: activecolor ?? COLORS.DIDACTO_LIGHT_RED + ' !important',
    },
    '&:visited': {
        color: visitedcolor ?? COLORS.DIDACTO_GREY + ' !important',
    }
}));

export type HyperLinkColors = {
    linkcolor: DidactoColor,
    visitedcolor: DidactoColor,
    hovercolor: DidactoColor,
    activecolor: DidactoColor
};

export const HyperLink = styled('a')<Partial<HyperLinkColors>>(({ 
    linkcolor, visitedcolor, hovercolor, activecolor 
}) => ({
    '&:link': {
        textDecoration: 'none',
        color: linkcolor,
    },
    '&:visited': {
        textDecoration: 'none',
        color: visitedcolor,
    },
    '&:hover': {
        textDecoration: 'none',
        color: hovercolor,
    },
    '&:active': {
        textDecoration: 'none',
        color: activecolor,
    }
}));
