import { COLORS } from "../../constants";
import { DownloadButton, Button } from "../Buttons";
import { DidactoColor, FileDescription } from "../../types/App";
import * as S from './Styles';
import { useSelector } from "react-redux";
import { UserProfile } from "../../pages/CheckoutPage/userProfileSlice";
import { RootState } from "../..";
import { PDFPackage } from "../../domains/Product/Product";

export type DownloadSectionProps = {
    backgroundColor?: DidactoColor,
    fileDescriptions: {
        [key in PDFPackage]?: FileDescription
    }
}

export const DownloadSection = ({
    backgroundColor = COLORS.DIDACTO_GREY,
    fileDescriptions,
}: DownloadSectionProps) => {
    const { pdfAccess } = useSelector<RootState, UserProfile>(state => state.userProfile);
    return (
        <S.DownloadSection backgroundColor={backgroundColor}>
            {
                pdfAccess.map(pdfPackage => {
                    const fileDescription = fileDescriptions[pdfPackage];
                    return fileDescription ?
                        <S.ProductDownload key={fileDescription.path}>
                            {
                                (fileDescription?.name === "Mathe-Package 3. Klasse" && new Date() < new Date('2023-07-15T00:00')) ?
                                    <Button id="grade-3-download-button"
                                        disabled={true}
                                        style={{ color: COLORS.DIDACTO_BLUE }}
                                        variant="blue"
                                        text="Mathe-Package 3. Klasse verfügbar ab 17. Juli 2023"
                                    /> :
                                    <DownloadButton variant="blue" path={fileDescription.path} text={fileDescription.name} />
                            }
                        </S.ProductDownload> :
                        null
                })
            }
        </S.DownloadSection>
    )
}