import React, { useState } from "react";
import * as C from "../../constants";
import { Input } from "../Inputs";
import { Button } from "../Buttons";
import { validateEmail } from "../../utils";
import { ErrorDisplay, SuccessDisplay } from "../Displays";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import * as S from "./Styles";
import { InlineRouteLink } from "../Links";

type PasswordResetFormProps  = { 
    next?: () => void,
    width?: string,
    maxWidth?: string,
    justifyButton?: string,
}
export const PasswordResetForm = ({
    next,
    width,
    maxWidth,
    justifyButton,
}: PasswordResetFormProps) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState<Error | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const isInvalid = !validateEmail(email);

    const passwordReset = async ({ email }: {email: string}) => {
        try {
            setError(null);
            setSuccess(false);
            const url = process.env.REACT_APP_CONFIRM_EMAIL_ROUTE ?? "https://www.didacto.ch/signin"
            await sendPasswordResetEmail(auth, email, {url});
            if (next) {
                next();
            } else {
                setSuccess(true);
            }
        } catch (error) {
            if (error instanceof Error){
                setError(error);
            } else {
                setError(new Error("Unhandled error"));
            }
        }
    }

    return (
        <S.Form onSubmit={(e) => { e.preventDefault(); passwordReset({ email })}} width={width} maxWidth={maxWidth}>
            <Input id="email-"
                name="email"
                type="email"
                placeholder="E-Mail"
                label="E-Mail"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setError(null); setSuccess(false); setEmail(e.target.value) }}
                secondaryColor={C.COLORS.DIDACTO_DARK_BLUE}
            />
            {error ? <ErrorDisplay error={error} padding="0.5rem" /> : null}
            {success ? <SuccessDisplay message={`Ein Link, um dein Passwort zurückzusetzen, wurde an ${email} geschickt.`} padding="0.5rem" /> : null}
            <Button id="pw-reset-button"
                type="submit"
                variant="blue"
                text="Passwort zurücksetzen"
                disabled={isInvalid}
                marginTop="1.5rem"
                width="100%"
                justifyContent={justifyButton} />
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <InlineRouteLink to={C.PROTECTED_ROUTES.SIGN_IN}
                    variant="blue"
                    margin="3rem 0 0 0"
                    fontSize="1.7rem">
                    Zum Login
                </InlineRouteLink>
            </div>
        </S.Form>
    )
}