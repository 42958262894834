import { styled } from '@mui/material/styles';

type CardBoxProps = {
    width?: string;
};

export const CardBox = styled('div')<CardBoxProps>(({ width = '100%' }) => ({
    width: width,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
}));
