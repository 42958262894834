import { useState, useEffect } from 'react';

export type RGBColor = [number, number, number];

/**
 * Get a color from linear gradient based on relative scroll position.
 * 
 * @param startColor 
 * @param endColor 
 */

interface UseGradientParams {
    startColor: RGBColor
    endColor: RGBColor
    slope?: number
}
export const useColorGradient = ({startColor, endColor, slope}: UseGradientParams) => {
    const relScrollPosition = useRelativeScrollPosition();
    const diffColor = startColor.map((start, i) => Math.max(start - endColor[i], 0));
    const currentColor = startColor.map((start, i) => {
        const current = start - (diffColor[i] * relScrollPosition);
        return Math.max(current, endColor[i]);
    });
    const [r, g, b] = currentColor;
    return `rgb(${r},${g},${b})`;
}

/**
 * Get opacity based on relative scroll position (fade out on scroll down).
 * 
 * @param scrollOffset {number} - leave opacity at 1 as long as rel. scroll position is lower than offset
 * @param scrollCut {number} - set opacity to 0 as soon as rel. scroll position reaches cut value
 * @param fadeSpeed {number}
 * @returns opacity value beteween 0 and 1
 */
export const useFadeOutOnScroll = (
    {scrollOffset, scrollCut, fadeSpeed=1}:
    {scrollOffset: number, scrollCut: number, fadeSpeed: number}
) => {
    const relScrollPos = useRelativeScrollPosition();
    let opacity = 1 - (fadeSpeed * relScrollPos);

    if (relScrollPos < scrollOffset){
        opacity = 1;
    }
    if (relScrollPos > scrollCut){
        opacity = 0;
    }
    return Math.max(0, Math.min(1, opacity));
};


export const useRelativeScrollPosition = () => {
    const [relScrollPosition, setRelScrollPosition] = useState(0);
    useEffect(() => {
        const onScroll = () => { 
            setRelScrollPosition(window.scrollY / window.innerHeight)
        }
    
        if (window) {
            window.addEventListener('scroll', onScroll);
        }
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [relScrollPosition]);

    return Math.min(relScrollPosition, 1);
}

