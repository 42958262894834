import React, { useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import MUIDataTable from "mui-datatables";
import * as S from "./Styles";
import {theme} from './theme';
import { ThemeProvider } from '@mui/material/styles';
import {
    subscribeToUserDocPaths,
    deleteWorkPlan,
    deleteWorkPlanFolder,
    insertWorkPlan,
    insertFolder,
    moveWorkPlan,
    moveWorkPlanFolder,
    renameWorkPlan,
    renameWorkPlanFolder
} from './workPlanActions';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
    TableRow,
    TableCell,
    IconButton,
    Tooltip,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    TextField,
    CssBaseline
} from '@mui/material';
import GridOnIcon from '@mui/icons-material/GridOn';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LaunchIcon from '@mui/icons-material/Launch';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FolderIcon from '@mui/icons-material/Folder';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationDialog from "./Dialogs/ConfirmationDialog";
import AddItemDialog from "./Dialogs/AddItemDialog";
import PdfExportDialog from "./Dialogs/PdfExportDialog";
import MoveWorkPlanDialog from "./Dialogs/MoveWorkPlanDialog";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import printWorkplanPdf from './WorkplanPDF/WorkplanPDF.js';
import OverviewNavigation from "./OverviewNavigation";
import WorkPlanEdit from "./WorkPlanEdit";
import AddSpeedDial from "./AddSpeedDial";

import firebaseAPI from "./firebase";
import workPlanReducer, { INITIAL_STATE } from "./workPlanReducer";
import { COLORS } from "../../constants";
import { createTheme } from '@mui/system';

const customTheme = createTheme(theme, {
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    marginTop: "16px",
                    minHeight: "64px",
                    '& .MuiSvgIcon-root': {
                      width: "20px",
                      height: "20px",
                      '& .MuiButtonBase-root:hover &': {
                        color: `${COLORS.DIDACTO_DARK_BLUE} !important`,
                      }
                    }
                }
            }
        },
        MuiTable: {
          styleOverrides: {
            root: {
              marginBottom: '40px'
            }
          }
        },
        MuiToolbar: {
			styleOverrides: {
				root: {
					paddingLeft: '16px !important'
				}
			}
        },
        MuiTableRow: {
            styleOverrides: {
              root: {
                '& > th': {
                  paddingTop: 0,
                  paddingBottom: 0,
                  zIndex: 0,
                  fontSize: "16px",
                  fontFamily: "TisaSansPro-Bold",
                  '& button > div': {
                    display: "flex",
                    alignItems: "center",
                  }
                },
                '& td': {
                  fontSize: "16px",
                  cursor: "pointer",
                  minWidth: '200px', // Applies only to first child with custom setup
                }
              }
            }
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              '&:nth-of-type(1)': {
                minWidth: '200px',
                width: '25%',
              },
              '&:nth-of-type(2)': {
                width: '30%',
              }
            }
          }
        },
		MuiTableFooter: {
			styleOverrides: {
				root: {
					"& tr": {
						height: "68.5px"
					},
                    "& .MuiToolbar-root": {
                        marginTop: '16px !important'
                    }
				}
			}
		}
    }
});

// Table config
export const localOptions = {
    body: {
        noMatch: "Keine Daten gefunden",
        toolTip: "Sortieren",
        columnHeaderTooltip: column => `Sortieren nach ${column.label}`
    },
    pagination: {
        next: "Weiter",
        previous: "Zurück",
        rowsPerPage: "Zeilen pro Seite:",
        displayRows: "von",
    },
    toolbar: {
        search: "Suche",
        downloadCsv: "Download CSV",
        print: "Drucken",
        viewColumns: "Spalten anzeigen",
        filterTable: "Tabelle filtern",
    },
    filter: {
        all: "Alle",
        title: "FILTER",
        reset: "RESET",
    },
    viewColumns: {
        title: "Spalten anzeigen",
        titleAria: "Spalten Anzeigen/Verstecken",
    },
    selectedRows: {
        text: "Zeile(n) ausgewählt",
        delete: "Löschen",
        deleteAria: "Ausgewählte Zeile(n) löschen",
    }
};


export default function WorkPlanOverview() {
    // State, Redux & Firebase
    const location = useLocation();
    const [wpState, dispatch] = useReducer(workPlanReducer, INITIAL_STATE);
    const { paths, wpAccess } = wpState;
    const user = useSelector(state => state.app.user);
    const userId = user?.uid;
    const pathString = decodeURI(location.pathname.replace('/wp', '')) || '/';
    const path = paths ? paths[pathString] : null;
    const validGrades = wpAccess ? Object.keys(wpAccess).filter(key => {
        const fromDate = new Date(wpAccess[key].validFrom.seconds * 1000 + wpAccess[key].validFrom.nanoseconds / 1000000);
        const toDate = new Date(wpAccess[key].validTo.seconds * 1000 + wpAccess[key].validTo.nanoseconds / 1000000);
        const now = new Date();
        return fromDate < now && toDate > now;
    }).map(grade => +grade) : null;
    const expiredGrades = wpAccess ? Object.keys(wpAccess).filter(key => {
        const toDate = new Date(wpAccess[key].validTo.seconds * 1000 + wpAccess[key].validTo.nanoseconds / 1000000);
        return toDate < new Date();
    }).map(grade => +grade) : null;
    const navigate = useNavigate();
    if (pathString && pathString !== "/" && paths && !path) navigate("/wp");
    const subPaths = useCallback(() => {
        if (userId) {
            subscribeToUserDocPaths({ userId, firebaseAPI, dispatch })
        }
    }, [userId, dispatch]);
    useEffect(() => {
        return subPaths();
    }, [subPaths]);

    const [deletingWorkplanId, setDeletingWorkplanId] = React.useState(null);
    const [printingPdfWorkPlanId, setPrintingPdfWorkPlanId] = React.useState(null);
    const [movingWorkPlanId, setMovingWorkPlanId] = React.useState(null);
    const [renamingWorkPlan, setRenamingWorkPlan] = React.useState(null);
    const [creatingFolder, setCreatingFolder] = React.useState(null);
    const [selectedWorkPlans, setSelectedWorkPlans] = React.useState([]);
    const [hoveringWorkPlanId, setHoveringWorkPlanId] = React.useState(null);
    const [openingExpiredWorkPlanId, setOpeningExpiredWorkPlanId] = React.useState(null);
    const [contextMenuPosition, setContextMenuPosition] = React.useState(null);
    const [editingId, setEditingId] = React.useState(null);

    // Excel export
    const exportToCSV = (rowIndex) => {
        firebaseAPI.fetchWorkplan({ userId, path, workPlanId: paths[pathString][rowIndex].id })
            .then(workPlan => {
                let currObj = "";
                let wpData = workPlan.finalTableRows.map(x => ({ Kompetenz: x.objective, Aufgabe: x.exercise, Niveau: x.level }));
                wpData.forEach((item, i) => {
                    if (currObj === item.Kompetenz) wpData[i].Kompetenz = "";
                    else currObj = item.Kompetenz;
                });
                const ws = XLSX.utils.json_to_sheet(wpData);
                const wb = { Sheets: { 'Arbeitsplan': ws }, SheetNames: ['Arbeitsplan'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                FileSaver.saveAs(data, "Arbeitsplan_" + paths[pathString][rowIndex].name.replace(/\s+/, "_") + ".xlsx");
            });
    }

    // Excel export
    const exportToPdf = (rowIndex, options) => {
        firebaseAPI.fetchWorkplan({ userId, path, workPlanId: paths[pathString][rowIndex].id })
            .then(workPlan => {
                firebaseAPI.fetchTopics({ grade: workPlan.grade }).then(topics => {
                    printWorkplanPdf(workPlan, topics, options);
                });
            });
    }

    // Copy workplan
    const isSamePlan = (plan) => {
        return (element) => plan.name === element.name && plan.grade === element.grade;
    }
    const copyWorkPlan = (rowIndex) => {
        firebaseAPI.fetchWorkplan({ userId, path, workPlanId: paths[pathString][rowIndex].id })
            .then(workPlan => {
                let tempName = workPlan.name + " Kopie";
                while (paths[pathString].findIndex(isSamePlan({ name: tempName, grade: workPlan.grade })) !== -1) {
                    tempName += " Kopie";
                }
                workPlan.name = tempName;
                insertWorkPlan({ userId, pathString, workPlan, firebaseAPI, index: rowIndex + 1 });
            });
    }


    const colDef = [
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
                sortThirdClickReset: true
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true
            }
        },
        {
            name: 'grade',
            label: 'Stufe',
            options: {
                sortThirdClickReset: true
            }
        }
    ];
    const options = {
        textLabels: localOptions,
        fixedHeader: true,
        tableBodyHeight: '400px',
        download: false,
        print: false,
        delete: false,
        viewColumns: false,
        selectableRows: 'single',
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 15, 100],
        rowsSelected: selectedWorkPlans,
        onRowSelectionChange: (_curr, _all, rows) => setSelectedWorkPlans(rows),
        customToolbarSelect: (_selectedRows, _displayData, _setSelectedRows) => {
            const isFolder = paths[pathString].length > 0 && paths[pathString][selectedWorkPlans[0]].isDirectory;
            return (
                <div>
                    <Tooltip title={!isEditable(selectedWorkPlans[0])? "Bitte erneuere deine Lizenz im Shop, um wieder auf deine Arbeitspläne zugreifen zu können." : (isFolder ? "Ordner öffnen" : "Arbeitsplan bearbeiten")}>
                        <span>
                            <IconButton disabled={!isEditable(selectedWorkPlans[0])} onClick={() => { handleEdit(selectedWorkPlans[0]); setContextMenuPosition(null); }}>
                                <LaunchIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={(isFolder ? "Ordner" : "Arbeitsplan") + " umbenennen"}>
                        <span>
                            <IconButton onClick={() => { setRenamingWorkPlan({ id: selectedWorkPlans[0], name: paths[pathString][selectedWorkPlans[0]].name }); setContextMenuPosition(null); }}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={(isFolder ? "Ordner" : "Arbeitsplan") + " verschieben"}>
                        <span>
                            <IconButton onClick={() => { setMovingWorkPlanId(selectedWorkPlans[0]); setContextMenuPosition(null); }}>
                                <DriveFileMoveIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    {isFolder ? '' :
                        (
                            <>
                                <Tooltip title={!isEditable(selectedWorkPlans[0])? "Bitte erneuere deine Lizenz im Shop, um wieder auf deine Arbeitspläne zugreifen zu können." : "Arbeitsplan kopieren"}>
                                    <span>
                                        <IconButton disabled={!isEditable(selectedWorkPlans[0])} onClick={() => { copyWorkPlan(selectedWorkPlans[0]); setContextMenuPosition(null); }}>
                                            <FileCopyIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title={!isEditable(selectedWorkPlans[0])? "Bitte erneuere deine Lizenz im Shop, um wieder auf deine Arbeitspläne zugreifen zu können." : "PDF Export"}>
                                    <span>
                                        <IconButton disabled={!isEditable(selectedWorkPlans[0])} onClick={() => { setPrintingPdfWorkPlanId(selectedWorkPlans[0]); setContextMenuPosition(null); }}>
                                            <PrintIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title={!isEditable(selectedWorkPlans[0])? "Bitte erneuere deine Lizenz im Shop, um wieder auf deine Arbeitspläne zugreifen zu können." : "Excel Export"}>
                                    <span>
                                        <IconButton disabled={!isEditable(selectedWorkPlans[0])}  onClick={() => { exportToCSV(selectedWorkPlans[0]); setContextMenuPosition(null); }}>
                                            <GridOnIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </>
                        )
                    }
                    <Tooltip title={(isFolder ? "Ordner" : "Arbeitsplan") + " löschen"}>
                        <span>
                            <IconButton onClick={() => { handleDelete(selectedWorkPlans[0]); setContextMenuPosition(null); }}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            );
        },
        customRowRender: (data, dataIndex, _rowIndex) => {
            return (
                <TableRow
                    key={dataIndex + "row"}
                    onClick={() => options.onRowSelectionChange(null, null, selectedWorkPlans.includes(dataIndex) ? [] : [dataIndex])}
                    onDoubleClick={() => {isEditable(dataIndex)? handleEdit(dataIndex) : setOpeningExpiredWorkPlanId(dataIndex)}}
                    onContextMenu={(e) => { e.preventDefault(); options.onRowSelectionChange(null, null, [dataIndex]); setContextMenuPosition({ index: dataIndex, position: { top: e.clientY - 4, left: e.clientX - 2 } }); }}
                    hover={true}
                    onMouseEnter={() => setHoveringWorkPlanId(dataIndex)}
                    onMouseLeave={() => setHoveringWorkPlanId(null)}
                    selected={selectedWorkPlans.includes(dataIndex)}
                >
                    <TableCell style={{color: isEditable(dataIndex)? COLORS.DIDACTO_BLACK : "#7B7D7D", opacity: 0.78}} key={dataIndex + "_0"}>
                        {paths[pathString][dataIndex].isDirectory ?
                            (<FolderIcon style={{ marginBottom: "-10px", marginRight: "10px", paddingBottom: "5px", width: "20px", height: "20px" }} />) :
                            (<ListAltIcon style={{ marginBottom: "-10px", marginRight: "10px", paddingBottom: "5px", width: "20px", height: "20px" }} />)
                        }
                        {data[0]}
                    </TableCell>
                    <TableCell style={{ color: isEditable(dataIndex)? COLORS.DIDACTO_BLACK : "#7B7D7D", paddingTop: 0, paddingBottom: 0 }}>
                        <Tooltip title={"Aktionen anzeigen"} hidden={!selectedWorkPlans.includes(dataIndex) && hoveringWorkPlanId !== dataIndex}>
                            <span>
                                <IconButton onClick={(e) => setContextMenuPosition({ index: dataIndex, position: { top: e.clientY - 4, left: e.clientX - 2 } })}>
                                    <MoreVertIcon fontSize="large" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </TableCell>
                    <TableCell style={{color: isEditable(dataIndex)? COLORS.DIDACTO_BLACK : "#7B7D7D"}} key={dataIndex + "_1"}>{paths[pathString][dataIndex].isDirectory ? "-" : data[2]}</TableCell>
                </TableRow>
            );
        }
    };

    // Handler
    const handleRename = () => {
        const wp = paths[pathString][renamingWorkPlan.id];
        if (wp.isDirectory) renameWorkPlanFolder({ userId, pathString, folderId: wp.id, prevName: wp.name, newName: renamingWorkPlan.name, firebaseAPI });
        else renameWorkPlan({ userId, pathString, workPlanId: wp.id, name: renamingWorkPlan.name, firebaseAPI });
        setRenamingWorkPlan(null);
        setSelectedWorkPlans([]);
    }
    const isValidName = name => {
        let workPlans = paths[pathString] ?? [];
        return (name.trim() !== '') &&
            !name.includes('/') &&
            !workPlans.map(wp => wp.name).includes(name);
    }
    const handleNewFolder = () => {
        insertFolder({
            userId,
            pathString,
            name: creatingFolder,
            firebaseAPI
        });
        setCreatingFolder(null);
    }
    const handleEdit = (rowId) => {
        setSelectedWorkPlans([]);
        let wp = paths[pathString][rowId];
        if (wp.isDirectory) {
            let current = pathString + (pathString === '/' ? '' : '/');
            navigate("/wp" + current + encodeURI(wp.name));
        } else {
            setEditingId(wp.id);
        }
    }
    const handleDelete = (rowId) => {
        setDeletingWorkplanId(rowId);
    }
    const handleDeleteConfirm = () => {
        const wp = paths[pathString][deletingWorkplanId];
        if (wp.isDirectory) deleteWorkPlanFolder({ userId, pathString, folderId: wp.id, name: wp.name, firebaseAPI });
        else deleteWorkPlan({ userId, pathString, workPlanId: wp.id, firebaseAPI });
        setDeletingWorkplanId(null);
        setSelectedWorkPlans([]);
    }
    const handleMoveConfirm = (targetPath) => {
        const wp = paths[pathString][movingWorkPlanId];
        if (wp.isDirectory) moveWorkPlanFolder({ userId, pathString, newPathString: targetPath, folderId: paths[pathString][movingWorkPlanId].id, firebaseAPI })
        else moveWorkPlan({ userId, pathString, newPathString: targetPath, workPlanId: paths[pathString][movingWorkPlanId].id, firebaseAPI })
        setMovingWorkPlanId(null);
        setSelectedWorkPlans([]);
    }

    // Helper
    const isFolder = context => {
        return context && paths[pathString][context.index].isDirectory;
    }
    const isEditable = index => {
        let wp = paths[pathString][index];
        return wp.isDirectory || (validGrades && validGrades.includes(wp.grade));
    }
    const isDeletingDir = () => {
        return deletingWorkplanId && paths && paths[pathString] && (paths[pathString][deletingWorkplanId]).isDirectory
    }

    // Return Component
    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline />
            {(validGrades && validGrades.length > 0 && editingId) ? (<WorkPlanEdit id={editingId} grades={validGrades.sort()} expiredGrades={expiredGrades} path={path} pathString={pathString} done={() => setEditingId(null)} />) :
                (
                    <S.StyledStack>
                        <OverviewNavigation path={pathString} />
                        <MUIDataTable
                            title={"Arbeitspläne"}
                            data={(paths && paths[pathString]) ? paths[pathString] : []}
                            columns={colDef}
                            options={options} />
                        <Menu
                            keepMounted
                            open={contextMenuPosition !== null}
                            onClose={() => setContextMenuPosition(null)}
                            anchorReference="anchorPosition"
                            anchorPosition={contextMenuPosition ? contextMenuPosition.position : undefined}
                        >
                            {(contextMenuPosition && !isEditable(contextMenuPosition.index)) ? (
                                <div>
                                    <Tooltip title="Bitte erneuere deine Lizenz im Shop, um wieder auf deine Arbeitspläne zugreifen zu können.">
                                        <div>
                                            <MenuItem disabled={true} onClick={() => { handleEdit(contextMenuPosition.index); setContextMenuPosition(null); } }>
                                                <ListItemIcon style={{ minWidth: 31 }}><LaunchIcon /></ListItemIcon>
                                                <ListItemText primary={"Bearbeiten"} />
                                            </MenuItem>
                                        </div>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div>
                                    <MenuItem onClick={() => { handleEdit(contextMenuPosition.index); setContextMenuPosition(null); } }>
                                        <ListItemIcon style={{ minWidth: 31 }}><LaunchIcon /></ListItemIcon>
                                        <ListItemText primary={isFolder(contextMenuPosition) ? "Öffnen" : "Bearbeiten"} />
                                    </MenuItem>
                                </div>
                            )}
                            <Divider />
                            <MenuItem onClick={() => { setRenamingWorkPlan({ id: contextMenuPosition.index, name: paths[pathString][contextMenuPosition.index].name }); setContextMenuPosition(null); } }>
                                <ListItemIcon style={{ minWidth: 31 }}><EditIcon /></ListItemIcon>
                                <ListItemText primary="Umbenennen" />
                            </MenuItem>
                            <MenuItem onClick={() => { setMovingWorkPlanId(contextMenuPosition.index); setContextMenuPosition(null); } }>
                                <ListItemIcon style={{ minWidth: 31 }}><DriveFileMoveIcon /></ListItemIcon>
                                <ListItemText primary="Verschieben" />
                            </MenuItem>
                            {isFolder(contextMenuPosition) ? '' :
                                <div key={"Menu"}>
                                    {(contextMenuPosition && !isEditable(contextMenuPosition.index)) ?
                                        (
                                            <div>
                                                <Tooltip title="Bitte erneuere deine Lizenz im Shop, um wieder auf deine Arbeitspläne zugreifen zu können.">
                                                    <div>
                                                        <MenuItem disabled={true}>
                                                            <ListItemIcon style={{ minWidth: 31 }}><FileCopyIcon /></ListItemIcon>
                                                            <ListItemText primary="Kopieren" />
                                                        </MenuItem>
                                                    </div>
                                                </Tooltip>
                                                <Divider />
                                                <Tooltip title="Bitte erneuere deine Lizenz im Shop, um wieder auf deine Arbeitspläne zugreifen zu können.">
                                                    <div>
                                                        <MenuItem disabled={true}>
                                                            <ListItemIcon style={{ minWidth: 31 }}><PrintIcon /></ListItemIcon>
                                                            <ListItemText primary="PDF Generieren" />
                                                        </MenuItem>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Bitte erneuere deine Lizenz im Shop, um wieder auf deine Arbeitspläne zugreifen zu können.">
                                                    <div>
                                                        <MenuItem disabled={true}>
                                                            <ListItemIcon style={{ minWidth: 31 }}><GridOnIcon /></ListItemIcon>
                                                            <ListItemText primary="Excel Exportieren" />
                                                        </MenuItem>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) :
                                        (
                                            <div>
                                                <MenuItem onClick={() => { copyWorkPlan(contextMenuPosition.index); setContextMenuPosition(null); } }>
                                                    <ListItemIcon style={{ minWidth: 31 }}><FileCopyIcon /></ListItemIcon>
                                                    <ListItemText primary="Kopieren" />
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem onClick={() => { setPrintingPdfWorkPlanId(contextMenuPosition.index); setContextMenuPosition(null); } }>
                                                    <ListItemIcon style={{ minWidth: 31 }}><PrintIcon /></ListItemIcon>
                                                    <ListItemText primary="PDF Generieren" />
                                                </MenuItem>
                                                <MenuItem onClick={() => { exportToCSV(contextMenuPosition.index); setContextMenuPosition(null); } }>
                                                    <ListItemIcon style={{ minWidth: 31 }}><GridOnIcon /></ListItemIcon>
                                                    <ListItemText primary="Excel Exportieren" />
                                                </MenuItem>
                                            </div>
                                        )}
                                </div>}
                            <Divider />
                            <MenuItem onClick={() => { handleDelete(contextMenuPosition.index); setContextMenuPosition(null); } }>
                                <ListItemIcon style={{ minWidth: 31 }}><DeleteIcon /></ListItemIcon>
                                <ListItemText primary="Löschen" />
                            </MenuItem>
                        </Menu>
                        <AddSpeedDial hasValidGrades={validGrades && validGrades.length > 0} addWorkPlan={() => setEditingId('new')} addFolder={() => setCreatingFolder("Neuer Ordner")} />
                        <ConfirmationDialog
                            isOpen={deletingWorkplanId != null}
                            handleCancel={() => setDeletingWorkplanId(null)}
                            handleConfirm={() => handleDeleteConfirm()}
                            title={`${isDeletingDir() ? "Ordner" : "Arbeitsplan"} löschen?`}
                            text={`Möchten Sie den ${isDeletingDir() ? "Ordner" : "Arbeitsplan"} wirklich unwiderruflich löschen?`}
                            cancelTitle="Abbrechen"
                            confirmTitle="Löschen" />
                        <ConfirmationDialog
                            isOpen={openingExpiredWorkPlanId != null}
                            handleCancel={() => setOpeningExpiredWorkPlanId(null)}
                            handleConfirm={() => setOpeningExpiredWorkPlanId(null)}
                            title="Lizenz abgelaufen"
                            text={<>Bitte erneuere deine Lizenz im <Link style={{ textDecoration: "underline" }} to='/shop'>Shop</Link>, um wieder auf deine Arbeitspläne zugreifen zu können.</>}
                            confirmTitle="OK" />
                        {renamingWorkPlan !== null ?
                            (
                                <AddItemDialog open={renamingWorkPlan !== null}
                                    handleCancel={() => setRenamingWorkPlan(null)}
                                    handleConfirm={handleRename}
                                    title={"Arbeitsplan umbenennen"}
                                    confirmText={"Umbenennen"}
                                    confirmAllowed={isValidName(renamingWorkPlan.name)}>
                                    <TextField
                                        margin="dense"
                                        id="name"
                                        label="Name"
                                        required
                                        error={!isValidName(renamingWorkPlan.name)}
                                        style={{ width: 330 }}
                                        autoFocus
                                        value={renamingWorkPlan.name}
                                        onChange={(e) => { setRenamingWorkPlan({ ...renamingWorkPlan, name: e.target.value }); } }
                                        onKeyPress={(e) => { if (e.code === 'Enter' && isValidName(renamingWorkPlan.name)) handleRename(); } } />
                                </AddItemDialog>
                            ) : ''}
                        {creatingFolder !== null ?
                            (
                                <AddItemDialog open={creatingFolder !== null}
                                    handleCancel={() => setCreatingFolder(null)}
                                    handleConfirm={handleNewFolder}
                                    title={"Neuer Ordner"}
                                    confirmText={"Erstellen"}
                                    confirmAllowed={isValidName(creatingFolder)}>
                                    <TextField
                                        margin="dense"
                                        id="name"
                                        label="Name"
                                        required
                                        error={!isValidName(creatingFolder)}
                                        style={{ width: 330 }}
                                        autoFocus
                                        value={creatingFolder}
                                        onFocus={e => e.target.select()}
                                        onChange={e => { setCreatingFolder(e.target.value); } }
                                        onKeyPress={e => { if (e.code === 'Enter' && isValidName(creatingFolder)) handleNewFolder(); } } />
                                </AddItemDialog>
                            ) : ''}
                        {printingPdfWorkPlanId !== null ?
                            (
                                <PdfExportDialog
                                    workPlan={(printingPdfWorkPlanId === null) ? null : paths[pathString][printingPdfWorkPlanId]}
                                    handleCancel={() => setPrintingPdfWorkPlanId(null)}
                                    handleConfirm={(options) => {
                                        exportToPdf(printingPdfWorkPlanId, options);
                                        setPrintingPdfWorkPlanId(null);
                                    } } />
                            ) : ''}
                        {movingWorkPlanId !== null ?
                            (
                                <MoveWorkPlanDialog
                                    workPlan={(movingWorkPlanId === null) ? null : paths[pathString][movingWorkPlanId]}
                                    path={path}
                                    paths={paths}
                                    pathString={pathString}
                                    handleCancel={() => setMovingWorkPlanId(null)}
                                    handleConfirm={handleMoveConfirm} />
                            ) : ''}
                    </S.StyledStack>
                )}
        </ThemeProvider>
    );
}
