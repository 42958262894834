import { styled } from '@mui/material/styles';
import { COLORS } from "../../constants";

type DisplayProps = {
    width?: string,
    padding?: string,
    margin?: string,
};

export const ErrorDisplay = styled('div')<DisplayProps>(({ theme, width = "100%", padding = "0", margin = "1rem 0" }) => ({
    width: width,
    border: `1px solid ${COLORS.DIDACTO_RED}`,
    margin: margin,
    padding: padding,
    borderRadius: '2px',
    color: COLORS.DIDACTO_RED,
    fontSize: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: COLORS.DIDACTO_WHITE,
    textAlign: 'center',
}));

export const SuccessDisplay = styled(ErrorDisplay)(({ theme }) => ({
    border: `1px solid ${COLORS.DIDACTO_DARK_BLUE}`,
    color: COLORS.DIDACTO_BLUE,
}));
