import React from 'react';
import {StyledAccordion, StyledTypography} from '../Styles';
import AddItemDialog from "./AddItemDialog";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextField, MenuItem, AccordionDetails, AccordionSummary, Box } from "@mui/material";

const formRowSx = (theme) => ({
  display: "flex",
  width: "100%",
  "&> * + *": {
    marginLeft: "1rem !important",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    "&> * + *": {
      marginLeft: "0 !important",
    }
  },
});

const PdfExportDialog = ({ workPlan, handleCancel, handleConfirm }) => {
  const [workPlanOptions, setWorkPlanOptions] = React.useState({ title: workPlan.name, subtitle: '', showLevels: false, customExercisesTitle: "Weitere Aufgaben", customExercisesColumnTitle: "Kompetenz", defaultColor: 'grey', student: null });
  const [moreAccordionExpanded, setMoreAccordionExpanded] = React.useState(false);
  const optionsValid = () => workPlanOptions.title.trim() !== '' && workPlanOptions.customExercisesTitle.trim() !== '' && workPlanOptions.customExercisesColumnTitle.trim() !== '';
  const colorMap = {
    grey: "Grau",
    blue: "Blau",
    yellow: "Gelb",
    lightgreen: "Hellgrün",
    darkgreen: "Dunkelgrün",
    orange: "Orange",
    red: "Rot"
  }
  return (
    <AddItemDialog open={true}
      handleCancel={handleCancel}
      handleConfirm={() => handleConfirm(workPlanOptions)}
      title={"PDF Exportieren"}
      description={"Konfiguriere dein PDF und klicke anschliessend auf 'Generieren'."}
      confirmText={"Generieren"}
      confirmAllowed={optionsValid()}>
      <StyledAccordion expanded={true}>
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ padding: "0" }}
        >
          <StyledTypography>Allgemeine Einstellungen</StyledTypography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0" }}>
          <TextField
            margin="dense"
            id="title"
            label="Titel Arbeitsplan"
            required
            error={workPlanOptions.title.trim() === ''}
            fullWidth
            autoFocus
            value={workPlanOptions.title}
            onChange={(e) => setWorkPlanOptions({ ...workPlanOptions, title: e.target.value })}
            onKeyPress={(e) => { if (e.code === 'Enter' && optionsValid()) handleConfirm(workPlanOptions) }}
          />
          <Box sx={formRowSx}>
            <TextField
              sx={{ flexGrow: 1 }}
              margin="dense"
              id="subtitle"
              label="Untertitel"
              value={workPlanOptions.subtitle}
              onChange={(e) => setWorkPlanOptions({ ...workPlanOptions, subtitle: e.target.value })}
              onKeyPress={(e) => { if (e.code === 'Enter' && optionsValid()) handleConfirm(workPlanOptions) }}
            />
            <TextField
              margin="dense"
              id="showLevels"
              label="Niveau-Spalten"
              select
              value={workPlanOptions.showLevels}
              onChange={(e) => setWorkPlanOptions({ ...workPlanOptions, showLevels: e.target.value })}
              onKeyPress={(e) => { if (e.code === 'Enter' && optionsValid()) handleConfirm(workPlanOptions) }}
            >
              <MenuItem key={"Einblenden"} value={true}>Einblenden</MenuItem>
              <MenuItem key={"Ausblenden"} value={false}>Ausblenden</MenuItem>
            </TextField>
          </Box>
          <Box sx={formRowSx}>
            <TextField
              margin="dense"
              id="showStudent"
              label="Persönlich"
              sx={{ minWidth: "87px" }}
              select
              value={workPlanOptions.student !== null}
              onChange={(e) => {
                setWorkPlanOptions({ ...workPlanOptions, student: e.target.value ? "" : null });
              }}
              onKeyPress={(e) => { if (e.code === 'Enter' && optionsValid()) handleConfirm(workPlanOptions) }}
            >
              <MenuItem key={"Ja"} value={true}>Ja</MenuItem>
              <MenuItem key={"Nein"} value={false}>Nein</MenuItem>
            </TextField>
            <TextField
              sx={{ flexGrow: 1 }}
              disabled={workPlanOptions.student === null}
              margin="dense"
              id="student"
              label="Name Schüler:in"
              value={workPlanOptions.student === null ? "Handschriftlich" : workPlanOptions.student}
              onChange={(e) => setWorkPlanOptions({ ...workPlanOptions, student: e.target.value })}
              onKeyPress={(e) => { if (e.code === 'Enter' && optionsValid()) handleConfirm(workPlanOptions) }}
            />
          </Box>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion expanded={moreAccordionExpanded} onChange={() => setMoreAccordionExpanded(!moreAccordionExpanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ padding: 0 }}
        >
          <StyledTypography>Einstellungen zu eigenen Kompetenzen</StyledTypography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "block", padding: "0" }}>
          <TextField
            margin="dense"
            id="customExercisesTitle"
            label="Titel Tabellen"
            required
            error={workPlanOptions.customExercisesTitle.trim() === ''}
            fullWidth
            value={workPlanOptions.customExercisesTitle}
            onChange={(e) => setWorkPlanOptions({ ...workPlanOptions, customExercisesTitle: e.target.value })}
            onKeyPress={(e) => { if (e.code === 'Enter' && optionsValid()) handleConfirm(workPlanOptions) }}
          />
          <Box sx={formRowSx}>
            <TextField
              sx={{flexGrow: "1"}}
              margin="dense"
              id="customExercisesColumnTitle"
              label="Titel 1. Spalte"
              required
              error={workPlanOptions.customExercisesColumnTitle.trim() === ''}
              value={workPlanOptions.customExercisesColumnTitle}
              onChange={(e) => setWorkPlanOptions({ ...workPlanOptions, customExercisesColumnTitle: e.target.value })}
              onKeyPress={(e) => { if (e.code === 'Enter' && optionsValid()) handleConfirm(workPlanOptions) }}
            />
            <TextField
              sx={{minWidth: "110px"}}
              margin="dense"
              id="defaultColor"
              label="Farbe Tabellen"
              select
              value={workPlanOptions.defaultColor}
              onChange={(e) => setWorkPlanOptions({ ...workPlanOptions, defaultColor: e.target.value })}
              onKeyPress={(e) => { if (e.code === 'Enter' && optionsValid()) handleConfirm(workPlanOptions) }}
            >
              {Object.entries(colorMap).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)}
            </TextField>
          </Box>
        </AccordionDetails>
      </StyledAccordion>
    </AddItemDialog>
  );
}

export default PdfExportDialog;
