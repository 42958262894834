import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppState } from "../../hooks";
import { WithChildren } from "../../types/App";

type RouteProps = {
    redirectTo: string,
    children: JSX.Element,
}

/**
 * Only display children if authenticated, else redirect
 */
export const PrivateRoute = ({ children, redirectTo }: RouteProps) => {
    const { isAuthenticated } = useAppState()
    return isAuthenticated ? children : <Navigate to={redirectTo}/>;
};

/**
 * Redirect when authenticated (e.g. for SignUp, SignIn)
 */
export const ProtectedRoute = ({ children, redirectTo }: RouteProps) => {
    const { isAuthenticated } = useAppState();
    return isAuthenticated ? <Navigate to={redirectTo} />: children;
};

/**
 * Scroll to Top Wrapper Component
 * All child components scroll to top at page load.
 */
export const ScrollToTop = ({children}: WithChildren) => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])
    return <>{children}</>;
}