import * as S from "./Styles";

type ErrorDisplayProps = {
    error: Error,
    padding?: string,
    width?: string,
    margin? : string,
}

export const ErrorDisplay = ({error, padding, width, margin}: ErrorDisplayProps) => {
    return <S.ErrorDisplay padding={padding} width={width} margin={margin}>{error.message}</S.ErrorDisplay>
};