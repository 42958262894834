import * as S from './Styles';
import {COLORS} from '../../constants';
import {DidactoColor} from '../../types/App';
import { useEffect, useState } from 'react';

export type InputProps = {
    id: string,
    name: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    validationError?: string,
    placeholder?: string,
    enabled?: boolean,
    secondaryColor?: DidactoColor,
    width?: string,
    insetShadow?: boolean,
    type?: string,
    label?: string,
    margin?: string,
    inputInfo?: string,
}

export const Input = ({
    id,
    name,
    value,
    placeholder="",
    enabled=false,
    onChange,
    validationError,
    secondaryColor=COLORS.DIDACTO_RED,
    width="100%",
    insetShadow=false,
    type="text",
    label,
    margin,
    inputInfo,
}: InputProps) => {
    const [displayLabel, setDisplayLabel] = useState<boolean>(false);
    const [displayInfoBox, setDisplayInfoBox] = useState<boolean>(false);
    const inputProps = {id, name, value, placeholder, enabled, onChange, insetShadow, type};
    
    useEffect(() => {
        if (value && value.length > 0){
            setDisplayLabel(true);
        } else {
            setDisplayLabel(false);
        }
    }, [value]);

    const infoIcon = inputInfo ? <S.InputInfoDisplay onMouseEnter={() => setDisplayInfoBox(true)}
                                                    onMouseLeave={() => setDisplayInfoBox(false)}></S.InputInfoDisplay> : null;
    const infoBox = 
    <S.InfoBox>
        <S.InfoBoxTitle >Info</S.InfoBoxTitle>
        {inputInfo}
    </S.InfoBox>;

    const onBlur = () => {
        return inputInfo ? setDisplayInfoBox(false) : null;
    }

    const onFocus = () => {
        return inputInfo ? setDisplayInfoBox(true) : null;

    }
    return (
        <S.InputWrapper width={width} margin={margin}>
            {
                displayLabel ?  
                <S.Label secondaryColor={secondaryColor} htmlFor={name}>{label}</S.Label> : 
                <S.Label secondaryColor={secondaryColor} htmlFor={name}>{""}</S.Label>
            }
            <div style={{display: "flex", position:"relative"}}>
                <S.Input {...inputProps} secondaryColor={secondaryColor} onFocus={onFocus} onBlur={onBlur}/>
                { infoIcon } 
                { displayInfoBox ? infoBox : null }

            </div>
            
            {   
                validationError ? 
                <S.InputValidationError secondaryColor={secondaryColor}>{validationError}</S.InputValidationError> :
                null
            }
        </S.InputWrapper>
    )
}