import React, { useState } from "react";
import * as C from "../../constants";
import { Input } from "../Inputs";
import { Button } from "../Buttons";
import { validateEmail } from "../../utils";
import { EmailAndPassword } from "../../types/App";
import { ErrorDisplay, SuccessDisplay } from "../Displays";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import * as S from "./Styles";
import { InlineRouteLink } from "../Links";

type SignInFormProps = { 
    next?: () => void,
    width?: string,
    maxWidth?: string,
    justifyButton?: string,
}
export const SignInForm = ({
    next,
    width,
    maxWidth,
    justifyButton,
}: SignInFormProps) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<Error | null>(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const isInvalid = !validateEmail(email) || password.length < 6;

    const signIn = async ({ email, password }: EmailAndPassword) => {
        try {
            setError(null);
            setSuccess(false);
            setLoading(true);
            await signInWithEmailAndPassword(auth, email, password);
            setLoading(false);
            setSuccess(true);
            if (next) {
                next();
            }
        } catch (error) {
            setLoading(false);
            if (error instanceof Error){
                setError(error);
            } else {
                setError(new Error("Unhandled error"));
            }
        }
    }

    return (
        <S.Form onSubmit={(e) => { e.preventDefault(); signIn({ email, password })}} width={width} maxWidth={maxWidth}>
            <Input id="email-login"
                name="email"
                type="email"
                placeholder="E-Mail"
                label="E-Mail"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setError(null); setEmail(e.target.value) }}
                secondaryColor={C.COLORS.DIDACTO_DARK_BLUE}
            />
            <Input id="password"
                name="password"
                type="password"
                placeholder="Passwort"
                label="Passwort"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setError(null); setPassword(e.target.value) }}
                secondaryColor={C.COLORS.DIDACTO_DARK_BLUE}
            />
            {error ? <ErrorDisplay error={error} padding="0.5rem" /> : null}
            {success ? <SuccessDisplay message="Anmeldung erfolgreich" padding="0.5rem" /> : null }
            <Button id="sigin-in-button"
                type="submit"
                variant="blue"
                text="Anmelden"
                disabled={isInvalid || loading}
                marginTop="1.5rem"
                width="100%"
                justifyContent={justifyButton}  />
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <InlineRouteLink to={C.PUBLIC_ROUTES.PASSWORD_FORGET}
                    variant="blue"
                    margin="3rem 0 0 0"
                    fontSize="1.7rem">
                    Passwort vergessen?
                </InlineRouteLink>
            </div>
        </S.Form>
    )
}
