import React from 'react';
import AddItemDialog from "./AddItemDialog";
import FolderIcon from '@mui/icons-material/Folder';
import {TextField, MenuItem} from "@mui/material";

const MoveWorkPlanDialog = ({workPlan, path, paths, pathString, handleCancel, handleConfirm}) => {
  const currentPath = (!pathString || pathString === '')? "/" : pathString
  const [targetPath, setTargetPath] = React.useState(currentPath);
  const keys = Object.keys(paths).sort()
  return(
    <AddItemDialog open={true}
                   handleCancel={handleCancel}
                   handleConfirm={() => handleConfirm(targetPath)}
                   title={"Arbeitsplan Verschieben"}
                   description={"Wähle den Zielpfad, an den der Arbeitsplan verschoben werden soll."}
                   confirmText={"Verschieben"}
                   confirmAllowed={targetPath !== currentPath}>
      <TextField
        margin="dense"
        id="targetPath"
        label="Zielpfad"
        color="secondary"
        select
        style={{width: "100%"}}
        value={targetPath}
        onChange={(e) => setTargetPath(e.target.value)}
        onKeyPress={(e) => {if (e.code === 'Enter' && targetPath !== currentPath) handleConfirm(targetPath)}}
        >
        {keys.map(k => <MenuItem key={k} value={k}><FolderIcon style={{marginBottom: "-2px", marginRight: '10px'}} />{k}</MenuItem>)}
      </TextField>
    </AddItemDialog>
  );
}

export default MoveWorkPlanDialog;
