import { styled } from '@mui/material/styles';
import { getVariantColors, VariantColorMap } from "../../utils";

type IconButtonProps = {
    icon: string,
    iconHover: string,
    backgroundColor: string,
    fontColor: string,
    fontColorHover: string,
    marginTop: string,
    width?: string,
    maxWidth?: string,
}

export const IconLink = styled('a')<Partial<VariantColorMap & IconButtonProps>>(({ theme, icon, iconHover, backgroundColor, fontColor, fontColorHover, marginTop, width, maxWidth }) => ({
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginTop: marginTop,
    padding: '0.5rem 5rem 0.5rem 2rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 0%',
    backgroundColor: backgroundColor,
    backgroundImage: `url("${icon}")`,
    fontFamily: 'TisaSansPro-Medium',
    color: `${fontColor} !important`,
    fontSize: '1.7rem',
    borderRadius: '1.8rem 1.8rem 1.8rem 0',
    '&:hover': {
        color: `${fontColorHover} !important`,
        backgroundImage: `url("${iconHover}")`,
    },
    '&:active': {
        boxShadow: 'inset 1px 1px 3px 0 #b58b8b',
    },
    width: width ?? 'initial',
    maxWidth: maxWidth ?? 'initial',
}));

export const IconButton = styled('button')<IconButtonProps>(({ theme, icon, iconHover, backgroundColor, fontColor, fontColorHover, marginTop, width, maxWidth }) => ({
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginTop: marginTop,
    display: 'block',
    padding: '0.5rem 5rem 0.5rem 2rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 0%',
    backgroundColor: backgroundColor,
    backgroundImage: `url("${icon}")`,
    fontFamily: 'TisaSansPro-Medium',
    color: `${fontColor} !important`,
    fontSize: '1.7rem',
    borderRadius: '1.8rem 1.8rem 1.8rem 0',
    '&:hover': {
        color: `${fontColorHover} !important`,
        backgroundImage: `url("${iconHover}")`,
    },
    '&:active': {
        boxShadow: 'inset 1px 1px 3px 0 #b58b8b',
    },
    width: width ?? 'initial',
    maxWidth: maxWidth ?? 'initial',
}));

type ButtonProps = {
    variant: 'red' | 'blue' | 'white';
}

export const Button = styled('button')<ButtonProps>(({ theme, variant }) => {
    const variantColors = getVariantColors(variant, "button");

    return {
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        padding: '0.4rem 2rem',
        fontFamily: 'TisaSansPro-Bold',
        fontSize: '1.8rem',
        borderRadius: '24px 24px 24px 0',
        backgroundColor: variantColors.backgroundcolor,
        color: variantColors.color,
        '&:enabled:active': {
            backgroundColor: variantColors.activebackgroundcolor,
            boxShadow: `inset 1px 1px 3px ${variantColors.shadowcolor}`,
        },
        '&:disabled': {
            backgroundColor: variantColors.disabledbackgroundcolor,
        },
        '&:enabled:hover': {
            color: variantColors.hovercolor,
            backgroundColor: variantColors.hoverbackgroundcolor,
        },
    };
});

type ButtonWrapperProps = {
    width?: string,
    justifyContent?: string,
    marginTop?: string,
}

export const ButtonWrapper = styled('div')<ButtonWrapperProps>(({ theme, width, justifyContent, marginTop }) => ({
    display: 'flex',
    marginTop: marginTop ?? "0",
    width: width ?? "fit-content",
    justifyContent: justifyContent ?? "flex-start",
}));