import { useDispatch, useSelector } from "react-redux";
import { WhiteSection, SectionHeader } from '../../components/Sections';
import { Stepper, Step } from '../../components/Stepper';
import { LoginForm, AddressForm, ConfirmationForm } from "../../components/Forms";
import { COLORS, PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../constants';
import { ShoppingCartTable } from "../../components/Tables/ShoppingCartTable";
import { RootState } from "../../index";
import { useNavigate } from "react-router-dom";
import { removeProduct } from "../ShopPage/shopSlice";
import { IProduct } from "../../domains/Product";
import headerImage from "../../assets/mountains_blueish.jpg";
import { useAppState } from "../../hooks";
import { useState } from "react";
import { UserProfile } from "./userProfileSlice";

export const CheckoutPage = () => {
    const steps: Step[] = [];
    // Step 1: Shopping Cart Table
    const dispatch = useDispatch();
    const productsInCart = useSelector<RootState, IProduct[]>(state => state.shop.items);
    const navigate = useNavigate();

    steps.push({
        title: "Warenkorb",
        prevText: "zum Shop",
        content: () => <ShoppingCartTable products={productsInCart} removeProduct={(i) => dispatch(removeProduct(i))} />,
        disableNext: productsInCart.length === 0,
        prevOverride: () => navigate(PUBLIC_ROUTES.SHOP),
    });

    // Step 2: Login
    const { isAuthenticated } = useAppState();
    const [isInitiallyAuthenticated] = useState(isAuthenticated);
    if (!isInitiallyAuthenticated){
        steps.push({
            title: "Login",
            content: (next) => <LoginForm next={next}/>,
            hideNext: true,
        })    
    }

    // Step 3: Address
    const {firstName, lastName, street, postalCode, city } = useSelector<RootState, UserProfile>(state => state.userProfile);
    const addressIsValid = firstName.length > 0 && lastName.length > 0 && street.length > 0 && postalCode.length > 0 && city.length > 0;
    steps.push({
        title: "Adresse",
        content: () => <AddressForm />,
        disableNext: !addressIsValid,
    });

    // Step 4 - Confirmation:
    steps.push({
        title: "Bestätigung",
        content: () => <ConfirmationForm />,
        nextOverride: () => {navigate(PRIVATE_ROUTES.HOME)},
        nextText:"zu den Downloads",
        disableNext: productsInCart.length > 0,
    })

    return (
        <>
            <SectionHeader id="checkout-header" imgSrc={headerImage} />
            <WhiteSection id="checkout">
                <Stepper color={COLORS.DIDACTO_BLUE}
                    colorDark={COLORS.DIDACTO_DARK_BLUE}
                    colorLight={COLORS.DIDACTO_GREY}
                    steps={steps} />
            </WhiteSection>
        </>
    )
};