import { FunctionComponent } from 'react';
import './ProductCard.css';

type ProductCardProps = {
    mainSrc: string
    detailSrc: string
    title: string
    text: string 
    button?: JSX.Element 
}

export const ProductCard: FunctionComponent<ProductCardProps> = ({mainSrc, detailSrc, title, text, button}) => {
    return (
        <div className="product-card__column-box">
            <div className="product-card__image-box">
                <img className="product-card__image-main" alt="main" src={mainSrc}/>
                <img className="product-card__image-detail" alt="detail" src={detailSrc}/>
            </div>
            <div className="product-card__title">
                {title}
            </div>
            <div className="product-card__text">
                {text}
            </div>
            { button }
        </div>
    )
}



