import { db } from "../../firebase";
import { addDoc, collection, doc, getDoc, updateDoc, writeBatch } from "firebase/firestore";

const COLLECTIONS = {
    AREA_TEMPLATES: 'areaTemplates',
    GRADES: 'grades',
    AREAS: 'areas',
    USERS: 'users',
    SCHOOL_CLASSES: 'schoolClasses',
    SUBJECTS: 'subjects',
    EXAMINED_AREAS: 'examinedAreas',
    EXAMS: 'exams',
    STUDENTS: 'students',
    TOPICS: 'topics',
    WORK_PLANS: 'workPlans',

}

class Firebase {

    /* Didacto Firestore API */
    _getUserRef({ userId }: {
        userId: string,
    }) {
        return doc(db, COLLECTIONS.USERS + "/" + userId);;
    }

    _getTopicsRef = ({ grade }: {
        grade: 3 | 4 | 5 | 6
    }) => {
        return doc(db, COLLECTIONS.TOPICS + "/" + grade);
    }

    _getWorkplanRef({userId, workPlanId}: {userId: string, workPlanId: string}) {
      return doc(collection(this._getUserRef({ userId }), COLLECTIONS.WORK_PLANS), workPlanId);
    }

    async fetchWorkplan({userId, workPlanId}: {userId: string, workPlanId: string}){
      const workplanDoc = await getDoc(this._getWorkplanRef({userId, workPlanId}));
      if (!workplanDoc.exists){
        throw new Error(`Workplan ${workPlanId} does not exist for user ${userId}.`);
      } else {
        return workplanDoc.data();
      }
    }

    async fetchTopics({grade}: {grade: 3 | 4 | 5 | 6}){
      const topicsDoc = await getDoc(this._getTopicsRef({grade}));
      if (!topicsDoc.exists){
        throw new Error(`No topics exist for grade ${grade}.`);
      } else {
        return topicsDoc.data();
      }
    }

    async renameWorkPlan({
        userId,
        pathString,
        workPlanId,
        name
    }: {
        userId: string,
        pathString: string,
        workPlanId: string,
        name: string,
    }) {
      const userRef = this._getUserRef({ userId });
      const userDoc = await getDoc(userRef);
      const workPlanRef = this._getWorkplanRef({ userId, workPlanId });

      let wpDirectories = userDoc.data()!.wpDirectories as {[key: string]: {id: string}[]};
      let workPlans = wpDirectories[pathString].map(currentPlan => (currentPlan.id === workPlanId) ? { ...currentPlan, name } : currentPlan);
      wpDirectories[pathString] = workPlans;
      const batch = writeBatch(db);
      batch.update(userRef, { wpDirectories });
      batch.update(workPlanRef, { name });
      return batch.commit();
    }

    async renameWorkPlanFolder({
        userId,
        pathString,
        folderId,
        prevName,
        newName
    }: {
        userId: string,
        pathString: string,
        folderId: string,
        prevName: string,
        newName: string
    }) {
        const userRef = this._getUserRef({ userId });
        const userDoc = await getDoc(userRef);
        let prevWpDirectories = userDoc.data()!.wpDirectories as {[key: string]: {isDirectory: true, name: string, id:string}[]};

        // Change name in parent-array
        let workPlans = prevWpDirectories[pathString].map(currentPlan => (currentPlan.id === folderId) ? { ...currentPlan, name: newName } : currentPlan);
        prevWpDirectories[pathString] = workPlans;

        // Rename all common prefix paths
        pathString += pathString === '/'? '' : '/';
        const prevPrefixPath = pathString + prevName;
        const newPrefixPath = pathString + newName;
        let wpDirectories: {[key: string]: {isDirectory: true, name: string, id:string}[]} = {};
        for (const key in prevWpDirectories) {
            let newKey = key === prevPrefixPath || key.startsWith(prevPrefixPath + '/') ? key.replace(prevPrefixPath, newPrefixPath) : key;
            wpDirectories[newKey] = prevWpDirectories[key];
        }


        // Update
        await updateDoc(userRef, { wpDirectories });
    }

    async updateWorkPlan({
        userId,
        pathString,
        workPlan,
        workPlanId
    }: {
        userId: string,
        pathString: string,
        workPlan: any,
        workPlanId: string,
    }) {
        const userRef = this._getUserRef({ userId });
        const userDoc = await getDoc(userRef);
        const workPlanRef = this._getWorkplanRef({ userId, workPlanId });

        let wpDirectories = userDoc.data()!.wpDirectories as {[key: string]: {id: string}[]};
        wpDirectories[pathString] = wpDirectories[pathString].map(currentPlan => (currentPlan.id === workPlanId) ? { ...currentPlan, grade: workPlan.grade, name: workPlan.name } : currentPlan);
        const batch = writeBatch(db);
        batch.update(userRef, { wpDirectories });
        batch.update(workPlanRef, workPlan);
        return batch.commit();
    }

    async moveWorkPlan({
        userId,
        pathString,
        newPathString,
        workPlanId
    }: {
        userId: string,
        pathString: string,
        newPathString: string,
        workPlanId: string,
    }) {
        const userRef = this._getUserRef({ userId });
        const userDoc = await getDoc(userRef);

        let wpDirectories = userDoc.data()!.wpDirectories as {[key: string]: {grade: 3 | 4 | 5 | 6, name: string, id: string}[]};
        let prevWorkPlans = [];
        let newWorkPlans = wpDirectories[newPathString];

        // Remove workPlan from previous array and append it to the new one.
        for (let wp of Object.values(wpDirectories[pathString])) {
          if (wp.id === workPlanId) {
            newWorkPlans.push(wp);
          } else {
            prevWorkPlans.push(wp);
          }
        }
        wpDirectories[pathString] = prevWorkPlans;
        wpDirectories[newPathString] = newWorkPlans;

        // Write / Update
        await updateDoc(userRef, { wpDirectories });
    }

    async moveWorkPlanFolder({
        userId,
        pathString,
        newPathString,
        folderId
    }: {
        userId: string,
        pathString: string,
        newPathString: string,
        folderId: string
    }) {
        const userRef = this._getUserRef({ userId });
        const userDoc = await getDoc(userRef);
        const prevWpDirectories = userDoc.data()!.wpDirectories as {[key: string]: {isDirectory: true, name: string, id:string}[]};

        // Delete from previous parent folder
        const prevWorkPlans: {isDirectory: true, name: string, id:string}[] = [];
        let docToMove: {isDirectory: true, name: string, id:string} | undefined = undefined;
        for (const f of prevWpDirectories[pathString]) {
          if (f.id === folderId){
            docToMove = f;
          } else {
            prevWorkPlans.push(f);
          }
        }
        prevWpDirectories[pathString] = prevWorkPlans;

        // Rename all common prefix paths
        pathString += pathString === '/'? '' : '/';
        const prevPrefixPath = pathString + docToMove!.name;
        const newPrefixPath = newPathString + (newPathString === '/'? '' : '/') + docToMove!.name;
        let wpDirectories: {[key: string]: {isDirectory: true, name: string, id:string}[]} = {};
        for (const key of Object.keys(prevWpDirectories)) {
            let newKey = key === prevPrefixPath || key.startsWith(prevPrefixPath + '/') ? key.replace(prevPrefixPath, newPrefixPath) : key;
            wpDirectories[newKey] = prevWpDirectories[key];
        }

        // Add folder-doc to new parent-array
        wpDirectories[newPathString].push(docToMove!);

        // Update
        await updateDoc(userRef, { wpDirectories });
    }

    async insertWorkPlan({
        userId,
        pathString,
        workPlan,
        index
    }: {
        userId: string,
        pathString: string,
        workPlan: any,
        index: number,
    }) {
        const userRef = this._getUserRef({ userId });
        const userDoc = await getDoc(userRef);
        const wpRef = await addDoc(collection(userRef, COLLECTIONS.WORK_PLANS), workPlan);
        let wpDirectories = userDoc.data()!.wpDirectories ?? {} as {[key: string]: {grade: 3 | 4 | 5 | 6, name: string, id: string}[]};
        let workPlans = wpDirectories[pathString];
        if (workPlans) {
            workPlans.splice(index ?? workPlans.length, 0, { grade: workPlan.grade, name: workPlan.name, id: wpRef.id });
        } else {
            workPlans = [{ grade: workPlan.grade, name: workPlan.name, id: wpRef.id }];
        }
        wpDirectories[pathString] = workPlans;
        updateDoc(userRef, { wpDirectories });
    }

    async insertFolder({
        userId,
        pathString,
        name,
        index
    }: {
        userId: string,
        pathString: string,
        name: string,
        index: number,
    }) {
        const userRef = this._getUserRef({ userId });
        const userDoc = await getDoc(userRef);
        let wpDirectories = userDoc.data()!.wpDirectories ?? {} as {[key: string]: {isDirectory: true, name: string, id: string}[]};
        let parentWorkPlans = wpDirectories[pathString];

        // Add folder-name to parent-array
        if (parentWorkPlans) {
            parentWorkPlans.splice(index ?? parentWorkPlans.length, 0, { name, id: new Date().getTime().toString(), isDirectory: true });
        } else {
            parentWorkPlans = [{ name, isDirectory: true, id: new Date().getTime().toString() }];
        }
        wpDirectories[pathString] = parentWorkPlans;

        // Add new path to wpDirectories
        pathString += (pathString === '/'? '' : '/') + name;
        if (wpDirectories) {
            wpDirectories[pathString] = [];
        } else {
            wpDirectories = {[pathString]: []};
        }
        await updateDoc(userRef, { wpDirectories });
    }

    async deleteWorkPlan({
        userId,
        pathString,
        workPlanId
    }: {
        userId: string,
        pathString: string,
        workPlanId: string,
    }) {
      const userRef = this._getUserRef({ userId });
      const userDoc = await getDoc(userRef);
      const workPlanRef = this._getWorkplanRef({ userId, workPlanId });

      let wpDirectories = userDoc.data()!.wpDirectories as {[key: string]: {id: string}[]};
      wpDirectories[pathString] = wpDirectories[pathString].filter(plan => plan.id !== workPlanId);

      const batch = writeBatch(db);
      batch.update(userRef, { wpDirectories });
      batch.delete(workPlanRef);
      return await batch.commit();
    }

    async deleteWorkPlanFolder({
        userId,
        pathString,
        folderId,
        name
    }: {
        userId: string,
        pathString: string,
        folderId: string,
        name: string
    }) {
        const userRef = this._getUserRef({ userId });
        const userDoc = await getDoc(userRef);
        let prevWpDirectories = userDoc.data()!.wpDirectories as {[key: string]: {isDirectory: true, name: string, id: string}[]};
        let totalPath = pathString + (pathString === '/'? '' : '/') + name;
        // Delete from parent id
        prevWpDirectories[pathString] = prevWpDirectories[pathString].filter(f => f.id !== folderId);
        
        // Delete routes
        let wpDirectories: { [key: string]: any } = {};
        for (const key in prevWpDirectories) {
            if (!(key === totalPath || key.startsWith(totalPath + '/'))) {
                wpDirectories[key] = prevWpDirectories[key];
            }
        }

        // Write / Update
        const triggerCleanupOrphanedWorkPlans = (prevWpDirectories[totalPath] && prevWpDirectories[totalPath].length > 0);
        await updateDoc(userRef, { wpDirectories, triggerCleanupOrphanedWorkPlans});
    }
}
export default new Firebase();
