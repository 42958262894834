import { useIsMobile } from '../../hooks';
import './PersonCard.css';

const SPACE = '\u00A0';
const NDASH = '\u2013';

interface PersonCardProps {
    avatarSrc: string
    fullName: string
    jobTitle: string
    text: string
    id: string
}

export const PersonCard = ({avatarSrc, fullName, jobTitle, text, id}: PersonCardProps):JSX.Element => {
    const isMobile = useIsMobile();
    return (
        <div id={id} className='person-card__box'>
           <div className='person-card__image-box'>
                <img className='person-card__image-main' alt='avatar' src={avatarSrc} />
            </div> 
            <div className='person-card__details-box'>
                <div className='person-card__person-title'>
                    <span className='person-card__person-name'>{`${fullName}${SPACE}`}</span>
                    <span className='person-card__person-job'>{isMobile ? jobTitle : NDASH + SPACE + jobTitle}</span>
                </div>
                <div className='person-card__person-text'>
                    {text}
                </div>
            </div>
        </div>
    )
}