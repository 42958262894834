import { DidactoColor, WithChildren } from '../../types/App';
import * as S from './Styles';
import './Section.css';
import { COLORS } from '../../constants';

/**
 * Section - Full width box with horizontally centered content
 * 
 * container: a full width (100vw) container
 * content: horizontally centered  with 840px max width
 */

 type SectionProps = WithChildren<{
    id: string,
    alignItems?: string,
    marginBottomContent?: string,
    paddingTop?: string,
    backgroundColor?: DidactoColor,
    width?: string,
}>;

 export const Section = ({
     id, 
     alignItems,
     marginBottomContent,
     paddingTop="0",
     backgroundColor=COLORS.DIDACTO_WHITE,
     width,
     children
}: SectionProps) => {
    return (
        <S.Section id={id} alignItems={alignItems} paddingTop={paddingTop} backgroundColor={backgroundColor}>
            <S.SectionContentBox marginBottom={marginBottomContent} width={width}>
                {children}
            </S.SectionContentBox>
        </S.Section>
    )
}

type ParallaxSectionProps = WithChildren<{
    id: string,
    backgroundSize?: string,
    minHeight?: string, 
    imgSrc: string,
    paddingTop?: string
}>;

export const ParallaxSection = ({id, backgroundSize, minHeight, children, imgSrc, paddingTop}: ParallaxSectionProps) => {
    return (
        <S.ParallaxSection id={id} 
                            backgroundSize={backgroundSize} 
                            minHeight={minHeight} 
                            backgroundImage={imgSrc}
                            paddingTop={paddingTop}
                            >
            <S.SectionContentBox justifyContent={"space-between"}>
                {children}
            </S.SectionContentBox>
        </S.ParallaxSection>
    )

}

export const RedSection = (props: SectionProps) => {
    return <Section {...props} backgroundColor={COLORS.DIDACTO_RED}/>
}

export const GreySection = (props: SectionProps) => {
    return <Section {...props}  backgroundColor={COLORS.DIDACTO_GREY}/>
}

export const WhiteSection = (props: SectionProps) => {
    return <Section {...props} backgroundColor={COLORS.DIDACTO_WHITE}/>
}

export type SectionHeaderProps = {
    maxHeight?: string,
    imgSrc: string,
    id: string,
}

/**
 * Header of section only displaying image that is visible behind navbar without content.
 * 
 * Used on HomePage, ShopPage, CartPage
 */
export const SectionHeader = ({
    id,
    maxHeight="22rem",
    imgSrc,
}: SectionHeaderProps) => {
    return <S.ImageHeader src={imgSrc} maxHeight={maxHeight}/>
};

type SectionParagraphProps = WithChildren<{
    padding?: string,
    fontSize?: string,
    textAlign?: string,
}>;

export const SectionParagraph = ({
    padding,
    fontSize,
    textAlign,
    children,
}: SectionParagraphProps) => {
    const paragraphProps = {padding, fontSize, textAlign};
    return (
        <S.SectionParagraph {...{paragraphProps}}>
            {children}
        </S.SectionParagraph>
    )
}