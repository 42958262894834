import { useSelector } from 'react-redux';
import { AppState } from '../components/App';
import { RootState } from '../index';

export const useAppState = () => {
    const {user, error} = useSelector<RootState, AppState>(state => state.app);
    return {
        user,
        isAuthenticated: user ? true : false,
        error,
    }
}