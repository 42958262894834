import { COLORS } from "../../constants";
import { IconProps } from "./index";


export const Forward = ({ width = "15px", height = "100%", color = COLORS.DIDACTO_BLACK }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 10 10" width={width} fill={color}>
            <g id="button-blue-arrow-shop" fill={color} stroke="none" strokeWidth="1" fillRule="evenodd">
                <path d="M9.71042471,10 C9.88416988,8.93309222 10,6.9801085 10,5.08137432 C10,3.09222423 9.86486486,1.12115732 9.71042471,3.55271368e-15 C7.18146718,0.994575045 2.2007722,3.52622061 3.46389584e-14,4.97287523 C2.22007722,6.43761302 7.14285714,9.02350814 9.71042471,10 M7.93436293,7.12477396 C6.75675676,6.70886076 4.53667954,5.58770344 3.53281853,4.93670886 C4.51737452,4.30379747 6.7953668,3.20072333 7.93436293,2.76672694 C8.01158301,3.23688969 8.03088803,4.06871609 8.03088803,4.81012658 C8.03088803,5.76853526 7.99227799,6.54611212 7.93436293,7.12477396" id="Fill-1" fill={color} transform="translate(5.000000, 5.000000) rotate(-180.000000) translate(-5.000000, -5.000000) "></path>
            </g>
        </svg>
    );
};