import "./HomePage.css";
import { getAuth, sendEmailVerification, User } from "firebase/auth";
import { Link } from 'react-router-dom';
import { HyperLink } from '../../components/Links';
import { Section, SectionHeader, SectionTitle, SectionParagraph, SubSection } from '../../components/Sections';
import { Button } from "../../components/Buttons";
import mountainsBlueish from '../../assets/mountains_blueish.jpg';
import { COLORS } from '../../constants';
import { DownloadSection } from "../../components/DownloadSection";
import { WithChildren } from '../../types/App';
import { useSelector } from 'react-redux';
import { RootState } from '../..';
import { UserProfileState } from '../CheckoutPage/userProfileSlice';
import { useState } from 'react';
import { ErrorDisplay, SuccessDisplay } from '../../components/Displays';
import { PDF_PACKAGES } from "../../domains/Product/Product";


export const HomePage = () => {
    const user = getAuth().currentUser;
    //TOOD: isVerifiedTeacher === false => either it is false or it is not loaded yet => use false/null 
    const { 
        isVerifiedTeacher, 
        success, 
        loading, 
        error, 
        wpAccess 
    } = useSelector<RootState, UserProfileState>(state => state.userProfile);
    let packageDownloads = [];
    if (user && !user.emailVerified) {
        packageDownloads.push(<EmailNotVerfiedExplainer key="email-not-verified">
            <ResendEmailVerification />
        </EmailNotVerfiedExplainer>)
    }

    const userProfileIsValid = !loading && success && !error;
    if (user && user.emailVerified && userProfileIsValid && !isVerifiedTeacher) {
        packageDownloads.push(<TeacherVerificationPending key="teacher-verification-pending" />)
    }

    if (user && user.emailVerified && userProfileIsValid && isVerifiedTeacher) {
        packageDownloads.push(<DownloadSection key="download-section" fileDescriptions={PDF_PACKAGES} />)
    }

    const hasWpAccess = Object.keys(wpAccess).length > 0;
    let wpAccessInfo = null;
    if (user && user.emailVerified && userProfileIsValid && isVerifiedTeacher && hasWpAccess){
        wpAccessInfo = 
        <div className="license-section">
            <h4>Arbeitsplan-Generator-Lizenzen</h4>
            <ul className="license-list">
            {Object.entries(wpAccess).map(([key, {validFrom, validTo}]) => {
                const validFromDateString = new Intl.DateTimeFormat('de-DE').format(new Date(validFrom.seconds * 1000));
                const validToDateString = new Intl.DateTimeFormat('de-DE').format(new Date(validTo.seconds * 1000));
                const today = new Date();
                const expirationThresholdDate = new Date(validTo.seconds * 1000);
                expirationThresholdDate.setDate(expirationThresholdDate.getDate() - 14);
                let licenseOutput = <li>
                        <span className="license-label">Lizenz {key}. Klasse: </span>
                        <span>Gültig vom {validFromDateString} bis zum {validToDateString}</span>
                    </li>
                if (new Date(validFrom.seconds * 1000) > today) { // Preorder
                    licenseOutput = <li>
                        <span style={{color: "grey"}} className="license-label">Lizenz {key}. Klasse: </span>
                        <span style={{color: "grey"}}>Dein Zugriff wird am {validFromDateString} aufgeschaltet</span>
                    </li>
                }
                else if (new Date(validTo.seconds * 1000) < today) { // Expired
                    licenseOutput = <li>
                        <span style={{color: "red"}} className="license-label">Lizenz {key}. Klasse: </span>
                        <span style={{color: "red"}}>Deine Lizenz ist seit dem {validToDateString} abgelaufen und kann im <Link style={{color:"red", textDecoration:"underline"}} to='/shop'>Shop</Link> erneuert werden</span>
                    </li>
                }
                else if (expirationThresholdDate < today) { // Expiring
                    licenseOutput = <li>
                        <span style={{color: "orange"}} className="license-label">Lizenz {key}. Klasse: </span>
                        <span style={{color: "orange"}}>Gültig vom {validFromDateString} bis zum {validToDateString}</span>
                    </li>
                }
                return licenseOutput;
            })}
            </ul>


        </div>
    }
    return (
        <>
            <SectionHeader id="homepage-header" imgSrc={mountainsBlueish} />
            <Section id="homepage">
                <SectionTitle>Meine didacto-Produkte</SectionTitle>
                {packageDownloads}
                {wpAccessInfo}
            </Section>
        </>
    )
};

const EmailNotVerfiedExplainer = ({
    children
}: WithChildren) => {
    const user = getAuth().currentUser;
    return (
        <>
            <SubSection>
                <SectionParagraph>Dein Account bei didacto wurde erstellt. Deine E-Mail-Adresse <strong style={{ color: COLORS.DIDACTO_BLUE }}>{user?.email}</strong> konnte jedoch noch nicht verifiziert werden.</SectionParagraph>

            </SubSection>
            <SubSection>
                <SectionParagraph>Falls du von uns kein E-mail mit einem Verifikations-Link erhalten hast, kannst du dir dieses hier nochmals zusenden lassen:</SectionParagraph>
                { children }
            </SubSection>
        </>
    )
}

const resendEmailVerification = async (
    user: User | null,
    setLoading: (loading: boolean) => void,
    setSuccess: (success: boolean) => void,
    setError: (error: Error) => void,
    url = process.env.REACT_APP_CONFIRM_EMAIL_ROUTE ?? "https://wwww.didacto.ch/home",
) => {
    try {
        setLoading(true);
        if (!user) {
            throw new Error("User ist nicht eingeloggt.")
        }
        await sendEmailVerification(user, {
            url,
        });
        setLoading(false);
        setSuccess(true);
    } catch (error) {
        if (error instanceof Error) {
            setError(error);
        } else {
            setError(new Error("Unhandled error"));
        }
    }
}

export const ResendEmailVerification = ({
    buttonText="E-Mail Adresse verifizieren"
}: {
    buttonText?: string,
}) => {
    const user = getAuth().currentUser;
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    return (
        <>
            <Button id="resend-verficaiton-email"
                onClick={() => resendEmailVerification(
                    user,
                    setLoading,
                    setSuccess,
                    setError,
                )}
                variant="blue"
                justifyContent="center"
                text={buttonText}
                width="100%"
                disabled={loading} />
            {error ? <ErrorDisplay margin="2rem 0" error={error} /> : null}
            {success ? <SuccessDisplay margin="2rem 0" message={`E-Mail an ${user?.email} versendet.`} /> : null}
        </>
    )
}

const TeacherVerificationPending = () => {
    const user = getAuth().currentUser;
    if (!user) {
        return null;
    }
    return (
        <SubSection>
            <SectionParagraph>Dein Account (<span style={{ color: COLORS.DIDACTO_BLUE }}>{user.email}{ }</span>) bei didacto ist nun erstellt.</SectionParagraph>
            <SectionParagraph>Bitte habe noch einen Moment Geduld, bis wir anhand deiner Angaben überprüft haben, dass du auch tatsächlich eine Lehrperson bist. Wir informieren dich, sobald dies geschehen ist und du deine gekauften Produkte hier downloaden kannst (maximal zwei Arbeitstage). </SectionParagraph>
            <SectionParagraph>Die Rechnung erhältst du in einem separaten Mail.</SectionParagraph>
            <SectionParagraph>Bei Problemen oder Fragen wende dich an <HyperLink href="mailto:info@didacto.ch" variant="blue" >info@didacto.ch</HyperLink>.</SectionParagraph>
        </SubSection>
    )
}