import React, { useState } from "react";
import * as S from "./Styles";

import { COLLECTIONS, COLORS } from "../../constants";
import { EmailAndPassword } from "../../types/App";
import { validateEmail } from "../../utils";
import { Input } from "../Inputs";
import { Button } from "../Buttons";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; 
import { auth, db } from "../../firebase";
import { ErrorDisplay, SuccessDisplay } from "../Displays";
import { USE_SCHOOL_EMAIL_INFO } from "../../texts";

type SignUpFormProps = {
    next?: () => void,
    width?: string,
    maxWidth?: string,
}
export const SignUpForm = ({
    next,
    width,
    maxWidth,
}: SignUpFormProps) => {
    const [email, setEmail] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [error, setError] = useState<Error|null>(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const isInvalid = !validateEmail(email) || (password1 !== password2) || (password1.length < 6);

    const signUp = async ({ email, password }: EmailAndPassword) => {
        try {
            //TODO: use a transaction for createUser, sendEmail and setDoc??
            setError(null);
            setSuccess(false);
            setLoading(true);
            const { user } = await createUserWithEmailAndPassword(auth, email, password);
            const url = process.env.REACT_APP_CONFIRM_EMAIL_ROUTE ?? "https://www.didacto.ch/home";
            await sendEmailVerification(user, {
                url
            });
            const userProfileDocRef = doc(db, COLLECTIONS.users, user.uid);
            await setDoc(userProfileDocRef, {uid: user.uid, email: user.email, emailVerified: user.emailVerified});
            setLoading(false);
            setSuccess(true);
            if (next){
                next();
            }
        } catch (error) {
            setLoading(false);
            if (error instanceof Error){
                setError(error);
            } else {
                setError(new Error("Unhandled error"));
            }
        }
    }

    return (
        <S.Form onSubmit={e => {e.preventDefault();signUp({email, password: password1})}} width={width} maxWidth={maxWidth}>
            <Input id="email"
                name="email"
                type="email"
                placeholder="E-Mail"
                label="E-Mail"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setError(null); setEmail(e.target.value)}}
                secondaryColor={COLORS.DIDACTO_DARK_BLUE}
                inputInfo={USE_SCHOOL_EMAIL_INFO}
            />
            <Input id="password1"
                name="password1"
                type="password"
                placeholder="Passwort (mindestens 6 Zeichen)"
                label="Passwort"
                value={password1}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setError(null); setPassword1(e.target.value)}}
                secondaryColor={COLORS.DIDACTO_DARK_BLUE}
            />
            <Input id="password2"
                name="password2"
                type="password"
                placeholder="Passwort nochmals eingeben"
                label="Passwort nochmals eingeben"
                value={password2}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setError(null); setPassword2(e.target.value)}}
                secondaryColor={COLORS.DIDACTO_DARK_BLUE}
            />
            {error ? <ErrorDisplay error={error} padding="0.5rem"/> : null}
            {success ? <SuccessDisplay message={`Konto ${email} erfolgreich erstellt.`} padding="0.5rem"/> : null}
            <Button id="sign-up-button" 
                    type="submit" 
                    variant="blue" 
                    text="Konto erstellen" 
                    disabled={isInvalid || loading} 
                    marginTop="1.5rem"
                    justifyContent="flex-end"
                    width="100%"/>
        </S.Form>
    )
}