import { WithChildren } from "../../types/App";
import { getVariantColors } from "../../utils";
import * as S from "./Styles";

type InlineRouteLinkProps = WithChildren<{
    variant: "white" | "blue" | "red",
    to: string,
    fontSize?: string,
    margin?: string,
}>

export const InlineRouteLink = ({variant, children, to, fontSize, margin}: InlineRouteLinkProps) => {
    const colors = getVariantColors(variant, "link");
    return <S.InlineRouteLink to={to} fontSize={fontSize} {...colors} margin={margin}>{children}</S.InlineRouteLink>
}