import { COLORS } from "../constants";
import { DidactoColor } from "../types/App";

export const validateEmail = (email: string) => {
    const re: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
    return re.test(email.toUpperCase());
}

/**
 * Post data using fetch.
 * 
 * @param { string } url 
 * @param {{[key: string]: string}} data 
 * @returns { any } JSON response
 */

 export const postData = async (url: string = '', data: { [key: string]: string }): Promise<any> => {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}


export function convertRemToPixels(rem: number) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}


export const scrollToTop = () => window.scrollTo({top: 0, left: 0, behavior:'smooth'});

/* 
 * Modulo function that works with negative numbers as well.
 */
export const mod = (x: number, b: number): number => {
    return x - Math.floor(x / b) * b;
}

//browser doesn't like camelCase
export type VariantColorMap = {
    backgroundcolor: DidactoColor,
    color: DidactoColor,
    activebackgroundcolor: DidactoColor,
    shadowcolor: DidactoColor,
    disabledbackgroundcolor: DidactoColor,
    hoverbackgroundcolor: DidactoColor,
    hovercolor: DidactoColor,
    linkcolor?: DidactoColor,
    activecolor?: DidactoColor,
    visitedcolor?: DidactoColor
}

export const getVariantColors = (
    variant: 'red' | 'blue' | 'white',
    type: "button" | "link",
): VariantColorMap => {
    const blueButtonMap: VariantColorMap = {
            backgroundcolor: COLORS.DIDACTO_BLUE,
            color: COLORS.DIDACTO_WHITE,
            activebackgroundcolor: COLORS.DIDACTO_DARK_BLUE,
            shadowcolor: COLORS.DIDACTO_BLACK,
            disabledbackgroundcolor: COLORS.DIDACTO_DARK_GREY,
            hoverbackgroundcolor: COLORS.DIDACTO_DARK_BLUE,
            hovercolor: COLORS.DIDACTO_LIGHT_GREY,
    }
    const blueLinkMap = {
        ...blueButtonMap,
        color: COLORS.DIDACTO_BLUE,
        hovercolor: COLORS.DIDACTO_DARK_BLUE,
        visitedcolor: COLORS.DIDACTO_BLUE
    }
    const whiteButtonMap: VariantColorMap = {
            backgroundcolor: COLORS.DIDACTO_WHITE,
            color: COLORS.DIDACTO_RED,
            activebackgroundcolor: COLORS.DIDACTO_LIGHT_GREY,
            shadowcolor: COLORS.DIDACTO_DARK_RED,
            disabledbackgroundcolor: COLORS.DIDACTO_DARK_GREY,
            hoverbackgroundcolor: COLORS.DIDACTO_LIGHT_GREY,
            hovercolor: COLORS.DIDACTO_DARK_RED,
    }
    
    const whiteLinkMap = {
        ...whiteButtonMap,
    }
    const redButtonMap: VariantColorMap = {
        backgroundcolor: COLORS.DIDACTO_RED,
        color: COLORS.DIDACTO_WHITE,
        activebackgroundcolor: COLORS.DIDACTO_DARK_RED,
        shadowcolor: COLORS.DIDACTO_DARK_BLUE,
        disabledbackgroundcolor: COLORS.DIDACTO_DARK_GREY,
        hoverbackgroundcolor: COLORS.DIDACTO_DARK_RED,
        hovercolor: COLORS.DIDACTO_LIGHT_GREY,
    }
    const redLinkMap = {
        ...redButtonMap,
    }
    if (type === "button" && variant === "red"){
        return redButtonMap;
    }
    if (type === "button" && variant === "white"){
        return whiteButtonMap;
    }
    if (type === "button" && variant === "blue"){
        return blueButtonMap;   
    }

    if (variant === "red"){
        return redLinkMap;
    }

    if (variant === "white"){
        return whiteLinkMap;
    }
    return blueLinkMap;
}

