import { Link } from 'react-router-dom';
import { COLORS } from '../../constants';
import * as S from './Styles';
import downloadIconRed from './assets/download-icon-red.svg';
import downloadIconHoverRed from './assets/download-icon-dark-red.svg';
import downloadIconBlue from './assets/download-icon-blue.svg';
import downloadIconHoverBlue from './assets/download-icon-dark-blue.svg';
import cartIcon from './assets/cart-icon.svg';
import cartIconHover from './assets/cart-icon-dark.svg';
import { useDownloadURL } from '../../hooks';

export type DownloadButtonProps = {
    path: string,
    text?: string,
    filename?: string,
    marginTop?: string,
    variant?: "red" | "blue",
    maxWidth?: string,
    width?: string,
}

export const DownloadButton = ({
    path,
    text = "Download",
    filename = "",
    marginTop = "0",
    variant = "red",
}: DownloadButtonProps) => {
    let colors = {
        backgroundColor: COLORS.DIDACTO_LIGHT_RED,
        fontColor: COLORS.DIDACTO_RED,
        fontColorHover: COLORS.DIDACTO_DARK_RED,
        icon: downloadIconRed,
        iconHover: downloadIconHoverRed,
    }
    if (variant === "blue"){
        colors = {
            backgroundColor: COLORS.DIDACTO_LIGHT_GREY,
            fontColor: COLORS.DIDACTO_BLUE,
            fontColorHover: COLORS.DIDACTO_DARK_BLUE,
            icon: downloadIconBlue,
            iconHover: downloadIconHoverBlue,
        }
    }
    const downloadUrl = useDownloadURL({path});
    return <S.IconLink 
        href={downloadUrl}
        download={filename}
        marginTop={marginTop}
        width={"fit-content"}
        maxWidth={"100%"}
        {...colors}>
        {text}
    </S.IconLink>;
}

export type AddToCartButtonProps = {
    text: string,
    marginTop?: string,
    onClick: () => void,
}

export const AddToCartButton = ({ text, marginTop = "0", onClick }: AddToCartButtonProps) => {
    return <S.IconButton icon={cartIcon}
        iconHover={cartIconHover}
        backgroundColor={COLORS.DIDACTO_LIGHT_GREY}
        fontColor={COLORS.DIDACTO_BLUE}
        fontColorHover={COLORS.DIDACTO_DARK_BLUE}
        marginTop={marginTop}
        onClick={onClick}>
        {text}
    </S.IconButton>
}

export type ButtonProps = {
    id: string,
    disabled?: boolean,
    variant: 'red' | 'blue' | 'white',
    text: string,
    onClick?: () => void,
    type?: "button" | "reset" | "submit",
    style?: { [key: string]: string | number },
    marginTop?: string,
    justifyContent?: string,
    width?: string,
};

export const Button = ({
    id,
    disabled = false,
    variant,
    text,
    type = "button",
    style,
    onClick = () => {},
    marginTop, 
    justifyContent,
    width,
}: ButtonProps) => {
    return (
        <S.ButtonWrapper marginTop={marginTop} justifyContent={justifyContent} width={width}>
            <S.Button id={id} type={type} variant={variant} disabled={disabled} style={style} onClick={onClick}>{text}</S.Button>
        </S.ButtonWrapper>
    )
}

export const NavButton = ({ id, disabled, variant, text, to, style }: ButtonProps & { to: string }) => {
    const buttonProps = { id, disabled, variant, text };
    return (
        <Link to={to}>
            <Button style={style} {...buttonProps} />
        </Link>
    )
}


