import React from 'react';
import ReactDOM from 'react-dom/client';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import App from './components/App';
import { appSlice } from './components/App';
import { shopSlice } from './pages/ShopPage';
import { userProfileSlice } from './pages/CheckoutPage';

import './index.css';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorDisplay } from './components/Displays';

const appReducer = combineReducers({
  app: appSlice.reducer,
  shop: shopSlice.reducer,
  userProfile: userProfileSlice.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const container = document.getElementById('root');

if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate
          loading={<ErrorDisplay error={new Error('State wird geladen...')} />}
          persistor={persistor}
        >
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
} else {
  console.error('Root element not found');
}