import {
  TextField,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

const WorkPlanEditNavigation = ({ name, onNameChange, grade }) => {
  return (
    <Box id="workPlanEdit-navigation" display="flex" justifyContent="space-between" mt={"30px"} alignItems="center" sx={(theme) => ({
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            "& >div": {
                marginTop: "16px"
            }
        }
    })}>
      <Typography key="title" variant="h6">Arbeitspläne</Typography>
      <Box>
        <TextField size="small" error={name.trim() === ''} required id="workPlanName" key="workPlanName" label="Name" value={name} onChange={onNameChange} style={{ marginRight: "2rem" }} />
        <TextField size="small" id="grade" key="grade" select value={grade} label="Stufe" disabled>
          <MenuItem key={grade} value={grade}>{grade}</MenuItem>
        </TextField>
      </Box>
    </Box>
  )
}
export default WorkPlanEditNavigation;