import { COLORS } from '../../constants';
import { IProduct } from '../../domains/Product';
import { DidactoColor } from '../../types/App';
import { Delete } from '../Icons';
import * as S from './Styles';

export type ShoppinCartTableProps = {
    products: IProduct[],
    removeProduct: (i: number) => void,
};

export const ShoppingCartTable = ({
    products,
    removeProduct,
}: ShoppinCartTableProps) => {
    // generate rows and calculate total price
    const rows: (string | JSX.Element)[][] = []; 
    let totalPrice: number = 0;
    products.forEach((product: IProduct, i) => {
        const trashIcon = <Delete color={COLORS.DIDACTO_DARK_BLUE} height="25px" onClick={() => removeProduct(i)}></Delete>;
        rows.push([product.name, `${product.price}.-`, trashIcon]);
        totalPrice = totalPrice + product.price;
    });
    
    // formatting/constants
    const borderStyle = "none none solid none";
    const borderColor = COLORS.DIDACTO_DARK_BLUE;
    const padding = "0.5rem 0 0.5rem 0";
    const headerRow = ["Produkt", "Preis", ""];
    const lastCol = (i:number) => i === (headerRow.length - 1);
    const nRows = headerRow.length;
    const footerRow: string[] = ["Total", `${totalPrice}.-`, ""];

    if (rows.length === 0){
        return (
            <S.Table nRows={nRows} fontSize="1.6rem">
                <p>Der Warenkorb ist leer.</p>
            </S.Table>
        )
    }

    return (
        <S.Table nRows={nRows} fontSize="1.6rem" gridTemplateColumns={`3fr 1fr minmax(30px, 0.5fr)`}>
            <HeaderRow data={headerRow} {...{borderStyle, borderColor, padding}}/>
            {
                rows.flatMap((row, i) => {
                    return row.map((value, j) => {
                        let cellProps = {borderStyle, borderColor, padding, justifyContent: "initial"};
                        if (lastCol(j)){
                            cellProps.justifyContent = "flex-end";
                            cellProps.padding = "0.5rem 0.5rem 0.5rem 0";
                        }
                        return (
                            <S.Cell key={`${i}-${j}`}{...cellProps}>{value}</S.Cell>
                        )
                    });
                })
            }
            <FooterRow data={footerRow} {...{borderStyle: "none", borderColor, padding}}/> 
        </S.Table> 
    )
};

type RowProps = {
    data: string[];
    borderStyle: string,
    borderColor: DidactoColor,
    padding: string,
}

const HeaderRow = ({data, borderStyle, borderColor, padding}: RowProps) => {
    const cells = data.map((value, i) => {
        return <S.HeaderCell key={i} {...{borderStyle, borderColor, padding}}>{value}</S.HeaderCell>
    });
    return <>{cells}</>
}

const FooterRow = ({data, borderStyle, borderColor, padding}: RowProps) => {
    const cells = data.map((value, i) => {
        return <S.FooterCell key={i} {...{borderStyle, borderColor, padding}}>{value}</S.FooterCell>
    });
    return <>{cells}</>
}