import { TYPES } from './workPlanReducer';
import { onSnapshot } from "firebase/firestore";


    export const subscribeToUserDocPaths = ({ userId, firebaseAPI, dispatch }) => {
        if (userId) {
            dispatch({ type: TYPES.PATHS_LOADING, payload: true });
            return onSnapshot(
                firebaseAPI._getUserRef({ userId }),
                (doc) => {
                    if (!doc.exists) {
                        throw new Error(`User doc does not exists for uid ${userId}.`);
                    }
                    if (doc.data().wpDirectories) {
                        dispatch({ type: TYPES.PATHS_UPDATED, payload: doc.data().wpDirectories });
                    } else {
                        dispatch({ type: TYPES.PATHS_UPDATED, payload: {} });
                    }
                    if (doc.data().wpAccess) {
                        dispatch({ type: TYPES.WP_ACCESS_UPDATED, payload: doc.data().wpAccess });
                    } else {
                        dispatch({ type: TYPES.WP_ACCESS_UPDATED, payload: {} });
                    }
                    dispatch({ type: TYPES.PATHS_LOADING, payload: false });
                }
            );
        }
    }

    export const subscribeToTopicsDoc = ({ grade, firebaseAPI, dispatch }) => {
        if (grade) {
            const unsubscribe = onSnapshot(firebaseAPI._getTopicsRef({ grade }),
                (doc) => {
                    if (!doc.exists) {
                        console.warn(`Topics doc does not exists for grade ${grade}.`);
                        return;
                    }
                    dispatch({ type: TYPES.TOPICS_UPDATED, payload: doc.data() });
                }
            );
            return unsubscribe;
        }
    }

    export const loadWorkplan = ({ userId, path, workPlanId, firebaseAPI, dispatch }) => {
        dispatch({ type: TYPES.WORKPLAN_LOADING, payload: true });
        firebaseAPI.fetchWorkplan({ userId, path, workPlanId })
            .then(workPlan => {
                dispatch({ type: TYPES.WORK_PLAN_LOADED, payload: workPlan });
                dispatch({ type: TYPES.WORKPLAN_LOADING, payload: false });
            })
    }

    export const renameWorkPlan = ({ userId, pathString, workPlanId, name, firebaseAPI }) => {
        firebaseAPI.renameWorkPlan({ userId, pathString, workPlanId, name });
    }

    export const renameWorkPlanFolder = ({ userId, pathString, folderId, prevName, newName, firebaseAPI }) => {
        firebaseAPI.renameWorkPlanFolder({ userId, pathString, folderId, prevName, newName });
    }

    export const updateWorkPlan = ({ userId, pathString, workPlan, workPlanId, firebaseAPI }) => {
        firebaseAPI.updateWorkPlan({ userId, pathString, workPlan, workPlanId });
    }

    export const moveWorkPlan = ({ userId, pathString, newPathString, workPlanId, firebaseAPI }) => {
        firebaseAPI.moveWorkPlan({ userId, pathString, newPathString, workPlanId });
    }

    export const moveWorkPlanFolder = ({ userId, pathString, newPathString, folderId, firebaseAPI }) => {
        firebaseAPI.moveWorkPlanFolder({ userId, pathString, newPathString, folderId });
    }

    export const insertWorkPlan = ({ userId, pathString, workPlan, firebaseAPI, index }) => {
        firebaseAPI.insertWorkPlan({ userId, pathString, workPlan, index });
    }

    export const insertFolder = ({ userId, pathString, name, firebaseAPI, index }) => {
        firebaseAPI.insertFolder({ userId, pathString, name, index });
    }

    export const deleteWorkPlan = ({ userId, pathString, workPlanId, firebaseAPI }) => {
        firebaseAPI.deleteWorkPlan({ userId, pathString, workPlanId });
    }

    export const deleteWorkPlanFolder = ({ userId, pathString, folderId, name, firebaseAPI }) => {
        firebaseAPI.deleteWorkPlanFolder({ userId, pathString, folderId, name });
    }
