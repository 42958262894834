import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { PDFPackage } from "../../domains/Product/Product";
import { Grade } from "../../types/App";

export type UserProfile = {
    uid: string,
    email: string,
    firstName: string,
    lastName: string,
    school: string,
    street: string,
    postalCode: string,
    city: string,
    phone: string,
    isVerifiedTeacher: boolean,
    wpAccess: WpAccess,
    pdfAccess: PDFPackage[],
    orders: number[],
}

type FirestoreTimestamp = {
    seconds: number,
    nanoseconds: number,
}

export type WpAccess = {
        [grade in Grade]?: {
            triggerRefresh: boolean,
            validFrom: FirestoreTimestamp,
            validTo: FirestoreTimestamp,
        }
}
export type Address = {
    school: string,
    firstName: string,
    lastName: string,
    street: string,
    postalCode: string,
    city: string,
    phone: string,
}

export type UserAccess = {
    wpAccess: WpAccess,
    pdfAccess: PDFPackage[],
}

export type UserProfileState =  UserProfile & {error: SerializedError|null, loading: boolean, success: boolean};

const initialState: UserProfileState = {
    uid: "",
    email: "",
    firstName: "",
    lastName: "",
    school: "",
    street: "",
    postalCode: "",
    city: "",
    phone: "",
    isVerifiedTeacher: false,
    wpAccess: {},
    pdfAccess: [],
    orders: [],
    error: null,
    loading: false,
    success: false,
}

export const userProfileSlice = createSlice({
    name: "userProfile",
    initialState,
    reducers: {
        updateAddress: (state, action: PayloadAction<Partial<Address>>) => {
            return {...state, ...action.payload};
        },
        updateUserProfile: (state, action: PayloadAction<Partial<UserProfile>>) => {
            return {...state, ...action.payload}
        },
        initUserProfile: (state) => {
            return initialState;
        },
        updateUserProfileAccess: (state, action: PayloadAction<UserAccess>) => {
            const pdfAccessMerged = Array.from(
                new Set([...state.pdfAccess, ...action.payload.pdfAccess])
            );
            state.pdfAccess= pdfAccessMerged;
            state.wpAccess = {
                ...state.wpAccess,
                ...action.payload.wpAccess,
            }
        },
        addOrder: (state, action: PayloadAction<number>) => {
            state.orders.push(action.payload);
        },
        setSuccess: (state, action: PayloadAction<boolean>) => {
            state.success = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state,  action: PayloadAction<SerializedError|null>) => {
            state.error = action.payload;
        }
    },
});

//export Redux action creators
export const { 
    updateAddress, 
    initUserProfile, 
    updateUserProfile, 
    setSuccess, 
    setLoading, 
    setError 
} = userProfileSlice.actions;