import React, { useReducer, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { subscribeToTopicsDoc, loadWorkplan, insertWorkPlan, updateWorkPlan } from './workPlanActions';
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import {
	Stepper,
	Button,
	TextField,
	Tooltip,
	Fab,
	MenuItem,
	Stack,
	Box,
  CssBaseline
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import GroupedMUIDataTable from "./GroupedMUIDataTable.js";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WorkPlanEditNavigation from "./WorkPlanEditNavigation.js";
import ConfirmationDialog from "./Dialogs/ConfirmationDialog";
import AddItemDialog from "./Dialogs/AddItemDialog";
import firebaseAPI from "./firebase";
import workPlanReducer, { INITIAL_STATE } from "./workPlanReducer";
import { COLORS } from "../../constants";
import * as S from "./Styles";
import {theme} from './theme';

const customTheme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
          '.root': {
            width: '100%',
            '& table': {
              '& tfoot': {
                '& div': {
                  fontSize: "14px",
                },
                '& p': {
                  fontSize: "14px",
                },
                "& tr > td": {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
                '& .MuiTablePagination-selectLabel + div': {
                  display: "flex",
                  alignItems: "center",
                  "& > svg": {
                    top: "2px",
                    right: 0,
                  },
                },
              },
              "& tbody > tr:only-child > td > div:last-child": {
                width: "100%",
              },
            },
            "& td": {
              "& button:hover svg": {
                color: `${COLORS.DIDACTO_DARK_BLUE} !important`,
              },
              "& button > svg": {
                width: "20px",
                height: "20px",
              },
            },
          },
          '.backButton': {
            marginRight: theme.spacing(1),
          },
          '.instructions': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
          }
        },
    },
	MuiToolbar: {
		styleOverrides: {
			root: {
				"&>div:last-child": {
				  [theme.breakpoints.down("md")]: {
					display: "none",
				  },
				},
				"& button:hover svg": {
				  color: `${COLORS.DIDACTO_DARK_BLUE} !important`,
				},
				"& svg": {
				  width: "24px",
				  height: "24px",
				}
			}
		  }
		}
	}
});

const normalTableTheme = createTheme(theme, {
	components: {
		MuiTableRow: {
            styleOverrides: {
				root: {
					cursor: 'pointer',
					'& th': {
					  paddingBottom: '0',
					  paddingTop: '0',
					  zIndex: '0',
					  "& div": {
						fontSize: "16px",
						fontFamily: "TisaSansPro-Bold",
					  },
					}
				},
            }
        },
		MuiTableCell: {
			styleOverrides: {
			  root: {
				fontSize: "16px",
				  '&:nth-of-type(3)': {
					width: '40%',
					[theme.breakpoints.down("md")]: {
					  width: "100%",
					},
				  },
			  }
			}
		  },
		MuiToolbar: {
			styleOverrides: {
				root: {
					"& h6": {
						fontFamily: 'TisaSansPro-Medium',
						fontSize: "20px",
					}
				}
			}
		}
	}
});

const finalTableTheme = createTheme(theme, {
	components: {
		MuiTableHead: {
			styleOverrides: {
			root: {
				'& th': {
					paddingBottom: '16px'
				}
			}
			}
		},
		MuiTable: {
            styleOverrides: {
				root: {
					marginBottom: 40,
					'& h6': {
						fontSize: "20px",
						fontFamily: "TisaSansPro-Medium",
					},
					'& tr > th': {
						paddingTop: '0',
						zIndex: '0',
						"& div": {
							fontSize: "16px",
							fontFamily: "TisaSansPro-Bold",
						}
					}
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: "16px",
					paddingTop: "6px",
					paddingBottom: "6px",
					'&:nth-of-type(2)': {
						'& svg': {
							width: '20px',
							height: '20px'
						},
						'& .MuiInputBase-root': {
							'& svg': {
								width: '16px',
								height: '16px'
							}
						}
					},
					'&:nth-of-type(3)': {
						width: '180px',
						'& svg': {
							width: '20px',
							height: '20px'
						}
					},
					'& input': {
						fontSize: "16px",
					},
				}
			}
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					"& h6": {
						fontFamily: 'TisaSansPro-Medium',
						fontSize: "20px",
					}
				}
			}
		},
		MuiTableFooter: {
			styleOverrides: {
				root: {
					"& tr": {
						height: "64.5px"
					}
				}
			}
		}
	}
});

export default function WorkPlanEdit({ path, grades, expiredGrades, pathString, id, done }) {

	// State, Redux & Firebase
	const [wpState, dispatch] = useReducer(workPlanReducer, INITIAL_STATE);
	const user = useSelector(state => state.app.user);
	const userId = user?.uid;
	const [grade, setGrade] = React.useState(grades[0]);
	const emptyWorkPlan = {
		selectedTopics: [],
		exerciseTableRows: [],
		selectedExercises: [],
		finalTableRows: [],
		name: "",
		grade: grade
	};
	const [workPlan, setWorkPlan] = React.useState(emptyWorkPlan);
	const { topics, currentWorkPlan, workPlanLoading } = wpState;
	const [hasName, setHasName] = React.useState(false);
	const [saveClicked, setSaveClicked] = React.useState(false);
	const isNew = id === 'new';
	const selectedTopicIds = () => workPlan.selectedTopics.sort((x,y) => parseInt(x) - parseInt(y)).map(i => topicRows[i].topicId);
	const subTopicsDoc = useCallback(() => {
		subscribeToTopicsDoc({ grade, firebaseAPI, dispatch })
	}, [grade, dispatch]);
	const loadWorkplanDoc = useCallback(() => loadWorkplan({ userId, path, workPlanId: id, firebaseAPI, dispatch }),
		[userId, path, id, dispatch])
	const isWorkPlanLoading = useCallback(() => {
		if (currentWorkPlan && !workPlanLoading) {
			setWorkPlan(currentWorkPlan);
			setGrade(currentWorkPlan.grade);
			setHasName(true);
		}
	}, [currentWorkPlan, workPlanLoading])
	useEffect(() => {
		return subTopicsDoc();
	}, [subTopicsDoc]);
	useEffect(() => {
		if (!isNew) {
			return loadWorkplanDoc();
		}
	}, [id, isNew, loadWorkplanDoc]);
	useEffect(() => {
		if (!isNew) {
			return isWorkPlanLoading();
		}
	}, [id, isNew, isWorkPlanLoading]);
	const backToOverView = useCallback(() => {
		if (saveClicked) {
			done();
			setSaveClicked(false);
		}
	}, [saveClicked, done]);
	useEffect(() => {
		return backToOverView();
	}, [backToOverView]);
	const [activeStep, setActiveStep] = React.useState(isNew ? 0 : 2);
	const [addObjectiveDialogOpen, setAddObjectiveDialogOpen] = React.useState(false);
	const [nameGradeDialogOpen, setNameGradeDialogOpen] = React.useState(isNew);
	const [newItem, setNewItem] = React.useState({ myObjective: 'Eigene Kompetenz', level: 1 });
	const [cancelConfirmationOpen, setCancelConfirmationOpen] = React.useState(false);
	const [itemsToDelete, setItemsToDelete] = React.useState(null);
	const [exerciseSortOrder, setExerciseSortOrder] = React.useState({ col: 'level', sign: 1 });

	// Localization config
	const localOptions = {
		body: {
			noMatch: "Keine Daten gefunden",
			toolTip: "Sortieren",
			columnHeaderTooltip: column => `Sortieren nach ${column.label}`
		},
		pagination: {
			next: "Weiter",
			previous: "Zurück",
			rowsPerPage: "Zeilen pro Seite:",
			displayRows: "von",
		},
		toolbar: {
			search: "Suche",
			downloadCsv: "Download CSV",
			print: "Drucken",
			viewColumns: "Spalten anzeigen",
			filterTable: "Tabelle filtern",
		},
		filter: {
			all: "Alle",
			title: "FILTER",
			reset: "RESET",
		},
		viewColumns: {
			title: "Spalten anzeigen",
			titleAria: "Spalten Anzeigen/Verstecken",
		},
		selectedRows: {
			text: "Zeile(n) ausgewählt",
			delete: "Löschen",
			deleteAria: "Ausgewählte Zeile(n) löschen",
		}
	};

	// Helper
	const compareObjectives = (x, y) => {
		let xIds = x.match(/^\d+(\.\d+){0,3}/)[0].split(".");
		let yIds = y.match(/^\d+(\.\d+){0,3}/)[0].split(".");
		let res = 0;
		xIds.every((xId, i) => {
			res = xId - yIds[i];
			return res === 0;
		});
		return res;
	}
	const isSameExercise = (ex) => {
		return (element) => ex.objective === element.objective && ex.exercise === element.exercise && ex.level === element.level;
	}

	// TopicTable config
	const topicsTableColDef = [
		{
			name: 'topicId', label: 'Thema', options: {
				sortCompare: (order) => {
					return (x, y) => order === 'asc' ? compareObjectives(x.data, y.data) : -1 * compareObjectives(x.data, y.data);
				}
			}
		},
		{ name: 'name', label: 'Name' },
		{ name: 'count', label: 'Anzahl Aufgaben' }
	];
	const countExcercises = (topic) => {
		return (Object.entries(topic.objectives)
			.map(([k, v]) => v).map(x => x.levels)
			.flatMap(x => Object.entries(x).map(([k, v]) => v.length))
			.reduce((x, y) => x + y, 0));
	}
	const topicRows = Object.entries(topics)
		.map(([k, v]) => ({ topicId: k, name: v.title, count: countExcercises(v) }))
		.sort((x, y) => compareObjectives(x.topicId, y.topicId));
	const topicTableOptions = {
		textLabels: localOptions,
		fixedHeader: true,
		tableBodyHeight: '400px',
		rowsPerPage: 100,
		filter: false,
		download: false,
		print: false,
		viewColumns: false,
		selectableRowsOnClick: true,
		rowsSelected: workPlan.selectedTopics,
		selectToolbarPlacement: 'none',
		onRowSelectionChange: (curr, all, rows) => setWorkPlan({ ...workPlan, selectedTopics: rows })
	};


	// ExerciseTable config
	let filterMap = {
		'Themenbuch': /^TB/,
		'Arbeitsheft': /^AH/,
		'Arbeitsblätter': /^A\d+\./
	};
	const getSortWeight = (ex) => {
		if (ex.match(/^TB /)) return 0;
		else if (ex.match(/^AH /)) return 1;
		else if (ex.match(/^A\d+\./)) return 2;
		else return ex;
	};
	const exercisesTableColDef = [
		{
			name: "exercise",
			label: "Aufgabe",
			options: {
				sortFilterList: false,
				filterOptions: {
					names: Object.keys(filterMap),
					logic: (value, filters) => {
						if (!filters.length || value === undefined) return false;
						let matchFound = false;
						filters.every(filter => {
							if (value.match(filterMap[filter])) matchFound = true;
							return !matchFound;
						});
						return !matchFound;
					}
				}
			}
		},
		{ name: 'level', label: 'Niveau' }
	];
	const exerciseTableOptions = {
		textLabels: localOptions,
		sort: true,
		fixedHeader: true,
		tableBodyHeight: '400px',
		rowsPerPage: 100,
		download: false,
		print: false,
		viewColumns: false,
		selectableRowsOnClick: true,
		filterType: 'multiselect',
		rowsSelected: workPlan.selectedExercises,
		selectToolbarPlacement: 'none',
		onRowSelectionChange: (curr, all, rows) => { setWorkPlan({ ...workPlan, selectedExercises: rows }); },
		customSort: (data, colIndex, order, meta) => {
			let sign = (order === 'asc') ? 1 : -1;
			let col = colIndex === 0 ? 'exercise' : 'level';
			if (exerciseSortOrder.col !== col || exerciseSortOrder.sign !== sign) setExerciseSortOrder({ col, sign });
			return data.sort((x, y) => sign * (getSortWeight(x.data[colIndex]) - getSortWeight(y.data[colIndex])));
		}
	};
	const initExerciseTableRows = () => {
		const ids = selectedTopicIds();
		const objectives = Object.entries(topics)
			.filter(([k, v]) => ids.includes(k))
			.map(([k, v]) => v.objectives)
			.reduce((acc, obj) => Object.assign(acc, obj), {});
		let rows = [];
		Object.entries(objectives).forEach(([objId, obj], _) => {
			Object.entries(obj.levels).forEach(([level, exercises], _) => {
				exercises.forEach((ex, _) => {
					rows.push({ objective: objId + " " + obj.name, level: level, exercise: ex });
				});
			});
		});
		rows = rows.sort((x, y) => compareObjectives(x.objective, y.objective));
		let selectedExercises = [];
		rows.forEach((item, i) => {
			if (workPlan.exerciseTableRows.findIndex(isSameExercise(item)) !== -1) selectedExercises.push(i);
		});
		setWorkPlan({ ...workPlan, exerciseTableRows: rows, selectedExercises });
	};

	// FinalTable config
	const handleFinalTableDelete = (rowIndices) => {
		let tempRows = workPlan.finalTableRows.filter((_x, i) => !rowIndices.includes(i));
		setWorkPlan({ ...workPlan, finalTableRows: tempRows });
	};
	const handleFinalTableMove = (upIndices, downIndices) => {
		let minDown = Math.min(...downIndices);
		let maxUp = Math.max(...upIndices);
		let { finalTableRows } = workPlan;
		let tempRows = [
			...(finalTableRows.filter((_x, i) => i < minDown)),
			...(upIndices.map(x => finalTableRows[x])),
			...(downIndices.map(x => finalTableRows[x])),
			...(finalTableRows.filter((_x, i) => i > maxUp))
		]
		setWorkPlan({ ...workPlan, finalTableRows: tempRows });
	};
	const handleFinalTableCopy = (rowIndices) => {
		let tempRows = [];
		workPlan.finalTableRows.forEach((item, i) => {
			tempRows.push(item);
			if (rowIndices.includes(i)) tempRows.push(Object.assign({}, item));
		});
		setWorkPlan({ ...workPlan, finalTableRows: tempRows });
	};
	const finalTableColDef = [
		{
			name: "exercise",
			label: "Aufgabe",
			options: {
				customBodyRenderLite: (dataIndex, rowIndex) => {
					if (workPlan.finalTableRows[dataIndex]) {
						return (
							<TextField
								size="small"
								sx={{ width: "30%", minWidth: 120 }}
								value={workPlan.finalTableRows[dataIndex].exercise}
								onChange={event => {
									let updatedFinalTableRows = workPlan.finalTableRows;
									updatedFinalTableRows[dataIndex].exercise = event.target.value;
									setWorkPlan({ ...workPlan, finalTableRows: updatedFinalTableRows });
								}}
							/>
						);
					}
				}
			}
		},
		{
			name: "level",
			label: "Niveau",
			options: {
				customBodyRenderLite: (dataIndex, rowIndex) => {
					if (workPlan.finalTableRows[dataIndex]) {
						return (
							<TextField
								size="small"
								inputProps={{ style: { textAlign: "center" } }}
								value={workPlan.finalTableRows[dataIndex].level}
								select
								onChange={event => {
									let updatedFinalTableRows = workPlan.finalTableRows;
									updatedFinalTableRows[dataIndex].level = event.target.value;
									setWorkPlan({ ...workPlan, finalTableRows: updatedFinalTableRows });
								}}
							>
								{[1, 2, 3].map((val) => <MenuItem key={val} value={val}>{val}</MenuItem>)}
							</TextField>
						);
					}
				}
			}
		}
	];
	const initFinalTableRows = () => {
		let { finalTableRows, selectedExercises, exerciseTableRows } = workPlan;
		selectedExercises = selectedExercises.sort((x,y) => parseInt(x) - parseInt(y)).sort((x, y) => {
			let comp = compareObjectives(exerciseTableRows[x].objective, exerciseTableRows[y].objective);
			if (comp === 0) {
				comp = exerciseSortOrder.sign * (getSortWeight((exerciseTableRows[x])[exerciseSortOrder.col]) - getSortWeight((exerciseTableRows[y])[exerciseSortOrder.col]));
			}
			return comp;
		});
		let matchedIndices = selectedExercises.map(i => finalTableRows.findIndex(isSameExercise(exerciseTableRows[i])));
		let tempFinalTableRows = [];
		finalTableRows.forEach((item, i) => {
			if (item.isManual || matchedIndices.indexOf(i) !== -1) tempFinalTableRows.push(item);
		});
		matchedIndices.forEach((item, i) => {
			if (item === -1) tempFinalTableRows = insertNewExercise(tempFinalTableRows, Object.assign({}, exerciseTableRows[selectedExercises[i]]));
		});
		setWorkPlan({ ...workPlan, finalTableRows: tempFinalTableRows });
	}
	const finalTableOptions = {
		textLabels: localOptions,
		selectableRows: 'none',
		fixedHeader: true,
		tableBodyHeight: '400px',
		rowsPerPage: 100,
		download: false,
		print: false,
		sort: false,
		filter: false,
		viewColumns: false,
		rowActions: {
			move: {
				titleRow: true,
				contentRows: true
			},
			onRowsMove: handleFinalTableMove,
			copy: {
				titleRow: false,
				contentRows: true
			},
			onRowsCopy: handleFinalTableCopy,
			delete: {
				titleRow: false,
				contentRows: true
			},
			onRowsDelete: setItemsToDelete
		}
	};
	const handleNewObjectiveAdded = () => {
		setAddObjectiveDialogOpen(false);
		setWorkPlan({ ...workPlan, finalTableRows: insertNewExercise(workPlan.finalTableRows, newItem) });
		setNewItem({ myObjective: 'Eigene Kompetenz', level: 1 });
	};
	const insertNewExercise = (exercises, newExercise) => {
		let tempRows = [];
		let addState = '';
		exercises.forEach(item => {
			if (item.objective === newExercise.objective) addState = 'groupFound';
			else if (addState === 'groupFound') {
				tempRows.push(newExercise);
				addState = 'itemAdded';
			}
			tempRows.push(item);
		});
		if (addState !== 'itemAdded') tempRows.push(newExercise);
		return tempRows;
	};
	const initSelectedExercises = () => {
		const { finalTableRows, exerciseTableRows } = workPlan;
		let tempFinalTableRows = finalTableRows;
		let selectedExercises = [];
		finalTableRows.forEach((item, i) => {
			let exerciseIndex = exerciseTableRows.findIndex(isSameExercise(item));
			if (exerciseIndex === -1) tempFinalTableRows[i].isManual = true;
			else selectedExercises.push(exerciseIndex);
		});
		selectedExercises.sort((x,y) => parseInt(x) - parseInt(y));
		setWorkPlan({ ...workPlan, finalTableRows: tempFinalTableRows, selectedExercises: selectedExercises });
	};
	const saveSelectedExercises = () => {
		let { exerciseTableRows, selectedExercises } = workPlan;
		setWorkPlan({ ...workPlan, selectedExercises: [], exerciseTableRows: selectedExercises.map(i => exerciseTableRows[i]) });
	};


	// Stepper config
	function getSteps() {
		return ['Themen auswählen', 'Aufgaben auswählen', 'Arbeitsplan fertigstellen'];
	}
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return <div>
					<ThemeProvider theme={normalTableTheme}>
						<MUIDataTable
							title={"Themen"}
							data={topicRows}
							columns={topicsTableColDef}
							options={topicTableOptions}
						/>
					</ThemeProvider>
					<AddItemDialog open={nameGradeDialogOpen}
						handleCancel={handleReset}
						handleConfirm={() => setNameGradeDialogOpen(false)}
						title={"Neuer Arbeitsplan"}
						description={"Bitte wähle die Stufe und benenne den Arbeitsplan"}
						confirmText={"OK"}
						confirmAllowed={hasName}>
						<TextField
							sx={{mt: "16px"}}
							fullWidth
							variant="outlined"
							margin="dense"
							color="primary"
							id="grade"
							label="Stufe"
							select
							value={grade}
							onChange={(e) => { setWorkPlan({ ...workPlan, grade: e.target.value }); setGrade(e.target.value); }}
							onKeyPress={(e) => { if (e.code === 'Enter' && hasName) setNameGradeDialogOpen(false) }}
						>
							{grades.map((val) => <MenuItem key={val} value={val}>{val}</MenuItem>)}
							{expiredGrades.map((val) => <MenuItem disabled={true} key={val} value={val}><i>*{val} abgelaufen</i></MenuItem>)}
						</TextField>
						<TextField
							variant="outlined"
							margin="dense"
							color="primary"
							id="name"
							label="Name"
							required
							error={workPlan.name.trim() === ''}
							fullWidth
							autoFocus
							onChange={(e) => { setWorkPlan({ ...workPlan, name: e.target.value }); setHasName(e.target.value.trim() !== ''); }}
							onKeyPress={(e) => { if (e.code === 'Enter' && hasName) setNameGradeDialogOpen(false) }}
						/>
					</AddItemDialog>
				</div>
			case 1:
				return <ThemeProvider theme={normalTableTheme}>
							<GroupedMUIDataTable
								title={"Aufgaben"}
								data={workPlan.exerciseTableRows}
								columns={exercisesTableColDef}
								options={exerciseTableOptions}
								groupingKey={'objective'}
							/>
						</ThemeProvider>
			case 2:
				return <div>
					<ThemeProvider theme={finalTableTheme}>
						<GroupedMUIDataTable
							title={"Arbeitsplan fertigstellen"}
							data={workPlan.finalTableRows}
							columns={finalTableColDef}
							options={finalTableOptions}
							groupingKey={'objective'}
						/>
					</ThemeProvider>
					<Tooltip title={"Aufgabe hinzufügen"}>
						<Fab color="primary" onClick={() => setAddObjectiveDialogOpen(true)} style={{ float: 'right', marginTop: '-150px', marginRight: '2.48%' }}>
							<AddIcon />
						</Fab>
					</Tooltip>
					<AddItemDialog open={addObjectiveDialogOpen}
						handleCancel={() => { setNewItem({ myObjective: 'Eigene Kompetenz', level: 1 }); setAddObjectiveDialogOpen(false); }}
						handleConfirm={handleNewObjectiveAdded}
						title={"Aufgabe hinzufügen"}
						description={"Eigene Aufgabe erstellen."}
						confirmText={"Hinzufügen"}
						confirmAllowed={newItem.objective && newItem.objective.trim() !== '' && newItem.exercise && newItem.exercise.trim() !== ''}>
						<div style={(newItem.myObjective === 'Eigene Kompetenz') ? { fontStyle: 'italic' } : { fontStyle: 'normal' }}>
							<TextField
								fullWidth
								margin="dense"
								id="objective"
								label="Kompetenz"
								select
								value={newItem.myObjective}
								onChange={(e) => {
									let myObjective = e.target.value;
									let objective = (myObjective === 'Eigene Kompetenz') ? '' : myObjective;
									setNewItem({ ...newItem, myObjective, objective });
								}}
								onKeyPress={(e) => { if (e.code === 'Enter' && newItem.objective && newItem.objective.trim() !== '' && newItem.exercise && newItem.exercise.trim() !== '') handleNewObjectiveAdded() }}
							>
								<MenuItem style={{ fontStyle: 'italic' }} key={'custom'} value={'Eigene Kompetenz'}>Eigene Kompetenz</MenuItem>
								{workPlan.finalTableRows.map(x => x.objective).filter((value, index, self) => self.indexOf(value) === index).map((val) => <MenuItem key={val} value={val}>{val}</MenuItem>)}
							</TextField>
						</div>
						{(newItem.myObjective === 'Eigene Kompetenz') ?
							(<TextField
								fullWidth
								autoFocus
								margin="dense"
								id="myObjective"
								label="Eigene Kompetenz"
								error={!newItem.objective || newItem.objective.trim() === ''}
								onChange={(e) => setNewItem({ ...newItem, objective: e.target.value })}
								onKeyPress={(e) => { if (e.code === 'Enter' && newItem.objective && newItem.objective.trim() !== '' && newItem.exercise && newItem.exercise.trim() !== '') handleNewObjectiveAdded() }}
							/>) : ''
						}
						<TextField
							margin="dense"
							fullWidth
							id="exercise"
							label="Aufgabe"
							error={!newItem.exercise || newItem.exercise.trim() === ''}
							onChange={(e) => setNewItem({ ...newItem, exercise: e.target.value })}
							onKeyPress={(e) => { if (e.code === 'Enter' && newItem.objective && newItem.objective.trim() !== '' && newItem.exercise && newItem.exercise.trim() !== '') handleNewObjectiveAdded() }}
						/>
						<TextField
							fullWidth
							margin="dense"
							id="level"
							label="Niveau"
							value={newItem.level}
							select
							onChange={(e) => setNewItem({ ...newItem, level: e.target.value })}
							onKeyPress={(e) => { if (e.code === 'Enter' && newItem.objective && newItem.objective.trim() !== '' && newItem.exercise && newItem.exercise.trim() !== '') handleNewObjectiveAdded() }}
						>
							{[1, 2, 3].map((val) => <MenuItem key={val} value={val}>{val}</MenuItem>)}
						</TextField>
					</AddItemDialog>
					<ConfirmationDialog
						isOpen={itemsToDelete !== null}
						handleCancel={() => setItemsToDelete(null)}
						handleConfirm={() => { handleFinalTableDelete(itemsToDelete); setItemsToDelete(null); }}
						title="Aufgabe löschen?"
						text="Möchtest Du die Aufgabe wirklich löschen?"
						cancelTitle="Abbrechen"
						confirmTitle="Löschen"
					/>
				</div>;
			default:
				return 'Unknown stepIndex';
		}
	}
	const steps = getSteps();
	const handleNext = () => {
		if (activeStep === 0) initExerciseTableRows();
		else if (activeStep === 1) initFinalTableRows();
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const handleBack = () => {
		if (activeStep === 1) saveSelectedExercises();
		else if (activeStep === 2) initSelectedExercises();
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	const handleReset = () => {
		done();
		setActiveStep(0);
		setWorkPlan(emptyWorkPlan);
	};

	// Navigation props
	const navigationProps = {
		name: workPlan.name,
		onNameChange: (e) => {
			setWorkPlan({ ...workPlan, name: e.target.value });
			setHasName(e.target.value.trim() !== '');
		},
		grade: grade
	}

	// Return Component
	return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <S.StyledStack>
        <Stack>
          <WorkPlanEditNavigation {...navigationProps} />
          <Stepper activeStep={activeStep} alternativeLabel sx={{mt: "15px"}}>
            {steps.map((label) => (
              <S.StyledStep key={label}>
                <S.StyledStepLabel>{label}</S.StyledStepLabel>
              </S.StyledStep>
            ))}
          </Stepper>
          <Stack>
            {getStepContent(activeStep)}
            <Box id="navigation" my={3} direction="row" display="flex" justifyContent={"space-between"} sx={theme => ({
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                "& #buttons-left": {
                  flexDirection: "column",
                  "& > * ": {
                    marginLeft: 0
                  },
                  "& button + button": {
                    my: "16px"
                  }
                }

              }
            })}>
              <Stack id="buttons-left" direction="row" spacing={5}>
                <Button
                  size="large"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                  startIcon={<ArrowBackIosIcon />}
                >
                  {(() => {
                    switch (activeStep) {
                      case 1:
                        return ("Themen auswählen");
                      case 2:
                        return ("Aufgaben auswählen");
                      default:
                        return ("Zurück");
                    }
                  })()}
                </Button>
                <Button
                  color="secondary"
                  startIcon={<CancelIcon />}
                  variant="outlined"
                  size="large"
                  onClick={() => setCancelConfirmationOpen(true)}>
                  Abbrechen
                </Button>
                <ConfirmationDialog
                  isOpen={cancelConfirmationOpen}
                  handleCancel={() => setCancelConfirmationOpen(false)}
                  handleConfirm={handleReset}
                  title="Bearbeitung abbrechen?"
                  text="Möchtest du die Bearbeitung wirklich abbrechen? Alle Änderungen am Arbeitsplan werden dabei verworfen."
                  cancelTitle="Nein"
                  confirmTitle="Ja"
                />
              </Stack>
              <Stack id="buttons-right">
                {activeStep !== steps.length - 1 ?
                  (<Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={handleNext}>
                    Weiter
                  </Button>) :
                  (<Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    disabled={!hasName}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      if (isNew) insertWorkPlan({ userId, pathString, workPlan, firebaseAPI });
                      else updateWorkPlan({ userId, pathString, workPlan, workPlanId: id, firebaseAPI });
                      setSaveClicked(true);
                    }}
                  >Speichern</Button>)
                }

              </Stack>
            </Box>

          </Stack>
        </Stack>

      </S.StyledStack>
    </ThemeProvider>
	);
}
