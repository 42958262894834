import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ConfirmationDialog = ({isOpen, handleCancel, handleConfirm, title, text, cancelTitle, confirmTitle}) => {
  return(
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
          <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelTitle? (
          <Button onClick={handleCancel} color="primary">
            {cancelTitle}
          </Button>
        ) : ''}
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {confirmTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
