import { COLORS } from "../../constants";
import { Input, MultiInputBox } from "../Inputs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { UserProfile, updateAddress } from "../../pages/CheckoutPage/userProfileSlice";

export const AddressForm = () => {
    const dispatch = useDispatch();
    const {
        school,
        firstName,
        lastName,
        street,
        postalCode,
        city,
        phone,
    } = useSelector<RootState, UserProfile>(state => state.userProfile);
    return (
        <form onSubmit={(e) => { e.preventDefault() }} style={{margin: "0 0 1rem 0"}}>
            <Input id="school"
                name="school"
                placeholder="Schule"
                label="Schule"
                value={school}
                onChange={e => dispatch(updateAddress({ school: e.target.value }))}
                secondaryColor={COLORS.DIDACTO_DARK_BLUE}
            />
            <MultiInputBox>
                <Input id="firstName"
                    name="firstName"
                    placeholder="Vorname"
                    label="Vorname"
                    value={firstName}
                    onChange={e => dispatch(updateAddress({ firstName: e.target.value }))}
                    secondaryColor={COLORS.DIDACTO_DARK_BLUE}
                    width="50%"
                />
                <Input id="lastName"
                    name="lastName"
                    placeholder="Nachname"
                    label="Nachname"
                    value={lastName}
                    onChange={e => dispatch(updateAddress({ lastName: e.target.value }))}
                    secondaryColor={COLORS.DIDACTO_DARK_BLUE}
                    width="50%"
                    margin="0 0 0 1rem"
                />
            </MultiInputBox>

            <Input id="street"
                name="street"
                placeholder="Strasse und Hausnummer"
                label="Strasse und Hausnummer"
                value={street}
                onChange={e => dispatch(updateAddress({ street: e.target.value }))}
                secondaryColor={COLORS.DIDACTO_DARK_BLUE}
            />
            <MultiInputBox>
                <Input id="postalCode"
                    name="postalCode"
                    placeholder="PLZ"
                    label="PLZ"
                    value={postalCode}
                    onChange={e => dispatch(updateAddress({ postalCode: e.target.value }))}
                    secondaryColor={COLORS.DIDACTO_DARK_BLUE}
                    width="20%"
                />
                <Input id="city"
                    name="city"
                    placeholder="Ort"
                    label="Ort"
                    value={city}
                    onChange={e => dispatch(updateAddress({ city: e.target.value }))}
                    secondaryColor={COLORS.DIDACTO_DARK_BLUE}
                    width="80%"
                    margin="0 0 0 1rem"
                />
            </MultiInputBox>
            <Input id="phone"
                name="phone"
                placeholder="Telefon Mobil (optional)"
                label="Telefon Mobil (optional)"
                value={phone}
                onChange={e => dispatch(updateAddress({ phone: e.target.value }))}
                secondaryColor={COLORS.DIDACTO_DARK_BLUE}
            />
        </form>
    )
};