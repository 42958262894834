import { COLORS } from '../../constants';
import { DidactoColor } from '../../types/App';
import { Button } from '../Buttons';
import { Close } from '../Icons';
import * as S from './Styles';

export type ModalProps = {
    backgroundColor: DidactoColor,
    fontColor: DidactoColor,
    shadowColor: DidactoColor,
    title: string,
    text: string,
    buttonText: string,
    buttonVariant: 'red' | 'white' | 'blue',
    closeModal: () => void,
    onButtonClick: () => void,
}

export const Modal = ({
    backgroundColor,
    fontColor,
    shadowColor,
    title,
    text,
    buttonText,
    buttonVariant,
    closeModal,
    onButtonClick,

}: ModalProps) => {
    return (
        <S.ModalBackground>
            <S.ModalBox {...{ backgroundColor, fontColor, shadowColor }}>
                <S.ModalCloseBox onClick={closeModal}><Close width="24px" color={COLORS.DIDACTO_DARK_BLUE} /></S.ModalCloseBox>
                <S.ModalTitle>{title}</S.ModalTitle>
                <S.ModalText>{text}</S.ModalText>
                <S.ModalButtonBox>
                    <Button id="modal-button" onClick={onButtonClick} variant={buttonVariant} text={buttonText} />
                </S.ModalButtonBox>
            </S.ModalBox>
        </S.ModalBackground>
    )
}