import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const AddItemDialog = ({children, open, handleCancel, handleConfirm, title, description, confirmText, confirmAllowed}) => {
  return(
    <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" sx={{fontSize: "20px"}}>{title}</DialogTitle>
      <DialogContent>
        {description ? (
            <DialogContentText sx={{fontSize: "16px"}}>
              {description}
            </DialogContentText>
          ): ''}
        {children}
      </DialogContent>
      <DialogActions sx={{px: "24px", pb: "16px"}}>
        <Button onClick={handleCancel} size="medium" color="primary" variant="contained">
          Abbrechen
        </Button>
        <Button onClick={handleConfirm} color="primary" disabled={!confirmAllowed} variant="contained">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddItemDialog;
