import { styled } from '@mui/material/styles';
import { DidactoColor } from '../../types/App';

type StepperBoxProps = {
    fontColor: DidactoColor;
};

export const StepperBox = styled('div')<StepperBoxProps>(({ fontColor }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '2rem 0',
    alignItems: 'stretch',
    fontFamily: 'TisaSansPro',
    color: fontColor,
}));

export const StepperHeaderBox = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1.5rem',
});

export const StepperContentBox = styled('div')({
    width: '100%',
    display: 'flex',
});

export const StepTitleActive = styled('div')({
    // Add specific styles if needed
});

type StepProps = {
    backgroundColor: DidactoColor,
};

export const Step = styled('div')<StepProps>(({ backgroundColor }) => ({
    display: 'flex',
    backgroundColor: backgroundColor,
    flexDirection: 'column',
    padding: '1.5rem',
    marginBottom: '2rem',
    '& p': {
        fontSize: '1.6rem',
        margin: '5px',
    },
}));

type StepBoxProps = {
    isActive: boolean;
};

export const StepBox = styled('div')<StepBoxProps>(({ isActive }) => ({
    transform: isActive ? "scale(1)" : "scale(0)",
    width: isActive ? "100%" : "0%",
}));

export const StepNavBox = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
});

export const StepErrorBox = styled('div')({
    flexBasis: '100%',
    borderColor: '', // Add color as needed
});

type StepHeaderProps = {
    color: DidactoColor,
};

export const StepHeader = styled('div')<StepHeaderProps>(({ color }) => ({
    cursor: 'pointer',
    fontSize: '1.5rem',
    padding: '0 2rem 0 2rem',
    color: color,
}));

export const StepHeaderActive = styled(StepHeader)({
    fontSize: '2rem', // Bigger size when active
});