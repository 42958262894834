import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import { db } from '../../firebase'
import { addProduct } from './shopSlice';
import { ProductConverter, IProduct } from "../../domains/Product";
import { WhiteSection, SectionTitle, SectionHeader } from '../../components/Sections';
import { Modal } from '../../components/Modal';

import { COLLECTIONS, COLORS } from '../../constants';
import shopHeaderSrc from '../../assets/mountains_blueish.jpg';
import imagePackageGrade3 from './assets/image-shop-package-3.png';
import imagePackageGrade4 from './assets/image-shop-package-4.png';
import imagePackageGrade5 from './assets/image-shop-package-5.png';
import imagePackageGrade6 from './assets/image-shop-package-6.png';
import imagePackagePlusGrade3 from './assets/image-shop-package-plus-3.png';
import imagePackagePlusGrade4 from './assets/image-shop-package-plus-4.png';
import imagePackagePlusGrade5 from './assets/image-shop-package-plus-5.png';
import imagePackagePlusGrade6 from './assets/image-shop-package-plus-6.png';
import imageWorkPlanGrade3 from './assets/image-shop-arbeitsplangenerator-3.png';
import imageWorkPlanGrade4 from './assets/image-shop-arbeitsplangenerator-4.png';
import imageWorkPlanGrade5 from './assets/image-shop-arbeitsplangenerator-5.png';
import imageWorkPlanGrade6 from './assets/image-shop-arbeitsplangenerator-6.png';
import { CardBox } from '../../components/Cards';
import { ShopCard } from '../../components/Cards';

const PRODUCT_IMAGES: { [key: number]: string } = {
    3: imagePackageGrade3,
    4: imagePackageGrade4,
    5: imagePackageGrade5,
    6: imagePackageGrade6,
    300: imageWorkPlanGrade3,
    400: imageWorkPlanGrade4,
    500: imageWorkPlanGrade5,
    600: imageWorkPlanGrade6,
    303: imagePackagePlusGrade3,
    404: imagePackagePlusGrade4,
    505: imagePackagePlusGrade5,
    606: imagePackagePlusGrade6,
}

const MODAL_PROPS = {
    backgroundColor: COLORS.DIDACTO_WHITE,
    fontColor: COLORS.DIDACTO_DARK_BLUE,
    shadowColor: COLORS.DIDACTO_DARK_BLUE,
    title: "Info",
    text: "Dieses Produkt enthält Lernkontrollen, die zur Prüfungsvorbereitung genutzt werden können und darf nur von Lehrpersonen und Schulen bestellt werden.",
    buttonText: "weiter",
    buttonVariant: "blue" as const,
};

export const Shop = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const addToCart = (product: IProduct) => dispatch(addProduct(product));
    const [showModal, setShowModal] = useState(false);
    const [modalProduct, setModalProduct] = useState<null|IProduct>(null);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [, setIsLoading] = useState<boolean>(false);

    const modalProps = {
        ...MODAL_PROPS,
        closeModal: () => setShowModal(false),
    };

    useEffect(() => {
        const getProducts = async () => {
            try {
                setIsLoading(true);
                const querySnapshot = await getDocs(collection(db, COLLECTIONS.products).withConverter(ProductConverter));
                setIsLoading(false);
                const products = querySnapshot.docs.map((doc) => doc.data());
                products.sort((p1, p2) => p1.productId - p2.productId);
                setProducts(products);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error);
                } else {
                    setError(new Error("Unhandled error"));
                }
            }
        };
        getProducts();
    }, [setError, setIsLoading, setProducts]);

    const shopCards = products.sort((p1, p2) => p1.sortOrder - p2.sortOrder)
        .filter(product => product.active === true)
        .map(product => {
            const shopCardProps = {
                key: product.id,
                id: product.id,
                title: product.name,
                facts: product.facts,
                price: product.price,
                image: PRODUCT_IMAGES[product.productId],
                tag: product.tag,
                instruction: product.instruction,
            };
            return <ShopCard {...shopCardProps} addToCart={() => {
                if (product.tag.toUpperCase().includes("PDF")){
                    setModalProduct(product);
                    setShowModal(true);
                } else {
                    addToCart(product)
                    navigate("/cart");
                }
            }} />
        });

    return (
        <>
            <SectionHeader id="shop-header" imgSrc={shopHeaderSrc} />
            <WhiteSection id="shop">
                <SectionTitle>Willkommen im Shop</SectionTitle>

                <CardBox width="90vw">
                    {shopCards}
                    {
                        error ? <h1>{error.message}</h1> : null
                    }
                    {
                        (showModal && modalProduct) ? 
                            <Modal  {...modalProps} onButtonClick={() => {
                                addToCart(modalProduct);
                                navigate("/cart");
                            }} /> :
                            null
                    }
                </CardBox>
            </WhiteSection>
        </>
    )
}