import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import {Step, StepLabel} from '@mui/material';

// Styled Stack with root styles
export const StyledStack = styled(Stack)({
  position: "relative",
  width: "100%"
});

export const StyledAccordion = styled(Accordion)({
  "&.MuiAccordion-root": {
    boxShadow: "none",
    "&:before": {
      display: "none"
    }
  }
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  '&.heading': {
    fontSize: theme.typography.pxToRem(18),
    flexShrink: 0,
  },
  '&.secondaryHeading': {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  }
}));

export const StyledStep = styled(Step)(({ theme }) => ({
  '& svg': {
    width: "20px",
    height: "20px",
    '& text': {
      fontSize: "12px",
    },
  },
}));

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& span': {
    fontSize: "14px",
  },
}));
