import { COLORS } from "../../constants";
import { IconProps } from "./index";


export const Delete = ({ width="100%", height="100%", color=COLORS.DIDACTO_BLACK, onClick }: IconProps) => {
    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" fill={color} width={width} height={height}>
            <g id="Group-9">
                <path d="M6.3255,18.584 C6.8335,18.584 7.2485,18.168 7.2485,17.661 L7.2485,7.965 C7.2485,7.457 6.8335,7.041 6.3255,7.041 C5.8175,7.041 5.4025,7.457 5.4025,7.965 L5.4025,17.661 C5.4025,18.192 5.8175,18.584 6.3255,18.584" id="Fill-1"></path>
                <path d="M9.9268,18.584 C10.4348,18.584 10.8498,18.168 10.8498,17.661 L10.8498,7.965 C10.8498,7.457 10.4348,7.041 9.9268,7.041 C9.4188,7.041 9.0038,7.457 9.0038,7.965 L9.0038,17.661 C9.0038,18.192 9.4188,18.584 9.9268,18.584" id="Fill-3"></path>
                <path d="M13.5282,18.584 C14.0362,18.584 14.4512,18.168 14.4512,17.661 L14.4512,7.965 C14.4512,7.457 14.0362,7.041 13.5282,7.041 C13.0202,7.041 12.6052,7.457 12.6052,7.965 L12.6052,17.661 C12.6052,18.192 13.0202,18.584 13.5282,18.584" id="Fill-5"></path>
                <path d="M18.9302,3.6245 L14.2672,3.6245 L13.6902,1.6855 C13.3892,0.6925 12.4662,0.0005 11.4272,0.0005 L8.4262,0.0005 C7.3872,0.0005 6.4642,0.6925 6.1642,1.6855 L5.5862,3.6245 L0.9232,3.6245 C0.4152,3.6245 0.0002,4.0405 0.0002,4.5475 C0.0002,5.0555 0.4152,5.4715 0.9232,5.4715 L1.6392,5.4715 L1.6392,19.7155 C1.6392,20.9385 2.6322,21.9545 3.8782,21.9545 L15.9982,21.9545 C17.2222,21.9545 18.2372,20.9615 18.2372,19.7155 L18.2372,5.4485 L18.9302,5.4485 C19.4382,5.4485 19.8532,5.0325 19.8532,4.5245 C19.8532,4.0165 19.4382,3.6245 18.9302,3.6245 L18.9302,3.6245 Z M7.9412,2.2165 C8.0102,2.0085 8.1952,1.8465 8.4262,1.8465 L11.4502,1.8465 C11.6812,1.8465 11.8662,1.9855 11.9352,2.2165 L12.3512,3.6245 L7.5032,3.6245 L7.9412,2.2165 Z M16.3672,19.6925 C16.3672,19.8995 16.1832,20.0845 15.9752,20.0845 L3.8782,20.0845 C3.6702,20.0845 3.4862,19.8995 3.4862,19.6925 L3.4862,5.4485 L16.3912,5.4485 L16.3912,19.6925 L16.3672,19.6925 Z" id="Fill-7"></path>
            </g>
        </svg>
    );
};

/*xviewBox="0 0 20 30"  transform="translate(8.000000, 7.000000)"*/