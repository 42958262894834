import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProduct } from '../../domains/Product';

export type CartState = {
    items: IProduct[]
}

export const initialState: CartState = {
    items: [],
}

// Redux slice
export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        addProduct: (state, action: PayloadAction<IProduct>) => {
            state.items.push(action.payload);
        },
        removeProduct: (state, action: PayloadAction<number>) =>{
            state.items.splice(action.payload, 1);
        }, 
        reset: (state) => {
            state.items = [];
        }
    },
});


//export Redux action creators
export const {addProduct, removeProduct, reset} = shopSlice.actions;
//export Redux selectors
export const selectCartItems = (state: { items: any[]; }) => state.items;
