export const SAMPLE_EXAM_PATH = "/public/Test-Lernstandserfassung.pdf";
export const SAMPLE_WORKPLAN_PATH = "/public/Test-Arbeitsplan.pdf";
export const DIDACTO_VIDEO_PATH = "/public/didacto-video-allgemein_v3.mp4";
export const MIN_RECAPTCHA_SCORE = 0.6;
export const MOBILE_BREAKPOINT = 800;
export const GCP_REGION_CH = "europe-west6";
export const LOCALHOST = "localhost";
export const MIN_PASSWORD_LENGTH = 12;

export const COLORS = {
    DIDACTO_GREY: "#dae0e0",
    DIDACTO_RED: "#B14E79",
    DIDACTO_DARK_RED: "#994469",
    DIDACTO_LIGHT_RED: "#E8CAD7",
    DIDACTO_LIGHT_GREY: "#FCFDFC",
    DIDACTO_DARK_GREY: "#EBEEF2",
    DIDACTO_BLUE: "#426190",
    DIDACTO_DARK_BLUE: "#33507d",
    DIDACTO_WHITE: "#ffffff",
    DIDACTO_BLACK: "#000000",
};

export const NAVBAR_HEIGHT = "9rem";
export const NAVBAR_HEIGHT_IN_REM = 9;

export const COLLECTIONS = {
    feedback: "feedback",
    users: "users",
    mail: "mail",
    newsletterSubs: "newsletterSubscriptions",
    orders: "orders",
    products: "products",
} as const;

const PASSWORD_FORGET = "/pw-forget";
const SHOP = "/shop";
const CART = "/cart";
const DATA_PRIVACY = "/data-privacy"
const LANDING = "/";
const NEWSLETTER = "/newsletter";

export const PUBLIC_ROUTES = {
    SHOP,
    CART,
    PASSWORD_FORGET, 
    DATA_PRIVACY,
    LANDING,
    NEWSLETTER,
};


const HOME = "/home";
const ACCOUNT = "/account";
const ADMIN = "/admin";
const SETUP = "/setup";
const MANAGE = "/manage";
const DATA = "/data"; 
const WORK_PLAN = "/wp";

export const PRIVATE_ROUTES = {
    HOME,
    ACCOUNT,
    ADMIN,
    SETUP,
    MANAGE,
    DATA,
    WORK_PLAN,
}

// Protected routes
const SIGN_IN = "/signin";
const SIGN_UP = "/signup";
export const PROTECTED_ROUTES = {
    SIGN_UP,
    SIGN_IN,
}
