import * as S from './ShopCardStyles';
import { DidactoColor } from '../../types/App';
import { AddToCartButton } from '../Buttons';

export type ShopCardProps = {
    id: string,
    title: string,
    facts: string[],
    price: number,
    image: string,
    tag: string,
    instruction: string,
    backgroundColor?: DidactoColor,
    addToCart: () => void,
}

export const ShopCard = ({
    id,
    title,
    facts,
    price,
    tag,
    instruction,
    image,
    backgroundColor,
    addToCart,
}: ShopCardProps) => {
    return (
        <S.ShopCardBox id={id} backgroundColor={backgroundColor} >
            <S.ShopCardInnerBox>
                <S.PackageTypeTag>{tag}</S.PackageTypeTag>
                <S.Image src={image} />
                <S.ProductTitle>{title}</S.ProductTitle>
                <S.ProductDescription>
                    <S.Paragraph>Dieses Package beinhaltet:</S.Paragraph>
                    <S.List>
                        {facts.map((fact, i) => {
                            return <S.ListItem key={i}>{fact}</S.ListItem>
                        })}
                    </S.List>
                    <S.Paragraph>{instruction}</S.Paragraph>
                </S.ProductDescription>
                <S.Footer>
                    <S.PriceTag>
                        <S.Currency>CHF</S.Currency>
                        <S.Price>{price}.-</S.Price>
                    </S.PriceTag>
                    <AddToCartButton text="in den Warenkorb" onClick={addToCart} />
                </S.Footer>
            </S.ShopCardInnerBox>
        </S.ShopCardBox>
    )
};

export const PriceTag = ({ price }: { price: number }) => {
    return (
        <>
            <span>CHF </span>
            <span>{price}.-</span>
        </>
    )
}