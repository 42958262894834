import { createTheme } from "@mui/material";
import { COLORS } from "../../constants";

const theme = createTheme({
    typography: {
        fontFamily: "TisaSansPro",
        h1: {
            
        },
        h2: {
            fontFamily: "TisaSansPro-Medium",
            
        },
        h3: {
            marginTop: "12px",
            fontFamily: "TisaSansPro-Medium",

        },
        // Toolbar title
        h6: {
            fontFamily: "TisaPro-Bold",
            fontSize: "36px"
        },
        subtitle1: {
            fontFamily: "TisaSansPro-Medium",
            fontSize: "16px",
            paddingLeft: "16px !important"
        },
        body1: {
            fontSize: "16px",
        }
    },
    palette: {
        primary: {
            main: COLORS.DIDACTO_BLUE,
            contrastText: COLORS.DIDACTO_WHITE,
            dark: COLORS.DIDACTO_DARK_BLUE,
        },
        secondary: {
            main: COLORS.DIDACTO_RED,
            dark: COLORS.DIDACTO_DARK_RED,
            contrastText: COLORS.DIDACTO_WHITE
        },
        error: {
            main: "#cc0000"
        }
    },
    components: {
        MuiPaper:  {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                elevation4: {
                    boxShadow: "none",
                    borderRadius: "4px",
                }
            }
        },
        MuiTable: {
          styleOverrides: {
            root: {
              position: "relative",
              width: "100%",
                  '& tfoot': {
                      '& div': {
                          fontSize: "14px",
                      },
                      "& tr > td": {
                          paddingLeft: 0,
                          paddingRight: 0,
                      },
                      '& p': {
                          fontSize: "14px",
                      },
                      '& .MuiTablePagination-selectLabel + div': {
                          display: "flex",
                          alignItems: "center",
                          "& > svg": {
                              top: "2px",
                              right: 0,
                          }
                      },
                      "& .MuiTablePagination-actions": {
                      }
              }
            }
          }
        },
        MuiTableHead: {
            styleOverrides: {
              root: {
                '& button': {
                    fontSize: "16px",
                    fontFamily: "TisaSansPro-Bold"
                }
              }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "14px",
                    backgroundColor: COLORS.DIDACTO_BLUE,
                } 
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: "2Opx",
                    fontFamily: "TisaSansPro-Medium"
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {

            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "14px",
                    fontFamily: "TisaSansPro-Medium"
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
              root: {
                padding: '0px !important',
                '& .MuiButtonBase-root:hover .MuiSvgIcon-root': {
                  color: `${COLORS.DIDACTO_DARK_BLUE} !important`,
                },
                '& .MuiSvgIcon-root': {
                  width: "24px",
                  height: "24px",
                },
                '& > div:last-child': {
                  display: 'none',
                  '@media (min-width:600px)': {
                    display: 'block', // Adjust the media query as needed
                  }
                }
              }
            }
        }
        
    }
});
export {
    theme
}