import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FolderIcon from '@mui/icons-material/Folder';

const AddSpeedDial = ({hasValidGrades, addWorkPlan, addFolder}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Box sx={{position: "absolute", right: "0", bottom: "90px"}}>
      <SpeedDial
        ariaLabel="AddSpeedDial"
        icon={<SpeedDialIcon sx={{height: '15px'}} />}
        onClick={() => setIsOpen(!isOpen)}
        open={isOpen}
      >
        {hasValidGrades ?
            (
                  <SpeedDialAction
                    key={'workplan'}
                    icon={<ListAltIcon />}
                    tooltipTitle={'Arbeitsplan'}
                    tooltipOpen={true}
                    onClick={addWorkPlan}
                  />
            ) : ''
        }
        <SpeedDialAction
          key={'folder'}
          icon={<FolderIcon />}
          tooltipTitle={'Ordner'}
          tooltipOpen={true}
          onClick={addFolder}
        />
      </SpeedDial>
    </Box>
  );
}

export default AddSpeedDial;
