export const TYPES = {
    TOPICS_UPDATED: 'topicsUpdated',
    WP_ACCESS_UPDATED: 'wpAccessUpdated',
    WORK_PLAN_LOADED: 'workplanLoaded',
    WORKPLAN_LOADING: 'workplanLoading',
    PATHS_LOADING: 'pathsLoading',
    PATHS_UPDATED: 'pathsUpdated'
  };

export const INITIAL_STATE = {
  topics: {},
  wpAccess: null,
  currentWorkPlan: null,
  workPlanLoading: false,
  paths: null
}

const workPlanReducer = (state=INITIAL_STATE, action) => {
  switch(action.type){
    case TYPES.TOPICS_UPDATED:
      return applyTopicsUpdated(state, action);

    case TYPES.WP_ACCESS_UPDATED:
      return applyWpAccessUpdated(state, action);

    case TYPES.WORK_PLAN_LOADED:
        return applyWorkplanLoaded(state, action);

    case TYPES.WORKPLAN_LOADING:
        return applyWorkplanLoading(state, action);

    case TYPES.PATHS_UPDATED:
      return applyPathsUpdated(state, action);

    case TYPES.PATHS_LOADING:
      return applyPathsLoading(state, action);

    case TYPES.INIT_STATE:
      return applyInitState(state, action);

    default:
      return state;
  }
}

const applyTopicsUpdated = (state, action) => {
  return {...state, topics: action.payload};
}

const applyWpAccessUpdated = (state, action) => {
  return {...state, wpAccess: action.payload};
}

const applyPathsUpdated = (state, action) => {
  return {...state, paths: action.payload};
}

const applyWorkplanLoaded = (state, action) => {
  return {...state, currentWorkPlan: action.payload};
}

const applyPathsLoading = (state, action) => {
  return {...state, pathsLoading: action.payload};
}

const applyWorkplanLoading = (state, action) => {
  return {...state, workPlanLoading: action.payload};
}

const applyInitState = (state, action) => {
  return {...INITIAL_STATE};
}

export default workPlanReducer;
