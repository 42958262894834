import avatarSackmesser from './assets/icon-sackmesser.svg';
import avatarSeil from './assets/icon-seil.svg';
import avatarZelt from './assets/icon-zelt.svg';
import avatarKarte from './assets/icon-karte.svg';

// Front/Parallax
export const TITLE = "Mathematik";
export const SLOGAN_FRAGMENT = <>kompetenzorientiert beurteilen<br />und individuell fördern</>;
export const LEAD_FRAGMENT = <>Das Lernfördersystem <span className="emphasized">didacto</span>&nbsp;
                                unterstützt dich als Lehrperson, deine Schülerinnen und Schüler auf
                                jeder Lernetappe zum Gipfel zu begleiten.&nbsp;
    <span className="emphasized">didacto</span> ist auf das Lehrmittel
                                Mathematik des Kantons Zürich abgestimmt.</>;
export const STOERER_FRAGMENT = <>Neu auch für die 3. Klasse</>;

// Didacto/About
export const DOWNLOAD_TEST_PDF = "Download Test-PDF";
export const LEAD_BUTTON = "zum Shop";
export const LERNSTANDSERFASSUNG_TEASER = "Für jede Kompetenz stehen dir zwei Lernstandserfassungen zur " +
    "Verfügung. Diese können zum Überprüfen, Aufarbeiten, Üben oder " +
    " Differenzieren verwendet werden.";
export const ARBEITSPLAENE_TEASER = "Die Aufgaben aus Themenbuch, Arbeitsheften und Zusatzblättern " +
    "sind den zugrunde liegenden Kompetenzen zugeordnet und in " +
    "drei Niveaus eingeteilt.";
export const LERNSTANDSUEBERSICHT_TEASER = "Alle Kompetenzen des Schuljahres werden hier zusammengefasst. " +
    "Die Resultate der Schülerinnen und Schüler können übersichtlich " +
    "dargestellt und u.a. als Grundlage für Elterngespräche verwendet " +
    "werden.";
export const LEHRPLANBEZUEGE_TEASER = "Auf welchen Kompetenzen des Lehrplans 21 die Themen des Lehrmittels " +
    "basieren, erkennst du hier. Zudem siehst du auf einen Blick, welchem " +
    " Zyklus die Kompetenz angehört.";

// Team
export const TEAM = "Unser Feuer brennt für didacto. Wir sind unterschiedlichste " +
    "Köpfe, aber haben ein Ziel: Lehrpersonen zu unterstützen, " +
    "ihre Schülerinnen und Schüler individuell zu fördern und " +
    "kompetenzorientiert zu beurteilen.";
export const TOBIAS = "Wenn's prekär wird, führt Tobias unsere Seilschaft an und bringt uns sicher über Kluften, " +
    "Steilhänge und Felsvorsprünge.";
export const MARTIN = "Martin ist unser Routenplaner. Stets mit dem Ziel vor Augen und hochmotiviert dieses zu " +
    "erreichen, scheut er keine Unwegsamkeiten."
export const BETTINA = "Bettina schnitzt die nötigen Werkzeuge für den Weg zum Gipfel. Dabei dürfen auch die " +
    "Cervelat-Beinchen nicht fehlen, denn das Auge isst schliesslich mit.";
export const LUCA = "Manchmal können die Tage länger werden. Dann sorgt Luca für den nötigen Unterschlupf zum " +
    "Nächtigen, damit es am nächsten Tag erholt weitergehen kann.";
export const NAMES = ['Tobias Ringli', 'Martin Inglin', 'Bettina Baumann', 'Luca Egli'];
export const JOBS = ['Primarschullehrer', 'Primarschullehrer / Software Developer', 'Grafikdesignerin', 'CTO'];
export const CONTACT_FRAGMENT = <>Hast du Lob, Kritik oder eine Frage an didacto? Hinterlasse uns hier deine E-Mail-Adresse. Wir freuen uns über deine Mitteilung.</>;

export const TEAM_PERSONAS = [TOBIAS, MARTIN, BETTINA, LUCA];
export const TEAM_AVATARS = [avatarSeil, avatarKarte, avatarSackmesser, avatarZelt]

// Contact Form
export const INPUT_VALIDATION_ERRORS = {
    invalidEmail: 'Bitte gültige Email Adresse angeben.',
    invalidName: 'Bitte Namen angeben.',
}

export const WANTS_NEWSLETTER = <>didacto darf mir Benachrichtigungen senden.</>;
export const SUBMIT_FEEDBACK = "senden";
export const THANK_YOU_MESSAGE = "Danke für dein Feedback!";

// Footer
export const DIDACTO_PRODUCT_DISCLAIMER_FRAGMENT = <><span className='emphasized'>didacto</span> ist ein Produkt der hoch5 GmbH.</>;
export const DIDACTO_ADDRESS_FRAGMENT = <>hoch5 GmbH / Martin Inglin<br />Hinterhubstrasse 4<br />8413 Neftenbach<br /></>;
export const HANDELSREGISTER_EINTRAG = 'CHE-330.424.412 (Handelsregister des Kantons Zürich)';
export const IMPRESSUM = 'Impressum';
export const DATA_PRIVACY = 'Datenschutz';

//Data Privacy Page:
export const DATA_PRIVACY_PERSONAL_DATA = <p>Bei einem Besuch dieser Website erheben wir keine personenbezogenen Daten von Ihnen.
Personenbezogene Daten werden nur erhoben, wenn Sie uns diese selbst über unser Kontaktformular mitteilen.
Diese Daten werden ausschliesslich zur Beantwortung Ihres Anliegens genutzt und danach wieder gelöscht.
Falls Sie uns im Kontaktformular die Einwilligung gegeben haben, über Neuigkeiten zu didacto beanachrichtigt zu werden,
    bleiben die im Kontaktformular gemachten Angaben gespeichert.</p>

export const DATA_PRIVACY_HOSTING = <>
    <p style={{marginBottom: 0}}> Zur Bereitstellung der Website von didacto und den dazugehörigen Webservices verwenden wir Produkte von <a href="https://firebase.google.com/">Google Firebase</a>:</p>
    <ol>
        <li>Webhosting: Firebase Hosting</li>
        <li>Datenbank zur Speicherung der über das Kontaktformular aufgenommenen Daten: Cloud Firestore</li>
        <li>Versand von Emails: Integration des Google Workspace Accounts (info@didacto.ch) über Cloud Firestore</li>
        <li>API Endpoints zur Verifizierung von Recaptcha Tokens und Newsletter-Abbestellung: Cloud Functions for Firebase</li>
        <li>Speicherplatz für Dokumente, die auf der Website abgerufen werden können: Cloud Storage for Firebase</li>
    </ol>
    <p>Die Server für die genannten Webservices liegen, soweit dies von uns konfiguriert werden kann, in der Google Cloud Region europe-west6 (Zurich).</p>
    <p style={{marginBottom: 0}}>Für Firebase Hosting gelten folgende Nutzungsbedingungen:</p>
    <ul style={{ listStyleType: "initial" }}>
        <li><a href="https://developers.google.com/terms/">Google APIs Terms of Service</a></li>
        <li><a href="https://firebase.google.com/terms/data-processing-terms">Firebase Data Processing and Security Terms</a></li>
    </ul>
    <p style={{marginBottom: 0}}>Für Cloud Firestore, Cloud Functions for Firebase, Cloud Storage for Firebase  gelten folgende Nutzungsbedingungen:</p>
    <ul style={{ listStyleType: "initial" }}>
        <li>
            <a href="https://cloud.google.com/terms/">Google Cloud Platform Terms of Service</a>
        </li>
    </ul>
</>;

export const DATA_PRIVACY_ANALYTICS = <p>Google Analytics wird auf dieser Website nicht verwendet. Bei der Verwendung von Firebase für
                                            Hosting und Webservices wurde Google Analytics explizit deaktiviert.</p>

export const DATA_PRIVACY_RECAPTCHA = <>
    <p> Wir setzen Google reCAPTCHA zur Überprüfung und Vermeidung von automatiserten Interaktionen ein (Kontaktformular, Anmeldung).
    Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse stattfindet.
    Fällt die von Google reCAPTCHA ermittelte Punktzahl unter 0.6, werden Kontaktformular und die Anmeldungsseite nicht mehr angezeigt.
    </p>
    <p style={{marginBottom: 0}}>Für Google reCAPTCHA gelten folgende Nutzungsbedingungen und Datenschutzerklärung:</p>
    <ul style={{ listStyleType: "initial" }}>
        <li>
            <a href="https://policies.google.com/privacy?hl=de">reCaptcha Datenschutzerklärung</a>
        </li>
        <li>
            <a href="https://policies.google.com/terms?hl=de">reCaptcha Nutzungsbedingungen</a>.
        </li>
    </ul>
</>;

export const DATA_PRIVACY_CONTACT_FORM = <p>Wenn Sie beim Absenden des Kontaktformulars über die entsprechende Checkbox einwilligen, dass wir sie über Neuigkeiten zu didacto informieren dürfen,
                                            speichern und nutzen wir Ihre E-Mail-Adresse für die Übersendung unseres Newsletters mit Informationen und Werbung über uns, bis Sie sich von unserem Newsletter abgemeldet haben. 
                                            Die Abmeldung vom Newsletter erfolgt über den Link in der vom Kontaktformular generierten Bestätigungs-Email.</p>;

export const DATA_PRIVACY_COOKIES = <p>Beim Besuch von didacto.ch werden von uns keine eigenen Cookies gesetzt. Die Cookies, die gesetzt werden,
ergeben sich aus aus der Nutzung von Google reCAPTCHA und den Services von Google Firebase, deren Nutzungsbedingungen
                                        bereits unter 2. und 4. angeführt wurden.</p>;

export const DATA_PRIVACY_RIGHTS = <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf
und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstösst oder
Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der
                                            Aufsichtsbehörde beschweren.</p>;

// Sign Up Form
export const USE_SCHOOL_EMAIL_INFO = 
    "Bitte verwende deine offizielle Schul-Email-Adresse, damit wir prüfen können, " +
    "ob du berechtigt bist, dieses Produkt zu kaufen. Unsere Produkte enthalten " + 
    "Lernkontrollen und dürfen nur von Lehrpersonen und Schulen bestellt werden."; 