import * as C from '../constants';
import { COLORS } from '../constants';

// Public routes
export type PublicRoute = keyof typeof C.PUBLIC_ROUTES;
export const isPublicRoute = (path: string): path is PublicRoute => {
    return Object.values(C.PUBLIC_ROUTES).includes(path);
}

export type ProtectedRoute = keyof typeof C.PROTECTED_ROUTES;
export const isProtectedRoute = (path: string): path is ProtectedRoute => {
    return Object.values(C.PROTECTED_ROUTES).includes(path);
}


export type PrivateRoute = keyof typeof C.PRIVATE_ROUTES;
export const isPrivateRoute = (path: string): path is PrivateRoute => {
    return Object.values(C.PRIVATE_ROUTES).includes(path);
}

export const isRoute = (path: string): boolean => {
    return isProtectedRoute(path) ||
        isPublicRoute(path) ||
        isPrivateRoute(path);
}

// utility types
export type WithChildren<T = {}> =
    T & { children?: React.ReactNode };

export type RequireAtLeastOne<T> = { [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>; }[keyof T]

// union type of valid Didacto hexcolors
const vals: string[] = Object.values(COLORS);
const colors = [...vals] as const;
export type DidactoColor = typeof colors[number];

// valid grades
const VALID_GRADES = [3,4,5,6];
export type Grade = 3 | 4 | 5 | 6;

export const isGrade = (grade: any): grade is Grade => {
    return VALID_GRADES.includes(grade);
}

export type FileDescription = {
    path: string,
    name: string,
    filename: string,
}

export type EmailAndPassword = {
    email: string,
    password: string,
}
