import React from 'react';
import { useIsMobile } from '../../hooks';

const LogoWithoutIcon = () => {
    return (
        <g>
            <path d="M123.7,183.1h-11.5v-51.3h11.5V183.1z"/>
            <circle cx="118" cy="116" r="7.6"/>
            <path d="M158.1,130.9c-14.7,0-26.7,12-26.7,26.7s12,26.7,26.7,26.7c14.7,0,26.7-12,26.7-26.7v-26.7H158.1z M173.3,157.6
                c0,8.4-6.8,15.2-15.2,15.2c-8.4,0-15.2-6.8-15.2-15.2c0-8.4,6.8-15.2,15.2-15.2h15.2V157.6z"/>
            <path d="M76.2,130.9c-14.7,0-26.7,12-26.7,26.7s12,26.7,26.7,26.7s26.7-12,26.7-26.7v-26.7H76.2z M91.5,157.6
                c0,8.4-6.8,15.2-15.2,15.2S61,166,61,157.6c0-8.4,6.8-15.2,15.2-15.2h15.2V157.6z"/>
            <path d="M216.8,183.3c-16.2,0-24.9-13.4-24.9-26c0-12.6,8.7-26,24.9-26c4.9,0,10.1,1.5,14.7,4.2l0.6,0.4v-3.5h12.2v50.2h-12.2v-4.1
                l-0.6,0.4C227.1,181.8,222,183.3,216.8,183.3z M219.1,142.4c-10.1,0-15.5,7.7-15.5,15c0,7.3,5.4,15,15.5,15c4.9,0,9.5-1.6,12.8-4.4
                l0.1-0.1V147l-0.1-0.1C228.6,144,223.9,142.4,219.1,142.4z"/>
            <path d="M279.4,183.3c-15.1,0-27.5-11.6-27.5-25.9c0-14.3,12.3-26,27.5-26c7.6,0,14.6,3.5,19.4,9.7l-7.8,7.2c-2.7-3.8-7-6-11.5-6
                c-8.5,0-15.4,6.7-15.4,15c0,8.2,6.9,15,15.4,15c4.6,0,8.8-2.3,11.5-6.1l7.7,7.2C293.9,179.6,286.8,183.3,279.4,183.3z"/>
            <path d="M325.1,183.3c-7.2,0-15-4.1-15-15.6v-25.3h-7.2v-10h7.2v-19.8h12.3v19.8h14.3v10h-14.3v18.4c0,9.5,1.8,12.1,8.5,12.1
                c1.9,0,3.5-0.2,5-0.4c0.2,0,0.4,0,0.6-0.1v9C332.5,183,328.1,183.3,325.1,183.3z"/>
            <path d="M366.2,130.8c15,0,26.7,11.6,26.7,26.7c0,15-11.7,26.8-26.7,26.8c-15.1,0-26.7-11.8-26.7-26.8
                C339.5,142.3,351.1,130.8,366.2,130.8z M366.2,173c8.7,0,15-7,15-15.5c0-8.6-6.4-15.5-15-15.5c-8.6,0-15.1,7-15.1,15.5
        C351.1,166,357.7,173,366.2,173z"/>
        </g>
    )
}

const Icon = () => {
    return (
        <g>
            <path d="M458.2,88.6c-29.5,0-53.6,24-53.6,53.6l0,53.6l53.6,0c29.5,0,53.6-24,53.6-53.6C511.7,112.7,487.7,88.6,458.2,88.6z
                    M469.6,157.4c0,14.7-12,26.7-26.7,26.7s-26.7-12-26.7-26.7s12-26.7,26.7-26.7h26.7V157.4z M484.7,123.4c-4.2,0-7.6-3.4-7.6-7.6
                    s3.4-7.6,7.6-7.6c4.2,0,7.6,3.4,7.6,7.6S488.9,123.4,484.7,123.4z"/>
            <path d="M427.7,157.4c0,8.4,6.8,15.2,15.2,15.2s15.2-6.8,15.2-15.2v-15.2H443C434.6,142.2,427.7,149,427.7,157.4z"/>
        </g>
    )
}

type LogoProps = {
    style?: React.CSSProperties
    collapseToIcon?: boolean
}

export const Logo = ({style, collapseToIcon=true}: LogoProps) => {
    const viewBoxSmall = "355 0 125 125";
    const viewBoxLarge = "0 0 480 125";
    const isMobile = useIsMobile();
    let viewBox = viewBoxLarge;
    if (collapseToIcon && isMobile){
        viewBox = viewBoxSmall;

    }
    return (
        <svg style={{...style, cursor: "pointer"}} version="1.1" id="Ebene_1" x="0px" y="0px" viewBox={viewBox}>
            <g transform="translate(-40, -80)">
                <LogoWithoutIcon />
                <Icon />
            </g>
        </svg>
    )
};
