import { styled } from '@mui/material/styles';

type FormProps = {
    maxWidth?: string;
    width?: string;
};

export const Form = styled('form')<FormProps>(({ theme, maxWidth = 'initial', width = '100%' }) => ({
    width: width,
    maxWidth: maxWidth,
    [theme.breakpoints.down('sm')]: { // 'sm' typically corresponds to 600px, adjust as needed
        width: '100% !important',
    }
}));
