import { styled } from '@mui/material/styles';
import { COLORS, NAVBAR_HEIGHT } from '../../constants';
import { DidactoColor } from '../../types/App';

type SectionProps = {
    paddingTop?: string,
    alignItems?: string,
    backgroundColor?: string,
}

export const Section = styled('section', {
    shouldForwardProp: (prop) =>
        prop !== 'paddingTop' && prop !== 'alignItems' && prop !== 'backgroundColor',
})<SectionProps>(({ paddingTop, alignItems, backgroundColor }) => ({
    paddingTop: paddingTop ?? "0",
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignItems ?? "stretch",
    backgroundColor: backgroundColor ?? COLORS.DIDACTO_WHITE,
}));

type ParallaxSectionProps = {
    backgroundImage: string,
    minHeight?: string,
    backgroundSize?: string,
    paddingTop?: string,
}

export const ParallaxSection = styled(Section, {
    shouldForwardProp: (prop) =>
        prop !== 'backgroundImage' && prop !== 'minHeight' && prop !== 'backgroundSize' && prop !== 'paddingTop',
})<ParallaxSectionProps>(({ backgroundImage, minHeight, backgroundSize, paddingTop }) => ({
    minHeight: minHeight ?? "100vh",
    paddingTop: paddingTop ?? "2rem",
    backgroundImage: `url(${backgroundImage})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: backgroundSize ?? "cover",
    '@media (max-width: 800px)': {
        backgroundAttachment: 'scroll',
    },
}));

type SectionContentBoxProps = {
    marginBottom?: string,
    alignItems?: string,
    justifyContent?: string,
    backgroundColor?: DidactoColor,
    width?: string,
}

/* 
 * Vertically stack section content in a horizontally centered flexbox. 
 * Add margin-bottom to provide a neutral background for navbar.
 * Use alignItems for horizontal and justifyContent for vertical alignment of content.
 **/
export const SectionContentBox = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'marginBottom' && prop !== 'alignItems' && prop !== 'justifyContent' && prop !== 'backgroundColor' && prop !== 'width',
})<SectionContentBoxProps>(({ marginBottom, alignItems, justifyContent, backgroundColor, width }) => ({
    width: width ?? "min(90vw, 840px)",
    flexGrow: 1,
    margin: "0 auto",
    display: 'flex',
    alignItems: alignItems ?? "center",
    justifyContent: justifyContent ?? "flex-start",
    flexDirection: 'column',
    marginBottom: marginBottom ?? NAVBAR_HEIGHT,
    backgroundColor: backgroundColor ?? "transparent",
}));

export const SectionTitle = styled('h2')`
    margin-top: 5rem;
    font-size: 2.8rem;
    text-align: center;
`;

export const SubSectionTitle = styled('h3')`
    font-family: TisaSansPro;
    font-size: 1.7rem;
    letter-spacing: 1px;
    margin: 1.7rem 0 0 0;
`;

type SubSectionProps = {
    textAlign?: React.CSSProperties['textAlign'],
    color?: DidactoColor,
    margin?: string,
};

export const SubSection = styled('div', {
    shouldForwardProp: (prop) => prop !== 'textAlign' && prop !== 'color' && prop !== 'margin',
})<SubSectionProps>(({ textAlign, color, margin }) => ({
    fontFamily: 'TisaSansPro',
    fontSize: '1.7rem',
    margin: margin ?? "0.5rem 0",
    textAlign: textAlign ?? "justify",
    color: color ?? "inherit",
}));

type SectionParagraphProps = {
    padding?: string,
    fontSize?: string,
    textAlign?: React.CSSProperties['textAlign']
}

export const SectionParagraph = styled('p', {
    shouldForwardProp: (prop) => prop !== 'padding' && prop !== 'fontSize' && prop !== 'textAlign',
})<SectionParagraphProps>(({ padding, fontSize, textAlign }) => ({
    padding: padding ?? "0 1rem",
    fontFamily: 'TisaSansPro',
    fontSize: fontSize ?? "1.7rem",
    textAlign: textAlign ?? "center",
    maxWidth: '600px',
}));

type ImageHeaderProps = {
    maxHeight?: string,
}

export const ImageHeader = styled('img', {
    shouldForwardProp: (prop) => prop !== 'maxHeight',
})<ImageHeaderProps>(({ maxHeight }) => ({
    position: 'sticky',
    top: 0,
    width: '100%',
    objectFit: 'cover',
    maxHeight: maxHeight ?? "auto",
    zIndex: -1,
}));
