import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants';
import { DidactoColor } from '../../types/App';

type ShopCardBoxProps = {
    backgroundColor?: DidactoColor,
};

export const ShopCardBox = styled('div')<ShopCardBoxProps>(({ theme, backgroundColor = COLORS.DIDACTO_GREY }) => ({
    backgroundColor: backgroundColor,
    minWidth: '350px',
    width: '25%',
    padding: '2rem',
    backgroundClip: 'content-box',
    position: 'relative',
    [theme.breakpoints.down('lg')]: { // 1200px breakpoint in MUI by default for 'lg'
        width: '50%',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'center',
        padding: '1rem',
    },
    [theme.breakpoints.down('sm')]: { // 800px breakpoint in MUI by default for 'sm'
        width: '100%',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'center',
        padding: '1rem',
    },
}));

export const ShopCardInnerBox = styled('div')({
    position: 'relative',
    padding: '5rem 2rem 2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

export const PackageTypeTag = styled('div')({
    backgroundColor: COLORS.DIDACTO_DARK_BLUE,
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '0 0 1.6rem 0',
    fontSize: '1.8rem',
    color: 'white',
    fontFamily: 'TisaSansPro-Bold',
    padding: '0.4rem 1.5rem 0.4rem 1.5rem',
});

export const Image = styled('img')({
    marginBottom: '2rem',
});

export const ProductTitle = styled('h2')(({ theme }) => ({
    margin: '0 0 1rem 0',
    fontFamily: 'TisaPro-Light',
    fontSize: '3rem',
    [theme.breakpoints.down('xs')]: { // 400px breakpoint in MUI by default for 'xs'
        fontSize: '2.5rem',
        fontFamily: 'TisaPro',
    },
}));

export const ProductDescription = styled('div')(({ theme }) => ({
    fontFamily: 'TisaSansPro',
    fontSize: '2rem',
    [theme.breakpoints.down('xs')]: {
        fontSize: '1.6rem',
    },
}));

export const List = styled('ul')(({ theme }) => ({
    listStyleType: 'none',
    fontSize: '1.8rem',
    margin: 0,
    padding: 0,
    fontFamily: 'TisaSansPro-Light',
    [theme.breakpoints.down('xs')]: {
        fontSize: '1.6rem',
    },
}));

export const ListItem = styled('li')({
    '&:before': {
        content: '"– "',
    },
});

export const Paragraph = styled('p')(({ theme }) => ({
    fontSize: '1.8rem',
    margin: '1rem 0 0 0',
    fontFamily: 'TisaSansPro-Light',
    [theme.breakpoints.down('xs')]: {
        fontSize: '1.6rem',
    },
}));

export const Footer = styled('div')(({ theme }) => ({
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'auto',
    paddingTop: '2rem',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

export const PriceTag = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
        marginBottom: '1rem',
    },
}));

export const Currency = styled('div')({
    fontSize: '1.8rem',
    paddingRight: '0.4rem',
    fontFamily: 'TisaSansPro-Bold',
});

export const Price = styled('div')({
    fontSize: '3rem',
    fontFamily: 'TisaSansPro-Medium',
    marginLeft: '2px',
});
