import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants';
import { DidactoColor } from '../../types/App';
import infoIcon from "./assets/info.svg";
import infoIconActive from "./assets/info-active.svg";
import infoIconHover from "./assets/info-hover.svg";

type InputWrapperProps = {
    width?: string,
    margin?: string,
}

export const InputWrapper = styled('div')<InputWrapperProps>(({ width = 'fit-content', margin = '0' }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: width,
    position: 'relative',
    margin: margin,
}));

type InputProps = {
    secondaryColor: DidactoColor,
    insetShadow: boolean,
}

export const Input = styled('input')<InputProps>(({ theme, secondaryColor, insetShadow }) => ({
    outline: 0,
    fontSize: '16px', // to avoid mobile zoom
    width: '100%',
    margin: 0,
    padding: 0,
    ...(insetShadow && { border: `2px solid green !important`,
                         boxShadow: `inset 0 0 3px 3px ${COLORS.DIDACTO_GREY}`,
                         background: 'transparent'}),
    '&:active, &:focus': {
        border: `1px solid ${secondaryColor ?? COLORS.DIDACTO_DARK_RED}`,
    },
    '&::-webkit-input-placeholder': { color: '#5B7398' },
    '&::-moz-placeholder': { color: '#5B7398' },
    '&:-ms-input-placeholder': { color: '#5B7398' },
    '&:-moz-placeholder': { color: '#5B7398' },
}));

type InputValidationErrorProps = {
    secondaryColor: DidactoColor,
}

export const InputValidationError = styled('span')<InputValidationErrorProps>(({ secondaryColor }) => ({
    marginTop: '-0.8rem',
    marginLeft: '0.8rem',
    color: secondaryColor,
    fontSize: '1.2rem',
}));

type LabelProps = {
    secondaryColor: DidactoColor,
}

export const Label = styled('label')<LabelProps>(({ secondaryColor }) => ({
    height: '1.4rem',
    margin: '0.4rem 0 0 0',
    padding: 0,
    position: 'relative', // Fixed to relative transition
    left: '0.5rem',
    fontSize: '1rem',
    fontFamily: 'TisaSansPro-Light',
    color: secondaryColor,
    width: '100%',
}));

export const MultiInputBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: { // 800px breakpoint
        display: 'block',
        '& > *': {
            width: '100%',
            margin: 0,
        }
    },
}));

export const InputInfoDisplay = styled('span')(({ theme }) => ({
    position: 'absolute',
    top: '0px',
    right: '-4rem',
    backgroundImage: `url(${infoIcon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '4rem',
    '&:hover': {
        backgroundImage: `url(${infoIconHover})`,
    },
    '&:active': {
        backgroundImage: `url(${infoIconActive})`,
    },
    [theme.breakpoints.down('sm')]: { // Adjusts for 800px
        right: '0rem',
    },
}));

export const InfoBox = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '-53%',
    top: '4rem',
    backgroundColor: 'white',
    zIndex: 10,
    boxShadow: `0 0 3px 0px ${COLORS.DIDACTO_DARK_BLUE}`,
    padding: '1rem',
    width: '50%',
    fontSize: '1.4rem',
    borderRadius: '1px',
    [theme.breakpoints.down('sm')]: { // 800px breakpoint
        right: '0',
        width: '100%',
    },
}));

export const InfoBoxTitle = styled('div')({
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: '0.4rem',
});
