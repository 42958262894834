import { useCallback, useContext, useReducer } from "react";
import { LandingPageRefContext, LandingPageRefs } from '../contexts/';
import { convertRemToPixels, scrollToTop } from '../utils';
import * as C from '../constants';

type LandingPageRefState = {
    didactoRef: HTMLDivElement | null,
    teamRef: HTMLDivElement | null,
    contactRef: HTMLDivElement | null,
};

const initialState: LandingPageRefState = {
    didactoRef: null,
    teamRef: null,
    contactRef: null,
};

type ACTION_TYPES = {
    DIDACTO_REF: "didactoRefUpdated",
    TEAM_REF: "teamRefUpdated",
    CONTACT_REF: "contactRefUpdated",
};

type LandingPageRefAction = {
    type: ACTION_TYPES[keyof ACTION_TYPES]
    ref: HTMLDivElement | null,
};

const reducer = (state: LandingPageRefState, action: LandingPageRefAction) => {
    switch (action.type) {
        case "didactoRefUpdated":
            return {...state, didactoRef: action.ref};
        case "teamRefUpdated":
            return {...state, teamRef: action.ref};
        case "contactRefUpdated":
            return {...state, contactRef: action.ref};
    }
};

type RefCallback = (node: HTMLDivElement|null) => void;

export const useLandingPageRefs = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {didactoRef, teamRef, contactRef} = state;
    const didactoRefCallback = useCallback<RefCallback>(node => {
        if (node !== null){
            dispatch({type:"didactoRefUpdated", ref:node});
        }
    }, []);
    const teamRefCallback = useCallback<RefCallback>(node => {
        if (node !== null){
            dispatch({type:"teamRefUpdated", ref:node});
        }
    }, []);
    const contactRefCallback = useCallback<RefCallback>(node => {
        if (node !== null){
            dispatch({type:"contactRefUpdated", ref:node});
        }
    }, []);

    return {
        scrollTo: {
            'didacto': scrollToElement(didactoRef),
            'team': scrollToElement(teamRef),
            'contact': scrollToElement(contactRef),
            'top': scrollToTop,
        },
        refCallbacks: {
            'didacto': didactoRefCallback,
            'team': teamRefCallback,
            'contact': contactRefCallback,
        }
    };
};


const scrollToElement = (el: HTMLDivElement|null) => {
    if (!el){
        return () => {};
    }
    return () => {
        const {top, left} = el.getBoundingClientRect();
        const offset = convertRemToPixels(C.NAVBAR_HEIGHT_IN_REM);
        window.scrollTo({
            top: top + window.pageYOffset - offset,
            left,
            behavior: 'smooth'
        })
    }
};

export const useScrollTo = () => {
    return useContext<LandingPageRefs>(LandingPageRefContext).scrollTo;
}

export const useRefCallbacks = () => {
    return useContext<LandingPageRefs>(LandingPageRefContext).refCallbacks;
}