import { getFunctions, connectFunctionsEmulator as _useFunctionsEmulator } from 'firebase/functions';
import { getFirestore, connectFirestoreEmulator as _useFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator as _useAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator as _useStorageEmulator } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import * as C from './constants';

export const options = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSENGER_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

declare global {
    interface Window {
        FIREBASE_APPCHECK_DEBUG_TOKEN: boolean;
    }
}
if (process.env.NODE_ENV !== 'production') {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }

const app = initializeApp(options);

if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY){
    throw new Error("Variable REACT_APP_RECAPTCHA_SITE_KEY not defined in .env.");

}
/*const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
    isTokenAutoRefreshEnabled: true
  });*/
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, C.GCP_REGION_CH);

/* TODO: Emulators currently not working --> Local pointing to didacto-d
if (process.env.NODE_ENV !== "production") {
    _useFirestoreEmulator(db, C.LOCALHOST , 8080);
    _useFunctionsEmulator(functions, C.LOCALHOST, 5001);
    _useAuthEmulator(auth, "http://localhost:9099");
    _useStorageEmulator(storage, C.LOCALHOST, 9199);
}*/

export default app;
export {db, auth, storage, functions};

