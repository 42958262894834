import { styled } from '@mui/material/styles';
import { DidactoColor } from "../../types/App";

type DownloadSectionProps = {
    backgroundColor: DidactoColor,
};

export const DownloadSection = styled('div')<DownloadSectionProps>(({ backgroundColor }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    backgroundColor: backgroundColor,
}));

export const ProductDownload = styled('li')({
    display: 'flex',
    width: '100%',
    maxWidth: '40rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
});