import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getAuth } from "firebase/auth";

import { ScrollToTop, PrivateRoute, ProtectedRoute } from "../Routes";
import * as C from "../../constants";

import { authChanged, authError, IUser } from "./appSlice";
import { initUserProfile, updateUserProfile, setSuccess, setError, setLoading } from "../../pages/CheckoutPage/userProfileSlice";
import { useLandingPageRefs } from "../../hooks";
import { LandingPageRefContext } from "../../contexts";
import { useDispatch, useSelector } from "react-redux";
import { SerializedError } from "@reduxjs/toolkit";

import { RootState } from "../..";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { WorkPlanOverview } from "../Workplan";
import Loading from "../Loading";

const NavBar = React.lazy(() => import("../NavBar"));
const LandingPage = React.lazy(() => import("../../pages/LandingPage"));
const SignInPage = React.lazy(() => import("../../pages/SignInPage"));
const AdminPage = React.lazy(() => import("../../pages/AdminPage"));
const HomePage = React.lazy(() => import("../../pages/HomePage"));
const WorkPlanPage = React.lazy(() => import("../../pages/WorkPlanPage"));
const DataPrivacyPage = React.lazy(() => import("../../pages/DataPrivacyPage"));
const ShopPage = React.lazy(() => import("../../pages/ShopPage"));
const CheckoutPage = React.lazy(() => import("../../pages/CheckoutPage"));
const NewsletterPage = React.lazy(() => import("../../pages/NewsletterPage"));
const PasswordResetPage = React.lazy(() => import("../../pages/PasswordResetPage"));

/**
 * App handles routing dependent on app state (auth, error)
 */
export const App = (): JSX.Element => {
    useSubscribeToAuthChanges();
    return (
        <React.Suspense fallback={<Loading />}>
            <BrowserRouter>
                <ScrollToTop>
                    <LandingPageRefContext.Provider value={useLandingPageRefs()}>
                        <NavBar />
                        <Routes>
                            <Route path={C.PRIVATE_ROUTES.HOME}
                                element={<PrivateRoute redirectTo={C.PUBLIC_ROUTES.LANDING}>
                                    <HomePage />
                                </PrivateRoute>} />
                            <Route path={C.PRIVATE_ROUTES.WORK_PLAN}
                                element={<PrivateRoute redirectTo={C.PUBLIC_ROUTES.LANDING}>
                                    <WorkPlanPage />
                                </PrivateRoute>} >
                                <Route index element={<WorkPlanOverview />} />
                                <Route path="*" element={<WorkPlanOverview />} />
                            </Route>
                            <Route path={C.PROTECTED_ROUTES.SIGN_IN}
                                element={<ProtectedRoute redirectTo={C.PRIVATE_ROUTES.HOME}>
                                    <SignInPage />
                                </ProtectedRoute>} />
                            <Route path={C.PRIVATE_ROUTES.ADMIN}
                                element={<PrivateRoute redirectTo={C.PUBLIC_ROUTES.LANDING}>
                                    <AdminPage />
                                </PrivateRoute>} />
                            <Route path={C.PUBLIC_ROUTES.DATA_PRIVACY} element={<DataPrivacyPage />} />
                            <Route path={C.PUBLIC_ROUTES.SHOP} element={<ShopPage />} />
                            <Route path={C.PUBLIC_ROUTES.CART} element={<CheckoutPage />} />
                            <Route path={C.PUBLIC_ROUTES.PASSWORD_FORGET} element={<PasswordResetPage />} />
                            <Route path={C.PUBLIC_ROUTES.NEWSLETTER} element={<NewsletterPage />} />
                            <Route path={C.PUBLIC_ROUTES.LANDING} element={<LandingPage />} />
                        </Routes>
                    </LandingPageRefContext.Provider>
                </ScrollToTop>
            </BrowserRouter>
        </React.Suspense>
    )
}

/**
 * Subscribe to auth changes in Firebase app.
 */
const useSubscribeToAuthChanges = (): null => {
    const auth = getAuth();
    const dispatch = useDispatch();
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(
            user => {
                if (user) {
                    const userProps: IUser = {
                        uid: user.uid,
                        emailVerified: user.emailVerified,
                        email: user.email,
                        displayName: user.displayName,
                    }
                    dispatch(authChanged(userProps));
                } else {
                    dispatch(authChanged(null));
                    dispatch(initUserProfile());
                }
            },
            error => {
                dispatch(authError(error));
            }
        );
        return unsubscribe;
    }, [dispatch, auth]);

    const user = useSelector<RootState, IUser | null>(state => state.app.user);
    useEffect(() => {
        const currentUser = getAuth().currentUser;
        if (user !== null && currentUser) {
            const userProfileRef = doc(db, C.COLLECTIONS.users, user.uid);
            const unsub = onSnapshot(userProfileRef, (doc) => {
                //reset => but we're not doing anything async, why all the loading stuff???
                dispatch(setSuccess(false));
                dispatch(setError(null));
                //now loading
                dispatch(setLoading(true));
                const data = doc.data();

                if (data) {
                    dispatch(updateUserProfile({
                        ...data,
                        uid: currentUser.uid,
                        email: currentUser.email ?? "no email",
                    }));
                } else {
                    throw new Error(`Profil für User mit id ${doc.id} existiert nicht.`)
                }
                //success
                dispatch(setLoading(false));
                dispatch(setSuccess(true));

            }, (error) => {
                console.error(error);
                dispatch(setLoading(false));
                dispatch(setSuccess(false));
                dispatch(setError({
                    name: error.name,
                    stack: error?.stack,
                    code: error?.code,
                    message: error.message
                } as SerializedError));
            });
            return unsub;
        }
    }, [user, dispatch])
    return null;
}

