import { useDispatch, useSelector } from "react-redux";
import { useAppState } from "../../hooks";
import { RootState } from "../..";
import { IProduct } from "../../domains/Product";
import { useEffect, useState } from "react";
import { UserProfile } from "../../pages/CheckoutPage/userProfileSlice";
import { collection, doc, runTransaction, Timestamp } from "firebase/firestore";
import { COLLECTIONS } from "../../constants";
import { db } from "../../firebase";
import { ErrorDisplay, SuccessDisplay } from "../Displays";
import { Button } from "../Buttons";
import { removeProduct, reset } from "../../pages/ShopPage/shopSlice";
import { getAuth, updateProfile } from "firebase/auth";
import { ShoppingCartTable } from "../Tables/ShoppingCartTable";
import { ResendEmailVerification } from "../../pages/HomePage/HomePage";

const submitOrder = async (
    products: IProduct[],
    isAuthenticated: boolean,
    userProfile: UserProfile,
    setLoading: (loading: boolean) => void,
    setError: (e: Error | null) => void,
    setSuccess: (success: boolean) => void,
) => {
    try {
        if (!isAuthenticated) {
            throw new Error("Login/Registrierung ist erforderlich, um die Bestellung abzuschliessen.")
        }
        setError(null);
        setSuccess(false);
        setLoading(true);
        await runTransaction(db, async (transaction) => {
            // READS
            // get orders collection metadata to determine next orderId
            const metaOrderDocRef = doc(db, `${COLLECTIONS.orders}/meta`);
            const metaOrderDoc = await transaction.get(metaOrderDocRef);
            const metaData = metaOrderDoc.data();
            if (!metaOrderDoc.exists() || (metaData === undefined) || (metaData.orderId === undefined)) {
                throw new Error("Cannot retrieve next orderId.");
            }

            //WRITES
            // update user profile
            const totalPrice = products.reduce<number>((acc, cur) => acc + cur.price, 0);
            const userDocRef = doc(db, COLLECTIONS.users, userProfile.uid);
            const orderId = metaData.orderId;
            transaction.update(userDocRef, {
                ...userProfile,
                orders: [...userProfile.orders, orderId],
            });

            // create new order
            const orderDocRef = doc(collection(db, COLLECTIONS.orders));
            transaction.set(orderDocRef, { id: orderDocRef.id, orderId, createdAt: Timestamp.now(), positions: products, userProfile, totalPrice, completed: false });

            // increment orderId
            transaction.update(metaOrderDocRef, { orderId: orderId + 1 });

            // set display name in profile
            const currentUser = getAuth().currentUser;
            const displayName = `${userProfile.firstName} ${userProfile.lastName}`;
            if (currentUser) {
                await updateProfile(currentUser, { displayName });
            }
        })
        setLoading(false);
        setSuccess(true);
    } catch (error) {
        setLoading(false);
        if (error instanceof Error) {
            setError(error);
        } else {
            setError(new Error("Unhandled error"));
        }
    }
}

export const ConfirmationForm = () => {
    const dispatch = useDispatch();

    const { isAuthenticated } = useAppState();
    const user = getAuth().currentUser;
    const productsInCart = useSelector<RootState, IProduct[]>(state => state.shop.items);
    const userProfile = useSelector<RootState, UserProfile>(state => state.userProfile);
    const [error, setError] = useState<Error | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (success) {
            dispatch(reset());
        }
    }, [success, dispatch])

    const emailVerificationText = user?.emailVerified ? 
        null : 
        <>
            <p style={{textAlign:"justify"}}>Wir haben dir eine E-Mail geschickt. Bitte folge den Anweisungen darin, um deine Mail-Adresse zu verifizieren.
</p>
            <p style={{textAlign:"justify", marginBottom: "2rem"}}>Falls du die Nachricht nicht erhalten hast, überprüfe bitte zuerst deinen Spam-Ordner oder schick dir die E-Mail nochmals zu.</p>
            <ResendEmailVerification buttonText="E-Mail nochmals verschicken"/>
        </>
       
    return (
        <form onSubmit={(e) => { e.preventDefault(); submitOrder(productsInCart, isAuthenticated, userProfile, setLoading, setError, setSuccess) }} style={{ margin: "0 0 2rem 0" }} >

            {success ?
                <>
                    <SuccessDisplay message="Deine Bestellung wurde erfolgreich übermittelt." margin="2rem 0 0 0" padding="0.4rem 0" /> :
                    {emailVerificationText}
                </> :
                <>
                    <p>Bitte bestätige deine Bestellung:</p>
                    <ShoppingCartTable
                        products={productsInCart}
                        removeProduct={(i) => dispatch(removeProduct(i))}
                    />
                    <p style={{ textAlign: "justify" }}>Die bestellten Produkte werden dir nach Überprüfung deiner Email-Adresse über deinen didacto-Account zur Verfügung gestellt.</p>
                    <p>Die Bezahlung erfolgt per Rechnung.</p>
                    <Button id="submit-order"
                        type="submit"
                        width="100%"
                        justifyContent="center"
                        marginTop="2rem"
                        variant="blue"
                        text="Bestellung bestätigen"
                        disabled={productsInCart.length === 0 || loading} />
                </>
            }
            {error ? <ErrorDisplay error={error} /> : null}
        </form>
    )
};