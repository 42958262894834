import { useEffect, useState } from "react";
import { MOBILE_BREAKPOINT } from '../constants';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean>(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < MOBILE_BREAKPOINT){
                setIsMobile(true)
            } else {
                setIsMobile(false);
            }
        };
        window.addEventListener('resize', handleResize);
        //check initially whether breakpoint has been reached
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [setIsMobile]);
    return isMobile;
}