import { COLORS } from "../../constants";
import { DidactoColor } from "../../types/App";
import * as S from './Styles';
import { Back } from "../../components/Icons"
import { Button } from "../Buttons";
import { useState } from "react";
import { useIsMobile } from "../../hooks";

export type Step = {
    title: string,
    content: (next: () => void) => JSX.Element,
    nextText?: string,
    prevText?: string,
    prevOverride?: () => void,
    nextOverride?: (nextFunc: () => void) => void,
    chainToNext?: boolean,
    disableNext?: boolean,
    hideNext?: boolean,
}

export type StepperProps = {
    color?: DidactoColor,
    colorDark?: DidactoColor,
    colorLight?: DidactoColor,
    steps: Step[],
}

export const Stepper = ({
    color = COLORS.DIDACTO_BLACK,
    colorDark = COLORS.DIDACTO_BLACK,
    colorLight = COLORS.DIDACTO_BLACK,
    steps,
}: StepperProps) => {

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const next = () => setActiveStepIndex(activeStepIndex + 1);
    const prev = () => setActiveStepIndex(activeStepIndex - 1);
    const lastIndex = steps.length - 1;

    return (
        <S.StepperBox fontColor={color}>
            <S.StepperHeaderBox id="stepper-headerbox">
                {
                    steps.map(({ title }, stepIndex) => {
                        const isLast = stepIndex === lastIndex;
                        const isActive = stepIndex === activeStepIndex;
                        const isPendingStep = stepIndex > activeStepIndex;
                        const isCompletedStep = stepIndex < activeStepIndex;
                        let onClick = () => setActiveStepIndex(stepIndex);
                        let headerColor = color;
                        if (isPendingStep) {
                            headerColor = colorLight;
                            onClick = () => false;
                        }
                        if (isCompletedStep) {
                            headerColor = colorDark;
                        }
                        const stepHeaderProps = { title, isLast, isActive, isPendingStep, color: headerColor, colorLight, colorDark, onClick };
                        return <StepperHeader key={stepIndex} {...stepHeaderProps} />

                    })
                }
            </S.StepperHeaderBox>
            <S.StepperContentBox id="stepper-contentbox">
                {
                    steps.map(({
                        content,
                        nextOverride,
                        prevOverride,
                        nextText,
                        prevText,
                        chainToNext,
                        disableNext,
                        hideNext }, stepIndex) => {
                        const isLast = stepIndex === lastIndex;
                        const isFirst = stepIndex === 0;
                        const isActive = stepIndex === activeStepIndex;
                        const stepProps = {
                            next,
                            prev,
                            nextOverride,
                            prevOverride,
                            chainToNext,
                            disableNext,
                            stepIndex,
                            isActive,
                            isFirst,
                            isLast,
                            content,
                            prevText,
                            nextText,
                            hideNext,
                        };
                        return <StepWrapper key={stepIndex} {...stepProps} />
                    })
                }
            </S.StepperContentBox>
        </S.StepperBox>
    )
}

export type StepWrapperProps = {
    next: () => void,
    prev: () => void,
    nextOverride?: (nextFunc: () => void) => void,
    prevOverride?: () => void,
    disableNext?: boolean,
    chainToNext?: boolean,
    nextText?: string,
    prevText?: string,
    stepIndex: number,
    isLast: boolean,
    isFirst: boolean,
    isActive: boolean,
    content: (next: () => void) => JSX.Element,
    hideNext?: boolean,
}

export const StepWrapper = ({
    next,
    prev,
    nextOverride,
    prevOverride,
    chainToNext,
    disableNext = false,
    nextText = "weiter",
    prevText = "zurück",
    stepIndex,
    isActive,
    isLast,
    isFirst,
    content,
    hideNext,
}: StepWrapperProps) => {
    return (
        <S.StepBox isActive={isActive}>
            <S.Step backgroundColor={COLORS.DIDACTO_GREY}>
                {content(next)}
            </S.Step>
            <S.StepNavBox>
                {
                    (isFirst && !prevOverride) ?
                        <div></div> :
                        <Button id={`prev-${stepIndex}`} variant="blue" onClick={prevOverride ?? prev} text={prevText} />
                }
                {
                    (isLast && !nextOverride) || hideNext ?
                        <div></div> :
                        <Button id={`next-${stepIndex}`} disabled={disableNext} variant="blue" onClick={nextOverride ? () => nextOverride(next) : next} text={nextText} />
                }
            </S.StepNavBox>
        </S.StepBox>
    )
}

type StepperHeaderProps = {
    title: string,
    onClick: () => void,
    isLast: boolean,
    isActive: boolean,
    isPendingStep: boolean,
    color: DidactoColor,
    colorLight: DidactoColor,
    colorDark: DidactoColor,
}

export const StepperHeader = ({ title, onClick, isLast, isActive, isPendingStep, color, colorLight, colorDark }: StepperHeaderProps) => {
    let iconColor = (isPendingStep || isActive) ? colorLight : colorDark;
    const isMobile = useIsMobile();

    if (isMobile) {
        return isActive ?
            <S.StepHeaderActive onClick={onClick} color={color}>{title}</S.StepHeaderActive> :
            null
    } else {
        return (
            <>
                {isActive ?
                    <S.StepHeaderActive onClick={onClick} color={color}>{title}</S.StepHeaderActive> :
                    <S.StepHeader onClick={onClick} color={color}>{title}</S.StepHeader>}
                {isLast ? null : <Back color={iconColor} />}
            </>
        )
    }
}





