import { WithChildren } from '../../types/App';
import * as S from './CardBoxStyles';

type CardBoxProps = WithChildren<{
    width?: string,
}>;

export const CardBox = ({
    width,
    children
}: CardBoxProps) => {
    return (
        <S.CardBox width={width}>
            {children}
        </S.CardBox>
    )
}