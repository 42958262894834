import { SignUpForm } from "./SignUpForm";
import { SignInForm } from "./SignInForm";
import { getAuth } from "firebase/auth";

type LoginFormProps = {
    next: () => void
}

export const LoginForm = ({next}: LoginFormProps) => {
    const user = getAuth().currentUser;
    return (
        <div style={{padding: "0 0 0.5rem 0"}}>
            <SignUpForm next={next} width="66%"/>
            {user ? null :
                <>
                    <p style={{margin: "2rem 0 0 0.5rem"}}>Falls du bereits einen Didacto-Account besitzt, melde dich bitte an:</p>
                    <SignInForm next={next} justifyButton="flex-end" width="66%" />
                </>}
        </div>
    )
}