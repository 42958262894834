import React from 'react';
import { useLandingPageRefs } from '../hooks';
import { scrollToTop } from '../utils';

export type LandingPageRefs = ReturnType<typeof useLandingPageRefs>;

const defaultScrollTo = {
    didacto: scrollToTop,
    contact: scrollToTop,
    team: scrollToTop,
    top: scrollToTop,
};

const defaultRefCallbacks = {
    didacto: () => {},
    contact: () => {},
    team: () => {},
};

export const LandingPageRefContext = React.createContext<LandingPageRefs>({scrollTo: defaultScrollTo, refCallbacks: defaultRefCallbacks})