import { ParallaxSection } from '../Sections/Styles';
import mountDidactoBg from '../../pages/LandingPage/assets/headerbild.jpg';
import * as C from '../../constants';

export const Loading = () => {
    return (
        <ParallaxSection id="loading-fallback"
            backgroundImage={mountDidactoBg}
            paddingTop={C.NAVBAR_HEIGHT}>
        </ParallaxSection>
    )
}