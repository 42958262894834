import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants';
import { DidactoColor } from '../../types/App';

/**
 * Table component using CSS Grid
 */ 
type TableProps = {
    backgroundColor?: DidactoColor,
    fontSize?: string,
    padding?: string,
    marginBottom?: string,
    width?: string,
    gridTemplateColumns?: string,
    nRows: number,
}

export const Table = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'backgroundColor' && prop !== 'fontSize' && prop !== 'padding' && prop !== 'marginBottom' && prop !== 'width' && prop !== 'gridTemplateColumns' && prop !== 'nRows',
})<TableProps>(({ backgroundColor, fontSize, padding, marginBottom, width, gridTemplateColumns, nRows }) => ({
    width: width ?? "100%",
    display: 'grid',
    alignItems: 'stretch',
    padding: padding ?? "1rem",
    background: backgroundColor ?? COLORS.DIDACTO_GREY,
    fontSize: fontSize ?? "1rem",
    gridTemplateColumns: gridTemplateColumns ?? `repeat(${nRows}, auto)`,
    marginBottom: marginBottom ?? 'initial',
}));

interface CellProps {
    fontFamily?: string,
    textAlign?: React.CSSProperties['textAlign'],
    padding?: string,
    backgroundColor?: DidactoColor,
    justifyContent?: string,
    borderStyle?: string,
    borderColor?: DidactoColor,
}

export const Cell = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'fontFamily' && prop !== 'textAlign' && prop !== 'padding' && prop !== 'backgroundColor' && prop !== 'justifyContent' && prop !== 'borderStyle' && prop !== 'borderColor',
})<CellProps>(({ fontFamily, textAlign, padding, backgroundColor, justifyContent, borderStyle, borderColor }) => ({
    borderStyle: borderStyle ?? 'solid',
    borderWidth: '1px',
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: justifyContent ?? "flex-start",
    padding: padding ?? "0",
    fontFamily: fontFamily ?? "TisaSansPro, Helvetica, Arial, sans-serif",
    textAlign: textAlign ?? "initial",
    background: backgroundColor ?? COLORS.DIDACTO_GREY,
    color: COLORS.DIDACTO_BLACK,
    borderColor: borderColor ?? "initial",
}));

export const HeaderCell = styled(Cell)({
    fontFamily: 'TisaSansPro-Medium',
    position: 'sticky',
});

export const FooterCell = styled(Cell)({
    fontFamily: 'TisaSansPro-Medium',
    border: 'none'
});

type MobileLabelProps = CellProps;

export const MobileLabel = styled(HeaderCell, {
    shouldForwardProp: (prop) =>
        prop !== 'fontFamily' && prop !== 'textAlign' && prop !== 'padding' && prop !== 'backgroundColor' && prop !== 'justifyContent' && prop !== 'borderStyle' && prop !== 'borderColor',
})<MobileLabelProps>(({ fontFamily, textAlign, padding, backgroundColor, justifyContent, borderStyle, borderColor }) => ({
    display: 'none',
    fontFamily: 'TisaSansPro-Medium',
    '@media (max-width: 800px)': {
        display: 'flex !important',
    },
}));

type CheckboxCellProps = CellProps;

export const CheckboxCell = styled(Cell, {
    shouldForwardProp: (prop) =>
        prop !== 'fontFamily' && prop !== 'textAlign' && prop !== 'padding' && prop !== 'backgroundColor' && prop !== 'justifyContent' && prop !== 'borderStyle' && prop !== 'borderColor',
})<CheckboxCellProps>({
    display: 'flex',
    justifyContent: 'center',
});

export const Checkbox = styled('input')({
    margin: 0,
});

type TextAreaCellProps = CellProps;

export const TextAreaCell = styled(Cell, {
    shouldForwardProp: (prop) =>
        prop !== 'fontFamily' && prop !== 'textAlign' && prop !== 'padding' && prop !== 'backgroundColor' && prop !== 'justifyContent' && prop !== 'borderStyle' && prop !== 'borderColor',
})<TextAreaCellProps>({
    display: 'flex',
    justifyContent: 'center',
});

export const TextArea = styled('textarea')({
    width: '80%',
    margin: 0,
    resize: 'vertical',
});