import { WithChildren } from '../../types/App';
import * as S from './Styles';
import { COLORS } from '../../constants';
import { VariantColorMap } from '../../utils';

export type HyperLinkProps = WithChildren<{
    variant: 'light' | 'dark' | 'blue'
    href: string
}>

/** 
 * Inline a-tag hyperlink
 */
export const HyperLink = ({ variant, href, children }: HyperLinkProps) => {
    if (variant === 'light') {
        const colors: Partial<VariantColorMap> = {
            linkcolor: COLORS.DIDACTO_WHITE,
            activecolor: COLORS.DIDACTO_LIGHT_RED,
            hovercolor: COLORS.DIDACTO_LIGHT_RED,
            visitedcolor: COLORS.DIDACTO_GREY,
        };
        return <S.HyperLink href={href} {...colors}>{children}</S.HyperLink>;
    } else if (variant === 'blue'){
        const colors: Partial<VariantColorMap> = {
            linkcolor: COLORS.DIDACTO_BLUE,
            activecolor: COLORS.DIDACTO_DARK_BLUE,
            hovercolor: COLORS.DIDACTO_DARK_BLUE,
            visitedcolor: COLORS.DIDACTO_DARK_BLUE,
        };
        return <S.HyperLink href={href} {...colors}>{children}</S.HyperLink>;
    } else {
        // default: variant === 'dark'
        const colors: Partial<VariantColorMap> = {
            linkcolor: COLORS.DIDACTO_BLACK,
            activecolor: COLORS.DIDACTO_RED,
            hovercolor: COLORS.DIDACTO_RED,
            visitedcolor: COLORS.DIDACTO_DARK_BLUE,
        };
        return <S.HyperLink href={href} {...colors}>{children}</S.HyperLink>;
    }
}