import {
  Link as MuiLink,
  Breadcrumbs
} from '@mui/material';
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { COLORS } from "../../constants";

const OverviewNavigation = ({ path }) => {
  let prefixPath = "/wp"
  let pathSegments = path.split('/').slice(1);
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        marginLeft: "16px", 
        marginTop:"3rem",
        display:"flex",
        alignItems: "center"}}
      separator={<NavigateNextIcon fontSize="medium" sx={{color: COLORS.DIDACTO_DARK_BLUE}} />}>
      <MuiLink
        sx={{ display: 'flex', alignItems: 'center'}}
        component={Link}
        to={prefixPath}>
        <HomeIcon sx={{ fontSize: "20px", color: COLORS.DIDACTO_BLUE, "&:hover": {color: COLORS.DIDACTO_DARK_BLUE} }} 
        fontSize="inherit" />
      </MuiLink>
      {pathSegments.map((seg, i) => {
        prefixPath += "/" + seg;
        return (
          <MuiLink
            key={seg}
            underline="hover"
            component={Link}
            sx={{fontSize: "14px", color: `${COLORS.DIDACTO_DARK_BLUE} !important`}}
            to={prefixPath}
          >{decodeURI(seg)}</MuiLink>
        );
      })}
    </Breadcrumbs>
  );
}

export default OverviewNavigation;
